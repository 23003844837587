import * as React from 'react';
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Rating from '@mui/material/Rating';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchToken } from '../../Auth';
import Map from '../../components/common/Map/Map';

const FocusCompetition = ({ focusComp, setFocusComp, selectedHotel, clientProperties, setClientProperties }) => {
    const navigate = useNavigate();

    const [hotels, setHotels] = useState([]);
    const [radius, setRadius] = useState(5);

    const distances = [
        {
            value: 5,
            label: '5km',
        },
        {
            value: 10,
            label: '10',
        },
        {
            value: 15,
            label: '15',
        },
        {
            value: 20,
            label: '20',
        },
        {
            value: 25,
            label: '25',
        },
        {
            value: 30,
            label: '30km',
        }
    ];

    function valuetext(value) {
        return `${value}km`;
    }

    const getHotels = async () => {
        try {
            const response = await axios.get(`/get_recommended_fc/mmt/${selectedHotel['value']}/${selectedHotel['city']}/${selectedHotel['latitude']}/${selectedHotel['longitude']}/${radius}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                setHotels(response.data)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        if (selectedHotel && radius) {
            getHotels()
        }
    }, [selectedHotel, radius]);

    const handleCheck = (hid) => {
        let temp = focusComp
        if (selectedHotel['value'] in temp){
            let obj = temp[selectedHotel['value']]
            if(hid in obj){
                delete obj[hid]
            }else{
                obj[hid] = {roomType: '', mealPlan: ''}
            }
            temp[selectedHotel['value']] = obj
        }else{
            temp[selectedHotel['value']] = {}
            temp[selectedHotel['value']][hid] = {roomType: '', mealPlan: ''}
        }
        setFocusComp(prevState => {
            return temp
        })
    }

    return (
        <div className='mt-4 mb-4'>
            <h5 className='bold text-orange'>Focus Competitions</h5>
            <Box sx={{ width: '100%' }}>
                <div className='row'>
                    <div className='col-8'></div>
                    <div className='col-4'>
                        <Slider
                            aria-label="Always visible"
                            defaultValue={5}
                            getAriaValueText={valuetext}
                            step={5}
                            min={5}
                            max={30}
                            marks={distances}
                            valueLabelDisplay="on"
                            onChange={(evt) => setRadius(evt.target.value)}
                        />
                    </div>
                </div>
                <Map radius={5} hotels={hotels} lat={parseFloat(selectedHotel['latitude'])} long={parseFloat(selectedHotel['longitude'])} />

                <div className='mt-4'>
                    {
                        hotels.map(hotel =>
                            <div className='p-3 corner border my-2 row'>
                                <div className='col-1'>
                                    <Checkbox 
                                        checked={selectedHotel['value'] in focusComp && hotel['hotel_id'] in focusComp[selectedHotel['value']]} 
                                        onClick={(evt) => handleCheck(hotel['hotel_id'])} 
                                    />
                                </div>
                                <div className='col-5 d-flex align-items-center'>{hotel['hotel_name']}</div>
                                <div className='col-2 d-flex align-items-center'>
                                    <Rating
                                        readOnly
                                        size="small"
                                        value={hotel['star']}
                                        max={5}
                                    />
                                </div>
                                <div className='col-2'>
                                    {
                                        selectedHotel['value'] in focusComp && hotel['hotel_id'] in focusComp[selectedHotel['value']] ?
                                        <select className='form-select'></select>
                                        : null
                                    }    
                                </div>
                            </div>
                        )
                    }
                </div>
            </Box>
        </div>
    )
};

export default FocusCompetition;