import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import axios from 'axios';
import {fetchToken} from '../../Auth' 
import { toast } from 'react-toastify';


import FCMap from './components/Map';
import Carousel from './components/Carousel';

const FCMapping = () => {

	const [loading, setLoading] = useState(false);
	const [cities, setCities] = useState([])
	const [selectedCity, setSelectedCity] = useState('');
	const [properties, setProperties] = useState([]);
	const [selectedProperty, setSelectedProperty] = useState('');
	const [clientHotel, setClientHotel] = useState(null);
	const [recommendedHotels, setRecommendedHotels] = useState([]);
	const [star, setStar] = useState(5);
	const [radius, setRadius] = useState(5);
	const [hotels, setHotels] = useState([]);
	const [selectedHotels, setSelectedHotels] = useState([]);
	const [roomTypes, setRoomTypes] = useState([]);

	const navigate = useNavigate();

	const distances = [
	  {
	    value: 5,
	    label: '5km',
	  },
	  {
	    value: 10,
	    label: '10',
	  },
	  {
	    value: 15,
	    label: '15',
	  },
	  {
	    value: 20,
	    label: '20',
	  },
	  {
	    value: 25,
	    label: '25',
	  },
	  {
	    value: 30,
	    label: '30km',
	  }
	];

	function valuetext(value) {
	  return `${value}km`;
	}

	const getAllCities = async () => {
		try {
	        const response = await axios.get('/get_client_cities', {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
	        	let c = Object.keys(response.data)[0]
	        	setSelectedCity(c)
	        	setCities(Object.keys(response.data))
	        	setProperties(response.data[c]['properties'])
	        	setSelectedProperty(response.data[c]['properties'][0]['property_id'])
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	const getHotelDetails = async () => {
		try {
	        const response = await axios.get(`/get_client_hotel/${selectedCity}/${selectedProperty}`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
	        	setClientHotel(response.data)
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	const getFC = async () => {
		try {
	        const response = await axios.get(`/get_fc/${selectedCity}/${clientHotel['property_id']}`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
	        	setSelectedHotels(Object.keys(response.data.EMT))
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	const getRecommendedFC = async () => {
		setLoading(true)
		try {
	        const response = await axios.get(`/get_recommended_fc/${clientHotel['property_id']}/${selectedCity}/${clientHotel['latitude']}/${clientHotel['longitude']}/${radius}`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
	        	getFC()
	        	setRecommendedHotels(response.data)
	        	setHotels(response.data.filter(hotel => hotel['star'] == star))
	        	setLoading(false)
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	const getRoomTypes = async (hotelId) => {
		try {
	        const response = await axios.get(`/get_room_types/${hotelId}`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response && response.status == 200 && response.data !== undefined){
	        	roomTypes(response.data)
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}


	useEffect(() => {
		getAllCities();
	}, []);

	useEffect(() => {
		if(selectedCity && cities.length){
			setProperties(cities[selectedCity]['properties'])
			setSelectedProperty(cities[selectedCity]['properties'][0]['property_id'])
		}
	}, [selectedCity]);

	useEffect(() => {
		if(selectedCity && selectedProperty){
			getHotelDetails()
		}
	}, [selectedCity, selectedProperty])

	useEffect(() => {
		if(clientHotel && radius) getRecommendedFC()
	}, [clientHotel, radius])


	useEffect(() => {
		if(star){
			setHotels(recommendedHotels.filter(hotel => hotel['star'] == star))
		}
	}, [star])

	const clickHotel = (id) => {
		// getRoomTypes(id)
		if(selectedHotels.includes(id)){
			setSelectedHotels(selectedHotels.filter(item => item !== id))
		}else{
			if(selectedHotels.length === 5){
				alert('You are allowed only 5 competitions in the trial version...')
			}else{
				setSelectedHotels([id, ...selectedHotels])
			}
		}
	};

	const saveFC = async () => {
		try {
	      const response = await axios.post("/update_focus_competition", {selectedHotels, selectedCity, selectedProperty}, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	      });
	      if(response.status == 200){
	        toast.success('Focus Competition updated!', {
	            position: toast.POSITION.BOTTOM_RIGHT,
	            autoClose: 2500,
	            hideProgressBar: false,
	            closeOnClick: true,
	            pauseOnHover: false,
	            draggable: false,
	            theme: "colored",
	            type: toast.TYPE.SUCCESS
	        });
	      }
	    } catch (error) {
	      console.error("Error", error);
	    }
	}

	return(
		<div className='py-3'>
			<div className='w-100 row'>
				<div className='col-8'>
					<h5 className='bold text-orange'>Focused Competition</h5>
					<p className='grey-2 mt-1'>Choose which hotels you want to track on the Price Competitive Dashboard.</p>
				</div>
				<div className='col-4 d-flex justify-content-between align-items-start'>
					<div className='w-50'>
						<h2 className='bold text-orange'>{selectedHotels.length}</h2>
						<p className='text-muted'>FCs selected</p>
					</div>
					<div className='w-50'>
						<Button className='mt-3' variant='contained' size='large' onClick={() => saveFC()}>Save FC</Button>
					</div>
				</div>
			</div>

			<div className='w-100 row'>
				<div className='col-3'>
					<FormControl className='d-flex flex-row align-items-center' >
			        	<LocationOnIcon style={{fontSize: '1.8em'}} className='grey-2' />
			        	{
			        		cities.length ?
			        		<Select
				        		style={{borderRadius: '200px', border: "1.8px solid #eee, padding: '0.5em', color: '#eee'"}}
				        	    className='w-100 mx-1'
			        			size='small'
			        			value={selectedCity}
			        			onChange={(evt) => setSelectedCity(evt.target.value)}
				          		displayEmpty
					        >	{
					        		cities.map(city => <MenuItem value={city}>{city}</MenuItem>)
					        	}
				        	</Select>
				        	:
				        	null
			        	}
		      		</FormControl>
				</div>
				<div className='col-3'>
					<FormControl className='d-flex flex-row align-items-center'>
		      			<BusinessIcon style={{fontSize: '1.8em'}} className='grey-2' />
		      			{
			        		properties.length ?
			        		<Select
				        		style={{borderRadius: '200px', border: "1.8px solid #eee, padding: '0.5em', color: '#eee'"}}
				        	    className='w-100 mx-1'
			        			size='small'
			        			value={selectedProperty}
			        			onChange={(evt) => setSelectedProperty(evt.target.value)}
				          		displayEmpty
					        >	{
					        		properties.map(property => <MenuItem value={property['property_id']}>{property['property_name']}</MenuItem>)
					        	}
				        	</Select>
				        	:
				        	null
			        	}
		      		</FormControl>
				</div>
			</div>

			<div className='mt-4 w-100 row'>
				<div className='mt-3 col-12 row rounded p-3 bg-grey-4'>
					<div className='col-5'>
						<h6 className='bold'>Recommended Competitions</h6>
						<p className='text-muted'>Hotels shown below are based on your property location. Click on the markers to view that property. Zoom in to view more properties.</p>
					</div>
					<div className='col-4'>
						<Slider
					        aria-label="Always visible"
					        defaultValue={5}
					        getAriaValueText={valuetext}
					        step={5}
					        min={5}
  							max={30}
					        marks={distances}
					        valueLabelDisplay="on"
					        onChange={(evt) => setRadius(evt.target.value) }
					      />
					</div>
					<div className='col-3'>
						<Select
			        		style={{borderRadius: '200px', border: "1.8px solid #eee, padding: '0.5em', color: '#eee'"}}
			        	    className='w-100 mx-1'
		        			size='small'
		        			value={star}
		        			onChange={(evt) => setStar(evt.target.value)}
			          		displayEmpty
				        >	
				        	<MenuItem value={0} selected hidden>Filter by stars</MenuItem>
				        	<MenuItem value={1}>1 Star</MenuItem>
				        	<MenuItem value={2}>2 Star</MenuItem>
				        	<MenuItem value={3}>3 Star</MenuItem>
				        	<MenuItem value={4}>4 Star</MenuItem>
				        	<MenuItem value={5}>5 Star</MenuItem>
			        	</Select>
					</div>
				</div>
				<div className='mt-1 col-12'>
					{
						clientHotel ? <FCMap radius={radius} hotels={hotels} lat={clientHotel['latitude']} long={clientHotel['longitude']} /> : null
					}
				</div>
				<div className='mt-4 col-12'>
					<h6 className='bold text-muted'>{hotels.length} hotels found</h6>
				</div>
				<div className='col-12'>
					<div>
						<Carousel loading={loading} checked={selectedHotels} clickHotel={clickHotel} hotels={hotels}/>
					</div>
				</div>
				
			</div>
		</div>
	)
}

export default FCMapping;