import React, { useState, useEffect } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";

const itemStyles = {
  padding: "10px",
  border: "1px #000 solid",
  backgroundColor: "#FFF",
  margin: "10px",
  zIndex: 5
};

const SortableItem = sortableElement(({ user }) => {
  return <div style={itemStyles}>{user.name}</div>;
});

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const Priority = () => {
  const [newUser, setNewUser] = useState("");

  const [users, setUsers] = useState([
    { name: "user 0", order: 0 },
    { name: "user 1", order: 1 },
    { name: "user 2", order: 2 }
  ]);

  // useEffect(() => {}, [users]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setUsers(prevState => {
      const newItems = [...prevState];

      if (oldIndex > newIndex) {
        for (let i = oldIndex - 1; i >= newIndex; i--) {
          newItems[i].order++;
          newItems[oldIndex].order = newIndex;
        }
      } else if (oldIndex < newIndex) {
        for (let i = oldIndex + 1; i <= newIndex; i++) {
          newItems[i].order--;
          newItems[oldIndex].order = newIndex;
        }
      }
      return newItems.sort((a, b) => a.order - b.order);
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    addUser();
    setNewUser("");
  };

  const addUser = () => {
    const user = { name: newUser, order: users.length };
    setUsers(prevState => [...prevState, user]);
  };

  return (
    <div>
      <SortableContainer onSortEnd={onSortEnd}>
        {users.map((user, index) => (
          <SortableItem key={index} index={index} user={user} />
        ))}
      </SortableContainer>
      <form onSubmit={e => onSubmit(e)}>
        <input
          placeholder="Name"
          value={newUser}
          onChange={e => setNewUser(e.target.value)}
        />{" "}
        <button type="submit">add user</button>
      </form>
    </div>
  );
};

export default Priority;
