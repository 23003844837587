import React from "react";
import StraightIcon from "@mui/icons-material/Straight";
import SouthIcon from "@mui/icons-material/South";
import Tooltip from "@mui/material/Tooltip";

const FlagIcon = ({ filter, flag }) => {

  const flags = {
    'overpriced': 'Overpriced',
    'underpriced': 'Underpriced',
    'comp_price_inc': 'Competition price increase',
    'comp_price_dec': 'Competition price decrease',
    'DemandSpike': 'Train demand'
  }
  return (
    <>
      {flag &&
        (() => {
          const matchingFilterItem = filter.find(
            (filterItem) => filterItem.val === flag
          );

          if (matchingFilterItem) {
            return (
              <Tooltip title={flags[flag]}>
                <div
                  className="d-flex flag-btn justify-content-center align-items-center"
                  style={{
                    background: matchingFilterItem.background,
                    // color: matchingFilterItem.color,
                  }}
                >
                  {matchingFilterItem.initial}

                  {matchingFilterItem.icon === "inc" ? (
                    <StraightIcon
                      sx={{ pointerEvents: "none", width: "0.5em" }}
                    />
                  ) : (
                    <SouthIcon sx={{ pointerEvents: "none", width: "0.5em" }} />
                  )}
                </div>
              </Tooltip>
            );
          }
          return null;
        })()}
    </>
  );
};

export default FlagIcon;
