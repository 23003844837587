import * as React from 'react';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Select from 'react-select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';

// import cities from '../../services/cities.js';
import cityDetails from '../../services/cityDetails.js';
import internationalCities from '../../services/internationalCities.js';
import axios from 'axios';
import { fetchToken } from '../../Auth'
import { toast } from 'react-toastify';

const ClientRegions = ({ properties, setProperties, international }) => {
	const navigate = useNavigate();

	const [cities, setCities] = useState([]);
	const [clientCities, setClientCities] = useState(properties && Object.keys(properties).length ? Object.keys(properties).map(item => ({'label': item, 'value': item})) : []);

	const getCities = async () => {
		try {
			const response = await axios.get(`/get_scrape_cities/mmt`, {
				headers: {
				  'Content-Type': 'application/json',
				  'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if(response.status === 200 && response.data !== undefined){
			  setCities(response.data)
			}
		} catch (err) {
			console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
		}	
	}

	useEffect(() => {
		getCities()	
	}, []);

	useEffect(() => {
		if(clientCities !== null && typeof clientCities !== 'undefined'){
			setProperties((prevData) => {
				const temp = { ...prevData };
				
				for(let city of clientCities){
					if(city['value'] in temp){
						continue
					}else if(typeof city['value'] !== 'undefined'){
						if(international == '0'){
							try{
								temp[city['value']] = {
									'state': cityDetails[city['value']]['state'],
									'active': true,
									'properties': []
								}
							} catch(err){
								temp[city['value']] = {
									'state': '',
									'active': true,
									'properties': []
								}
							}
						}else{
							temp[city['value']] = {
								'country': internationalCities[city['value']]['country'],
								'active': true,
								'properties': []
							}
						}
					}
				}

				for(let key in temp){
					let res = clientCities.filter(obj => obj.value === (key));
					if(!res.length){
						delete temp[key];
					}
				}

				return temp;
			})
		}
		else if(clientCities == null){
			setProperties({});
		}
	}, [clientCities]);


	const deleteRegion = (val) => {
		let temp = clientCities;
		temp = temp.filter(item => item.value !== val)
		setClientCities(temp)
	}

	return(
		<div className='mt-4 mb-4'>
			<div className="mx-3 w-100 row mb-4 mt-2">
				{ cities.length ?
				<div className="col-10">
					<label className="mb-1">Select cities</label>
					{
					  	international == '0' ?
					  	<Select placeholder="Select client cities" value={clientCities} onChange={opt => {
							setClientCities(opt)
						} } isMulti options={cities.map(item => ({'label': item, 'value': item}))} className="w-100" />
					  	:
					  	<Select placeholder="Select client cities" value={clientCities} onChange={opt => {
							setClientCities(opt)
						} } isMulti options={Object.keys(internationalCities).map(item => ({'label': item, 'value': item}))} className="w-100" />
					}
				</div>
				:
				null}
			</div>
			<div className="mx-3 w-100 row mb-4 mt-2">
				<List sx={{ width: '95%', bgcolor: 'background.paper' }}>
					{
						clientCities !== null && typeof clientCities !== 'undefined' && clientCities.length ? clientCities.map(item => (
							<>
							{
								(international == '0' && typeof cityDetails[item['value']] !== 'undefined') || (international == '1' && typeof typeof internationalCities[item['value']] !== 'undefined') ?
									<ListItem className="light-blue-bg corner my-3 w-100 d-flex justify-content-between align-items-center">
								        <ListItemAvatar>
								          {
								          	international == '0' ?
								          	<Avatar alt={String(item['value'])} src={cityDetails[item['value']]['img']} />
								          	:
								          	<Avatar alt={String(item['value'])} src={internationalCities[item['value']]['img_url']} />
								          }
								        </ListItemAvatar>
								        <ListItemText primary={String(item['value'])} secondary={international == '0' ? cityDetails[item['value']]['state'] : internationalCities[item['value']]['country']} />
								    	
								    	<div className="w-10">
								    		<IconButton onClick={() => deleteRegion(item['value'])} aria-label="delete">
											  <DeleteIcon />
											</IconButton>
								    	</div>
								    </ListItem>
								:
								null
							}
							
						    </>
						))
						:
						<Alert severity="warning">No regions setup</Alert>
					}
			    </List>
		    </div>
		</div>
	)
}

export default ClientRegions;