import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Rating from '@mui/material/Rating';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { fetchToken } from '../../Auth';
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { toast } from 'react-toastify';

import FocusCompetition from './FocusCompetition';

const itemStyles = {
  zIndex: 5,
  cursor: 'grab'
};

const SortableItem = sortableElement(({ roomType }) => {
  return (
    <div className='outline-card p-3 row' style={itemStyles}>
      <div className='mt-2 col-1'>
        <i class="fa-solid fa-bars"></i>
      </div>
      <div className='mt-2 col-4'>
        <div className='w-100 d-flex align-items-start'>
          <div class='outline-card-icon p-1'><i class="fa-solid fa-bed"></i></div>
          <h6 className='bold mx-2'>{roomType.type_name.charAt(0).toUpperCase() + roomType.type_name.slice(1)}</h6>
        </div>
      </div>
      <div className='mt-2 col-2'>
        <p className='bold small'>{roomType.count} rooms</p>
      </div>
      <div className='mt-2 col-2'>
        <p className='grey small'>{roomType.sq_ft_area} sq. ft.</p>
      </div>
      <div className='mt-2 col-2'>
        <p className='success-pill small'>{roomType.bed_type} Bed</p>
      </div>
      <div className='mt-2 col-1'>
        <p className='priority small'>#{roomType.order + 1}</p>
      </div>
    </div>
  );
});

const SortableContainer = sortableContainer(({ children }) => {
  return <div className="container">{children}</div>;
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== value}
      id={`simple-tabpanel-${value}`}
      aria-labelledby={`simple-tab-${value}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




const ClientProperties = ({ clientProperties, setClientProperties, international, focusComp, setFocusComp }) => {
  const navigate = useNavigate();

  const [hotels, setHotels] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState('');

  // Get index of which city is selected in the tabs
  const [city, setCity] = React.useState(0);
  // Get the name of the city selected
  const [cities, setCities] = useState(Object.keys(clientProperties))
  // Get which accordion tab is expanded
  const [expanded, setExpanded] = React.useState(-1);

  const [data, setData] = useState(clientProperties) // {Mumbai: {properties: [{}, {}], Delhi: {properties: [{}, {}]}}

  //Property details
  const [name, setName] = useState('');
  const [rating, setRating] = React.useState(0);
  const [checkInTime, setCheckInTime] = useState('');
  const [checkOutTime, setCheckOutTime] = useState('');
  const [type, setType] = useState('');
  const [address, setAddress] = useState('');
  const [AC, setAC] = useState(false);
  const [restaurant, setRestaurant] = useState(false);
  const [bar, setBar] = useState(false);
  const [roomService, setRoomService] = useState(false);
  const [laundry, setLaundry] = useState(false);
  const [parking, setParking] = useState(false);
  const [internet, setInternet] = useState(false);
  const [wlan, setWlan] = useState(false);
  const [gym, setGym] = useState(false);
  const [spa, setSpa] = useState(false);
  const [swimming, setSwimming] = useState(false);
  const [vehicle, setVehicle] = useState(false);
  const [airportTransfer, setAirportTransfer] = useState(false);
  const [pets, setPets] = useState(false);
  const [coupleFriendly, setCoupleFriendly] = useState(false);
  const [customAmenities, setCustomAmenities] = useState('');
  const [roomTypes, setRoomTypes] = useState([]);
  const [typeName, setTypeName] = useState('');
  const [bedType, setBedType] = useState('');
  const [area, setArea] = useState('');
  const [subCount, setSubCount] = useState('');

  useEffect(() => { }, [roomTypes]);

  const getHotels = async () => {
    try {
      const response = await axios.get(`/get_scrape_hotels/${cities[city]}/mmt`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        },
      });
      if (response.status === 200 && response.data !== undefined) {
        setHotels(response.data)
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate('/login')
      }
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setRoomTypes(prevState => {
      const newItems = [...prevState];

      if (oldIndex > newIndex) {
        for (let i = oldIndex - 1; i >= newIndex; i--) {
          newItems[i].order++;
          newItems[oldIndex].order = newIndex;
        }
      } else if (oldIndex < newIndex) {
        for (let i = oldIndex + 1; i <= newIndex; i++) {
          newItems[i].order--;
          newItems[oldIndex].order = newIndex;
        }
      }
      return newItems.sort((a, b) => a.order - b.order);
    });
  };

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChange = (event, newCity) => {
    setCity(newCity);
  };


  useEffect(() => {
    if (Object.keys(data).length) setClientProperties(data)
  }, [data])

  const checkAmenities = () => {
    if (
      !AC &&
      !restaurant &&
      !bar &&
      !roomService &&
      !laundry &&
      !parking &&
      !internet &&
      !wlan &&
      !gym &&
      !spa &&
      !swimming &&
      !vehicle &&
      !airportTransfer &&
      !pets &&
      !coupleFriendly
    ) {
      return false
    }

    return true
  }

  const addProperty = () => {

    if (!name) {
      toast.error(`Property name not provided!`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
      return;
    }
    if (/^[0-9]+$/.test(name)) {
      toast.error('Property name cannot contain only numerical characters!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
      return
    }
    if (/^[^a-zA-Z0-9]+$/.test(name)) {
      toast.error('Property name cannot contain only special characters!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
      return
    }
    if (!rating) {
      toast.error(`Property star not provided!`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
      return;
    }
    // if (!checkInTime) {
    //   toast.error(`Check in time not provided!`, {
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 2500,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: false,
    //     theme: "colored",
    //     type: toast.TYPE.ERROR
    //   });
    //   return;
    // }
    // if (!checkOutTime) {
    //   toast.error(`Check out time not provided!`, {
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 2500,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: false,
    //     theme: "colored",
    //     type: toast.TYPE.ERROR
    //   });
    //   return;
    // }
    if (!type) {
      toast.error(`Property type not provided!`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
      return;
    }
    if (!roomTypes.length) {
      toast.error(`Please add room types for this property!`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
      return;
    }

    // if (!checkAmenities()) {
    //   toast.error(`Atleast one amenity should be selected!`, {
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 2500,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: false,
    //     theme: "colored",
    //     type: toast.TYPE.ERROR
    //   });
    //   return;
    // }

    setData((prevData) => {
      const temp = { ...prevData };
      const currentCity = cities[city];

      // Make sure the necessary nested properties exist
      temp[currentCity] = temp[currentCity] || {};
      temp[currentCity].properties = temp[currentCity].properties || [];

      // Push the new property
      temp[currentCity].properties.push({
        'property_name': name,
        'active': true,
        'rating': rating,
        'check_in_time': checkInTime,
        'check_out_time': checkOutTime,
        'property_type': type,
        'address': address,
        'air_conditioned': AC,
        'restaurant': restaurant,
        'bar': bar,
        'room_service': roomService,
        'laundry_service': laundry,
        'car_parking': parking,
        'internet': internet,
        'wlan_access': wlan,
        'gym': gym,
        'spa': spa,
        'swimming_pool': swimming,
        'vehicle_rentals': vehicle,
        'airport_transfers': airportTransfer,
        'pets_allowed': pets,
        'couple_friendly': coupleFriendly,
        'custom_amenities': customAmenities,
        'room_types': roomTypes
      });

      return temp;
    });

    setName('');
    setRating('');
    setCheckInTime('');
    setCheckOutTime('');
    setType('');
    setAddress('');
    setAC(false);
    setRestaurant(false);
    setBar(false);
    setRoomService(false);
    setLaundry(false);
    setParking(false);
    setInternet(false);
    setWlan(false);
    setGym(false);
    setSpa(false);
    setSwimming(false);
    setVehicle(false);
    setAirportTransfer(false);
    setPets(false);
    setCoupleFriendly(false);
    setCustomAmenities('');
    setRoomTypes([]);
  }

  const addRoomType = () => {
    if (!typeName) {
      toast.error(`Room type name not provided!`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
      return;
    }
    if (/^[0-9]+$/.test(typeName)) {
      toast.error('Room type name cannot contain only numerical characters!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
      return
    }
    if (/^[^a-zA-Z0-9]+$/.test(typeName)) {
      toast.error('Room type name cannot contain only special characters!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
      return
    }
    if (!bedType) {
      toast.error(`Bed type not provided!`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
      return;
    }

    if (!area || parseFloat(area) < 10) {
      toast.error(`Invalid room area provided!`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
      return;
    }

    if (!subCount || parseInt(subCount) < 1) {
      toast.error(`Invalid room count provided!`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
      return;
    }

    setRoomTypes(prevState => [...roomTypes, {
      'order': roomTypes.length,
      'type_name': typeName,
      'bed_type': bedType,
      'sq_ft_area': area,
      'count': parseInt(subCount)
    }]);

    setArea('');
    setSubCount('');
    setBedType('');
    setTypeName('');
  }

  useEffect(() => {
    getHotels()
    setSelectedHotel({'label': '', 'value': ''})
    setExpanded(-1)
    clear()
  }, [city]);

  const clear = () => {
    setName('');
    setRating('');
    setCheckInTime('');
    setCheckOutTime('');
    setType('');
    setAddress('');
    setAC(false);
    setRestaurant(false);
    setBar(false);
    setRoomService(false);
    setLaundry(false);
    setParking(false);
    setInternet(false);
    setWlan(false);
    setGym(false);
    setSpa(false);
    setSwimming(false);
    setVehicle(false);
    setAirportTransfer(false);
    setPets(false);
    setCoupleFriendly(false);
    setCustomAmenities('');
    setRoomTypes([]);
    setArea('');
    setSubCount('');
    setBedType('');
    setTypeName('');
  }

  const handleHotelSelect = (opt) => {
    setSelectedHotel(opt)
    setName(opt.label)
    setRating(opt.star)
    setAddress(opt.address)
    setType(opt.category)
  }


  return (
    <div className='mt-4 mb-4'>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={city} onChange={handleChange} aria-label="tabs">
            {
              typeof clientProperties !== 'undefined' ?
                Object.keys(clientProperties).map((item, idx) => (
                  <Tab label={item} {...a11yProps(idx)} />
                ))
                :
                null
            }
          </Tabs>
        </Box>

        {/* List all properties of selected city */}
        <div className="p-4 mt-2">
          <h5 className="mb-2 bold text-orange">Properties</h5>
          {
            typeof data[cities[city]] !== 'undefined' && data[cities[city]]['properties'].length ?

              data[cities[city]]['properties'].map((item, idx) => (
                <div className='w-100 mb-4 row d-flex justify-content-between align-items-center summary-card p-3'>
                  <div className='col-1'>
                    <div className='summary-card-icon'><i class="fa-solid fa-hotel"></i></div>
                  </div>
                  <div className='col-9 border-right'>
                    <h5 className='bold mb-2'>{item['property_name']}</h5>
                    <div className='mt-2 d-flex align-items-center'>
                      {/* <p className='mr-3 small grey-2'><i class="mx-1 fa-solid fa-right-to-bracket"></i>Check in: {item['check_in_time']}</p> */}
                      {/* <p className='mr-3 small grey-2'><i class="mx-1 fa-solid fa-right-from-bracket"></i>Check out: {item['check_out_time']}</p> */}
                      <p className='mr-3 small grey-2'><i class="mx-1 fa-solid fa-house"></i>Property type: {item['property_type']}</p>
                      <p className='mr-3 small grey-2'><i class="mx-1 fa-solid fa-bed"></i>Room types: {item['room_types'].length}</p>
                    </div>
                  </div>
                  <div className='col-2 d-flex justify-content-end align-items-center'>
                    <Rating size="medium" value={parseInt(item['rating'])} readOnly />
                  </div>
                </div>
              ))
              :
              <Alert className="text-center" severity="info">No properties added...</Alert>
          }
        </div>



        {/* Form to add new property */}
        <div className="p-4 mt-3">
          <h5 className="bold text-orange">Add New Property</h5>

          <div className="mt-3 w-100 row">
            <div className='col-4'><label className=''>Property Name</label></div>
            {/* <div className='col-8'><input value={name} onChange={(evt) => setName(evt.target.value) } type="text" className="mt-1 form-control" placeholder="Property name" aria-label="Propertyname"/></div> */}
            <div className='col-8'>
              <Select placeholder="Select property" value={selectedHotel} onChange={opt => {
                handleHotelSelect(opt)
              }} options={hotels.map(item => ({ 'label': item['hotel_name'], 'value': item['hotel_id'], 'city': item['city'], 'address': item['address'], 'star': item['star'], 'category': item['catagory'], 'latitude': item['latitude'], 'longitude': item['longitude'] }))} className="w-100" />
            </div>
          </div>

          {
            selectedHotel.value ?
              <>
                <div className="mt-4 w-100 row">
                  <div className='col-4'>
                    <label className=''>Property Rating</label>
                    {/* <p className='small grey-2 light'>Maximum 5 stars for domestic properties and 7 stars for international ones</p> */}
                  </div>
                  <div className='col-8'>
                    <Rating
                      disabled={true}
                      className="mt-1"
                      size="large"
                      value={rating}
                      onChange={(event, newValue) => {
                        setRating(newValue);
                      }}
                      max={parseInt(international) === 0 ? 5 : 7}
                    />
                  </div>
                </div>

                {/* <div className="mt-4 w-100 row">
                  <div className='col-4'>
                    <label className=''>Check in time</label>
                  </div>
                  <div className='col-8'>
                    <select value={checkInTime} className="form-control mt-1" onChange={(evt) => setCheckInTime(evt.target.value)}>
                      <option select hidden>Select a time</option>
                      <option value="12PM">12PM</option>
                      <option value="1PM">1PM</option>
                      <option value="2PM">2PM</option>
                      <option value="3PM">3PM</option>
                      <option value="4PM">4PM</option>
                      <option value="5PM">5PM</option>
                    </select>
                  </div>
                </div> */}

                {/* <div className="mt-4 w-100 row">
                  <div className='col-4'>
                    <label className=''>Check out time</label>
                  </div>
                  <div className='col-8'>
                    <select value={checkOutTime} className="form-control mt-1" onChange={(evt) => setCheckOutTime(evt.target.value)}>
                      <option select hidden>Select a time</option>
                      <option value="8AM">8AM</option>
                      <option value="9AM">9AM</option>
                      <option value="10AM">10AM</option>
                      <option value="11AM">11AM</option>
                    </select>
                  </div>
                </div> */}

                <div className="mt-3 w-100 row">
                  <div className='col-4'>
                    <label className=''>Property Type</label>
                  </div>
                  <div className='col-8'>
                    <input className='mt-1 form-control' disabled value={type}/>
                    {/* <select className="mt-1 form-control" value={type} onChange={(evt) => setType(evt.target.value)}>
                      <option selected hidden>Select a type</option>
                      <option value="Airport Hotels">Airport Hotels</option>
                      <option value="Apartment">Apartment</option>
                      <option value="Bed & Breakfasts">Bed & Breakfasts</option>
                      <option value="Boutique">Boutique</option>
                      <option value="Bunkhouse">Bunkhouse</option>
                      <option value="Business Hotels">Business Hotels</option>
                      <option value="Casino Hotels">Casino Hotels</option>
                      <option value="Chain Hotels">Chain Hotels</option>
                      <option value="Conference Center">Conference Center</option>
                      <option value="Deluxe Hotel Rooms">Deluxe Hotel Rooms</option>
                      <option value="Hostel">Hostel</option>
                      <option value="Inn">Inn</option>
                      <option value="Lodge">Lodge</option>
                      <option value="Motel">Motel</option>
                      <option value="Resort">Resort</option>
                      <option value="Studio">Studio</option>
                      <option value="Other">Other</option>
                    </select> */}
                  </div>
                </div>

                <div className="mt-3 w-100 row">
                  <div className='col-4'>
                    <label className=''>Property Address</label>
                  </div>
                  <div className='col-8'>
                    <textarea disabled value={address} onChange={(evt) => setAddress(evt.target.value)} className='form-control' placeholder='Property Address' />
                  </div>
                </div>


                {/* <div className="mt-3 w-100 row">
                  <div className="col-6">
                    <h5 className='bold text-orange'>Amenities</h5>
                  </div>
                  <div className="mt-3 w-100 row">
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setAC(!AC)} id="Air-conditioned" class="form-check-input" type="checkbox" value="" checked={AC} />
                        <label class="form-check-label" for="Air-conditioned">
                          <i class="fa-solid fa-wind mx-2"></i> Air conditioned
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setRestaurant(!restaurant)} id="Restaurant" class="form-check-input" type="checkbox" checked={restaurant} />
                        <label class="form-check-label" for="Restaurant">
                          <i class="fa-solid fa-utensils mx-2"></i> Restaurant
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setBar(!bar)} id="Bar" class="form-check-input" type="checkbox" checked={bar} />
                        <label class="form-check-label" for="Bar">
                          <i class="fa-solid fa-martini-glass-citrus mx-2"></i> Bar
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setRoomService(!roomService)} id="Room-service" class="form-check-input" type="checkbox" checked={roomService} />
                        <label class="form-check-label" for="Room-service">
                          <i class="fa-solid fa-mug-hot mx-2"></i> Room service
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 w-100 row">
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setLaundry(!laundry)} id="Laundry" class="form-check-input" type="checkbox" checked={laundry} />
                        <label class="form-check-label" for="Laundry">
                          <i class="fa-solid fa-jug-detergent mx-2"></i> Laundry
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setParking(!parking)} id="Parking" class="form-check-input" type="checkbox" checked={parking} />
                        <label class="form-check-label" for="Parking">
                          <i class="fa-solid fa-square-parking mx-2"></i> Parking
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setInternet(!internet)} id="Internet" class="form-check-input" type="checkbox" checked={internet} />
                        <label class="form-check-label" for="Internet">
                          <i class="fa-solid fa-wifi mx-2"></i> Internet
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setWlan(!wlan)} id="Wlan-Access" class="form-check-input" type="checkbox" checked={wlan} />
                        <label class="form-check-label" for="Wlan-Access">
                          <i class="fa-solid fa-signal mx-2"></i> WLAN Access
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 w-100 row">
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setGym(!gym)} id="Gym" class="form-check-input" type="checkbox" checked={gym} />
                        <label class="form-check-label" for="Gym">
                          <i class="fa-solid fa-dumbbell mx-2"></i> Gym
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setSpa(!spa)} id="Spa" class="form-check-input" type="checkbox" checked={spa} />
                        <label class="form-check-label" for="Spa">
                          <i class="fa-solid fa-leaf mx-2"></i> Spa
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setSwimming(!swimming)} id="Swimming-Pool" class="form-check-input" type="checkbox" checked={swimming} />
                        <label class="form-check-label" for="Swimming-Pool">
                          <i class="fa-solid fa-water-ladder mx-2"></i> Swimming Pool
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setVehicle(!vehicle)} id="Vehicle-Rentals" class="form-check-input" type="checkbox" checked={vehicle} />
                        <label class="form-check-label" for="Vehicle-Rentals">
                          <i class="fa-solid fa-car mx-2"></i> Vehicle Rentals
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 w-100 row">
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setAirportTransfer(!airportTransfer)} id="Airport-transfers" class="form-check-input" type="checkbox" checked={airportTransfer} />
                        <label class="form-check-label" for="Airport-transfers">
                          <i class="fa-solid fa-plane-departure mx-2"></i> Airport transfers
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setPets(!pets)} id="Pets-allowed" class="form-check-input" type="checkbox" checked={pets} />
                        <label class="form-check-label" for="Pets-allowed">
                          <i class="fa-solid fa-dog mx-2"></i> Pets allowed
                        </label>
                      </div>
                    </div>

                    <div className="col-3">
                      <div class="form-check">
                        <input onChange={() => setCoupleFriendly(!coupleFriendly)} id="Couple-friendly" class="form-check-input" type="checkbox" checked={coupleFriendly} />
                        <label class="form-check-label" for="Couple-friendly">
                          <i class="fa-solid fa-heart mx-2"></i> Couple friendly
                        </label>
                      </div>
                    </div>

                  </div>

                  <div className='mt-5 w-100 row'>
                    <div className='col-4'>
                      <label className='grey-2'>Custom Ameneties</label>
                      <p className='small grey-2'>Ex. Gaming area, Charging station, Medical facility etc.</p>
                    </div>
                    <div className='col-8'>
                      <input className='form-control' onChange={(evt) => setCustomAmenities(evt.target.value)} placeholder='Enter additional amenities (if any)' type="text" value={customAmenities} />
                    </div>
                  </div>
                </div> */}


                <div className="mt-3 py-3 w-100 row">
                  <div class="col-6">
                    <h5 className='bold text-orange'>Room Types</h5>
                  </div>

                  <div className='mt-2 w-100 row'>
                    <div className='col-4'><label className=''>Room type name</label></div>
                    <div className='col-8'>
                      <select className='form-select'  value={typeName} onChange={(evt) => setTypeName(evt.target.value)} placeholder='Room type'>
                        <option value="" selected hidden></option>
                        <option value='apartment'>Apartment</option>
                        <option value='classic'>Classic</option>
                        <option value='club'>Club</option>
                        <option value='club-executive'>Club Executive</option>
                        <option value='deluxe'>Deluxe</option>
                        <option value='duplex'>Duplex</option>
                        <option value='executive'>Executive</option>
                        <option value='standard'>Standard</option>
                        <option value='lounge'>Lounge</option>
                        <option value='luxury'>Luxury</option>
                        <option value='palace'>Palace</option>
                        <option value='premier'>Premier</option>
                        <option value='premium'>Premium</option>
                        <option value='presidential'>Presidential</option>
                        <option value='privilege'>Privilege</option>
                        <option value='suite'>Suite</option>
                        <option value='superior'>Superior</option>
                        <option value='villa'>Villa</option>
                      </select>
                      {/* <input placeholder='Ex. Premium Suite' onChange={(evt) => setTypeName(evt.target.value)} class="mt-1 form-control" type="text" value={typeName} /> */}
                    </div>
                  </div>

                  <div className='mt-3 w-100 row'>
                    <div className='col-4'><label className=''>Bed type</label></div>
                    <div className='col-8'>
                      <select className="mt-1 form-control" value={bedType} onChange={(evt) => setBedType(evt.target.value)}>
                        <option selected hidden>Select bed type</option>
                        <option value="Single">Single Bed</option>
                        <option value="Twin">Twin Bed</option>
                        <option value="Double">Double Bed</option>
                        <option value="Queen">Queen Bed</option>
                        <option value="King">King Bed</option>
                        <option value="Super King">Super King Bed</option>
                      </select>
                    </div>
                  </div>

                  <div className='mt-3 w-100 row'>
                    <div className='col-4'><label className=''>Carpet area</label>
                    {/* <p className='small grey-2'>Enter value in square feet (sq. ft.)</p> */}
                    </div>
                    <div className='col-8'><input placeholder="Carpet area (in sq. ft.)" onChange={(evt) => setArea(evt.target.value)} min={10} class="mt-1 form-control" type="number" value={area} /></div>
                  </div>

                  <div className='mt-3 w-100 row'>
                    <div className='col-4'><label className=''>Number of rooms</label></div>
                    <div className='col-8'><input placeholder="Number of rooms" onChange={(evt) => setSubCount(evt.target.value)} min={1} class="mt-1 form-control" type="number" value={subCount} /></div>
                  </div>

                  <div className='mt-4 w-100 d-flex justify-content-center align-items-center'>
                    <Button size='large' style={{ width: '100%' }} onClick={() => addRoomType()} variant="outlined"><i class="fa-solid fa-plus mx-2"></i> Add room type</Button>
                  </div>

                  {/* List added room types */}
                  <div className="mt-4 p-1 mb-3">
                    <div className='w-100 d-flex justify-content-between align-items-center'>
                      <div>
                        <h5 className='bold text-orange'>Room Priority Order</h5>
                        <p className='text-muted small'>Rearrange the tiles to set the priority order of room types.</p>
                      </div>
                      <div className='mx-4'>
                        <Tooltip title="Set priority order for room types">
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    {
                      roomTypes.length ?
                        <>
                          <Box>
                            <SortableContainer onSortEnd={onSortEnd}>
                              {roomTypes.map((type, index) => (
                                <SortableItem key={index} index={index} roomType={type} />
                              ))}
                            </SortableContainer>
                          </Box>
                        </>
                        :
                        <Alert severity="info">No room types configured</Alert>
                    }
                  </div>
                </div>

                <div>
                  <FocusCompetition focusComp={focusComp} setFocusComp={setFocusComp} selectedHotel={selectedHotel} clientProperties={clientProperties} setClientProperties={setClientProperties} />
                </div>

                <div className="w-100 mt-4 d-flex justify-content-center align-items-center">
                  <Button size='large' variant="contained" onClick={() => addProperty()}>Add Property</Button>
                </div>
              </>
              :
              null
          }


        </div>
      </Box>
    </div>
  )
};

export default ClientProperties;