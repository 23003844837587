import * as React from 'react';
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import LinearStepper from '../../components/common/Stepper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Grow from '@mui/material/Grow';
import axios from 'axios';

import BasicDetails from './BasicDetails';
import ClientRegions from './ClientRegions';
import ClientProperties from './ClientProperties';
import FocusCompetition from './FocusCompetition';
import Summary from './Summary';

import { toast } from 'react-toastify';
import {fetchToken} from '../../Auth';
import hotel from '../../assets/img/hotel.png';

const NewClient = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [showComplete, setShowComplete] = useState(false);

  const steps = ['Basic details', 'Set regions', 'Configure Properties & Rooms', 'Summary'];


  //Client fields
  const [name, setName] = useState("");
  const [domain, setDomain] = useState("");
  const [international, setInternational] = useState('0');
  const [headOffice, setHeadOffice] = useState({'label': '', 'value': ''});
  const [properties, setProperties] = useState({});
  const [focusComp, setFocusComp] = useState({});
  const [showInput, setShowInput] = useState(false);
  const [otherCity, setOtherCity] = useState('');

  const navigate = useNavigate();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if(activeStep === 0){
      if(!name){
        toast.error('Please provide a client name!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
        return
      }
      if(/^[0-9]+$/.test(name)){
        toast.error('Client name cannot contain only numerical characters!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
        return
      }
      if(/^[^a-zA-Z0-9]+$/.test(name)){
        toast.error('Client name cannot contain only special characters!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
        return
      }
      if(domain && !/^(?!.*(?:http|www))[a-zA-Z0-9]+(?:\.[a-zA-Z]{2,})+$/.test(domain)){
        toast.error('Invalid website URL entered!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
        return
      }
      if(!headOffice['value']){
        toast.error("Please select the client's head office!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
        return 
      }
      if(headOffice['value'] === 'Other' && !otherCity){
        toast.error("Enter the head office location!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
        return  
      }
    }

    if(activeStep === 1){
      if(!Object.keys(properties).length){
        toast.error("Please select the client's regions!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
        return 
      }
    }

    if(activeStep === 2){
      for(let city in properties){
        if(!properties[city]['properties'].length){
          toast.error(`Please configure properties for ${city} region!`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
          });
          return; 
        }
      }
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const createClient = async () => {
    const newClient = {
      clientName: name,
      clientDomain: domain,
      international: parseInt(international) === 0 ? false : true,
      headOffice: headOffice['value'] === 'Other' ? otherCity : headOffice['value'],
      clientCities: properties
    }

    try {
      const response = await axios.post("/create_new_client", {newClient}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
          },
      });
      if(response.status === 200 && response.data !== undefined){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setShowComplete(true);
      }
    } catch (error) {
      console.error("Error", error);
      if(error.response !== undefined && error.response.status === 409){
          toast.error(error.response.data.msg, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
          });
          return;
      }
      if(error.response !== undefined && error.response.status === 500){
          toast.error(`Something went wrong!`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
          });
          return;
      }
    }
  };

  useEffect(() => {
    if(headOffice && headOffice['value'] === 'Other'){
      setShowInput(true)
    }else{
      setShowInput(false)
    }
  }, [headOffice])
  
  return(
    <Grow
      in={true}
      {...(true ? { timeout: 700 } : {})}
    >
    <section className='p-4 m-4 white-card'>

      <div className="w-100 row mb-4 mt-2">
        <div className="col-12">
          <h4 className="dashboard-heading">Setup a New Client</h4>
          <p className="mt-2" style={{color: '#888', fontSize: '0.9em', fontWeight: '300'}}>Onboard new clients by setting up basic details, configuring client regions and adding properties for each region.</p>
        </div>
      </div>

      <LinearStepper steps={steps} skipped={skipped} activeStep={activeStep} setActiveStep={setActiveStep} isStepSkipped={isStepSkipped}/>

      {!showComplete ?  (
        <React.Fragment>
          {
            activeStep === 0 ? <BasicDetails name={name} domain={domain} headOffice={headOffice} setName={setName} setDomain={setDomain} setHeadOffice={setHeadOffice} international={international} setInternational={setInternational} showInput={showInput} otherCity={otherCity} setOtherCity={setOtherCity} /> : null
          }
          {
            activeStep === 1 ? <ClientRegions properties={properties} setProperties={setProperties} international={international}/> : null
          }
          {
            activeStep === 2 ? <ClientProperties focusComp={focusComp} setFocusComp={setFocusComp} international={international} clientProperties={properties} setClientProperties={setProperties}/> : null
          }
          {
            activeStep === 3 ? <Summary name={name} domain={domain} headOffice={headOffice} international={international} clientProperties={properties} createClient={createClient} showComplete={showComplete}/> : null
          }
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {
              activeStep !== steps.length ?
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                size="medium"
                variant="outlined"
              >
               <KeyboardArrowLeftIcon /> BACK 
              </Button>
              :
              null
            }
            <Box sx={{ flex: '1 1 auto' }} />

            
            {
              activeStep === steps.length - 1 ?
              null
              :
              <Button color="inherit" size="medium"
              variant="outlined" onClick={handleNext}>
                Next <KeyboardArrowRightIcon />
              </Button>
            }

            
          </Box>
        </React.Fragment>
      )
      :
      <React.Fragment>
        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
          <img className='hotel' src={hotel} alt='Hotel' />
          <h3 className='bold'><span className='text-orange'>{name}</span> Onboarded successfully!</h3>
          <p className='text-muted light'>You can now create users for this client, assign roles and user scope through the User Management Dashboard.</p>
          <div className="mt-3 mb-5 w-100 px-5 d-flex justify-content-between align-items-center">
            <Button className="w-50 mx-4" onClick={() => navigate('/home') } variant="outlined">Maybe later</Button>
            <Button className="w-50 mx-4" onClick={() => navigate('/admin/user-management') } variant="contained">Create users</Button>
          </div>
        </div>
      </React.Fragment>
    }

            
    </section>
    </Grow>
  )
};

export default NewClient;