import React, { useRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FocusComp from "./FocusComp";
import RateParity from "./RateParity";
import RoomDetails from "./RoomDetails";
import PriceHistoryDialogue from "./PriceHistoryDialogue";

import {
  format,
  addDays,
  startOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import PriceHistory from "./PriceHistory";
import Chip from "@mui/material/Chip";
import { keyframes } from "@mui/system";
import TrainIcon from "@mui/icons-material/Train";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import soldOut from "../../../assets/img/sold-out-2.png";
import rightArrow from "../../../assets/img/rightArrow.png";
import leftArrow from "../../../assets/img/leftArrow.png";
import StraightIcon from "@mui/icons-material/Straight";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

import Tooltip from "@mui/material/Tooltip";
import FlagIcon from "./FlagIcon";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Button from '@mui/material/Button';
import EqualizerIcon from '@mui/icons-material/Equalizer';

const Calendar = ({
  today,
  ota,
  pms,
  tenantId,
  data,
  equRoomData,
  trainData,
  selectedMonth,
  selectedYear,
  selectedProperty,
  selectedPropertyName,
  filters,
  selectedFlagMonth,
  selectedFlagYear,
  calLength,
  startDate,
  endDate,
}) => {
  const [open, setOpen] = useState(false);

  const [modalDate, setModalDate] = useState("");
  const [updateTime, setUpdateTime] = useState("");
  const [modalData, setModalData] = useState({});

  const [expanded, setExpanded] = useState(false);

  const [priceHistoryOpen, setPriceHistoryOpen] = useState(false);

  const [clubedRoomDetails, setClubedRoomDetails] = useState({});

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const PillShapedButton = ({ children, onClick, startIcon }) => {
    return (
      <Button
        onClick={onClick}
        size="small"
        variant="contained"
        endIcon={startIcon}
        sx={{
          borderRadius: '50px',
          paddingLeft: '16px',
          paddingRight: '16px',
          minWidth: 'auto',
          height: '30px',
          '& .MuiButton-startIcon': {
            marginRight: '8px',
          },
          marginRight: "2.5em"
        }}
      >
        {children}
      </Button>
    );
  };

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [filterNames, setFilterNames] = useState([
    {
      name: "Competition Price Increase",
      val: "comp_price_inc",
      color: "rgb(255, 174, 1)",
      icon: "inc",
      background: "linear-gradient(90deg, #FFC46B 0%, #FF5C01 100%)",
      initial: "CP",
      flagShort: "Comp price",
    },
    {
      name: "Competition Price Decrease",
      val: "comp_price_dec",
      color: "rgb(255, 174, 1)",
      icon: "dec",
      background: "linear-gradient(90deg, #FFC46B 0%, #FF5C01 100%)",
      initial: "CP",
      flagShort: "Comp price",
    },
    {
      name: "OverPriced",
      flagShort: "Overpriced",
      val: "overpriced",
      color: "#f68d8e",
      icon: "inc",
      background: "linear-gradient(90deg, #FFBBBB 0%, #FF3737 100%)",
      initial: "OP",
    },
    {
      name: "UnderPriced",
      flagShort: "Underpriced",
      val: "underpriced",
      color: "#f68d8e",
      icon: "dec",
      background: "linear-gradient(90deg, #FFBBBB 0%, #FF3737 100%)",
      initial: "UP",
    },
  ]);

  // Get the current date and find the first Monday before or on the current date
  const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 });

  // Generate the next 365 days
  const days = Array.from({ length: calLength }, (_, i) =>
    addDays(startOfCurrentWeek, i)
  );

  const refs = useRef({});

  const dummyData = Array.from({ length: 9 }, (_, i) => `Card ${i + 1}`);

  const DayItem = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.7),
    textAlign: "center",
    color: "#000c",
    fontWeight: "bold",
  }));

  const Card = styled(Box)(({ theme, bg }) => ({
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: "15em",
    minHeight: "15em",
    maxHeight: "15em",
    width: "100%",
    border: filters.length ? "2px solid #ededed" : "1px solid #ededed",
    marginTop: "0.55em",
    borderRadius: "4px",
    backgroundColor: "#f8f8f8",
    // backgroundColor:
    //   bg === "yellow" ? "#F4E07A" : bg === "red" ? "#f68d8e" : "#f8f8f8",
    cursor: "pointer",
  }));
  const rippleEffect = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    70% {
        box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
`;

  const RippleContainer = styled("div")(({ theme }) => ({
    display: "inline-flex", // Change to inline-flex
    position: "relative",
    borderRadius: "16px", // Match Chip's default borderRadius
    animation: `${rippleEffect} 1.5s infinite`,
    // padding: '2px', // Add slight padding around the Chip
  }));
  const StickyHeader = styled(Box)(({ theme }) => ({
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  }));

  function deslugify(slug) {
    return slug
      .split("-") // Split the string by hyphens
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words with spaces
  }

  const isSameDate = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  useEffect(() => {
    const element = document.querySelector(
      `[data-card="${selectedMonth}-${selectedYear}"]`
    );
    const calendarContainer = document.querySelector(".calender-card");

    if (element) {
      const elementRect = element.getBoundingClientRect();
      const containerRect = calendarContainer.getBoundingClientRect();
      const offset = elementRect.top - containerRect.top;

      calendarContainer.scrollBy({
        top: offset - 260,
        behavior: "smooth",
      });
    }
  }, [data]);



  const handlePrevNextClick = (type, modalDate) => {
    let newDate = new Date(modalDate);

    const formattedModalDate = format(newDate, "yyyy-MM-dd");
    const formattedStartDate = format(new Date(startDate), "yyyy-MM-dd");
    const formattedEndDate = format(new Date(endDate), "yyyy-MM-dd");

    if (type === "prev") {
      // If moving to the previous day would go below the start date, return
      if (formattedModalDate <= formattedStartDate) {
        return;
      }
      newDate.setDate(newDate.getDate() - 1);
    } else if (type === "next") {
      // If moving to the next day would go beyond the end date, return
      if (formattedModalDate >= formattedEndDate) {
        return;
      }
      newDate.setDate(newDate.getDate() + 1);
    }

    // Update the modalDate and modalData
    const newFormattedDate = format(newDate, "yyyy-MM-dd");
    setModalDate(newFormattedDate);

    if (newFormattedDate in data && !data[newFormattedDate]['master_doc_missing']) {
      setModalData(data[newFormattedDate]);
    } else if (newFormattedDate in equRoomData || data[newFormattedDate]['master_doc_missing']) {
      setModalData(equRoomData[newFormattedDate]);
    }
  };
  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    return daysOfWeek[date.getDay()];
  }
  function convertDateFormat(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  const handleTileClick = (date, updateTime) => {
    setModalDate(date);
    if (format(date, "yyyy-MM-dd") in data) {
      if (data[format(date, "yyyy-MM-dd")]["master_doc_missing"]) {
        if (format(date, "yyyy-MM-dd") in equRoomData) {
          setModalData(equRoomData[format(date, "yyyy-MM-dd")]);
          console.log(equRoomData[format(date, "yyyy-MM-dd")])
        }
      } else {
        setModalData(data[format(date, "yyyy-MM-dd")]);
      }
    }

    setUpdateTime(updateTime);
    setOpen(true);
  };
  const handleFilter = (flags) => {
    if (!flags || !filters || filters.length === 0) {
      return "";
    }

    const flagSet = new Set(flags);
    const filterSet = new Set(filters);

    if (
      flagSet.size === filterSet.size &&
      [...flagSet].every((value) => filterSet.has(value))
    ) {
      return "exact-match";
    } else if (filters.some((filter) => flagSet.has(filter))) {
      return "similar-match";
    } else {
      return "";
    }
  };
  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    return daysOfWeek[date.getDay()];
  }
  function convertDateFormat(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  const fontSizeCal = {
    fontSize: "1.2em",
  };

  const flagActive = true;

  return (
    <>
      <Box className="w-100" sx={{ flexGrow: 1, width: "100%" }}>
        <Grid container spacing={1}>
          <Grid
            container
            spacing={0.6}
            // sx={{ position: "sticky", top:'7.9em', zIndex: 1 }}
            sx={{
              position: "sticky",
              top: "0em",
              zIndex: 1,
              marginBottom: "0.5em",
            }}
          >
            {daysOfWeek.map((day, index) => (
              <Grid
                style={{ backgroundColor: "#eee", ...fontSizeCal }}
                item
                xs={1.71}
                key={index}
              >
                <DayItem>{day}</DayItem>
              </Grid>
            ))}
          </Grid>
          {daysOfWeek.map((day, index) => (
            <Grid item xs={1.71} key={day}>
              {days
                .filter((date) => date.getDay() === (index + 1) % 7)
                // .filter(date => format(date, 'yyyy-MM-dd') in data)
                .map((date, i) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card
                      className={(() => {
                        const formattedDate = format(date, "yyyy-MM-dd");
                        const [dateYear, dateMonth] = formattedDate.split("-");
                        let finalArr = [];
                        if (
                          trainData &&
                          typeof trainData[formattedDate] !== "undefined" &&
                          "train" in trainData[formattedDate] &&
                          trainData[formattedDate]["train"].length &&
                          trainData[formattedDate]["train"].includes(
                            "DemandSpike"
                          )
                        ) {
                          finalArr.push("DemandSpike");
                        }

                        // Check if the date is in the correct year and month
                        if (
                          selectedFlagYear === null ||
                          selectedMonth === null
                        ) {
                          if (
                            formattedDate in data &&
                            "flags" in data[formattedDate] &&
                            data[formattedDate]["flags"][ota] &&
                            data[formattedDate]["flags"][ota].length > 0
                          ) {
                            return handleFilter([
                              ...finalArr,
                              ...data[formattedDate]["flags"][ota],
                            ]);
                          }
                        } else if (
                          dateYear === selectedFlagYear.toString() &&
                          dateMonth ===
                          selectedFlagMonth.toString().padStart(2, "0")
                        ) {
                          // If date is in the correct year and month, proceed with the original logic
                          if (
                            formattedDate in data &&
                            "flags" in data[formattedDate] &&
                            data[formattedDate]["flags"][ota] &&
                            data[formattedDate]["flags"][ota].length > 0
                          ) {
                            return handleFilter([
                              ...finalArr,
                              ...data[formattedDate]["flags"][ota],
                            ]);
                          }
                        }

                        // If conditions are not met, return an empty string or a default class
                        return "";
                      })()}
                      onClick={() =>
                        format(date, "yyyy-MM-dd") in data
                          ? handleTileClick(
                            format(date, "yyyy-MM-dd"),
                            data[format(date, "yyyy-MM-dd")]["update_time"],
                            data[format(date, "yyyy-MM-dd")],
                            ota,
                            data[format(date, "yyyy-MM-dd")]["property_id"],
                            data[format(date, "yyyy-MM-dd")]["tenant_id"],
                            pms
                          )
                          : null
                      }
                      key={i}
                      style={{ margin: "7px 0" }}
                      bg={
                        format(date, "yyyy-MM-dd") in data &&
                          "flags" in data[format(date, "yyyy-MM-dd")] &&
                          data[format(date, "yyyy-MM-dd")]["flags"][ota].length
                          ? data[format(date, "yyyy-MM-dd")]["flags"][
                            ota
                          ].includes("comp_price_inc")
                            ? "yellow"
                            : data[format(date, "yyyy-MM-dd")]["flags"][
                              ota
                            ].includes("comp_price_dec")
                              ? "yellow"
                              : data[format(date, "yyyy-MM-dd")]["flags"][
                                ota
                              ].includes("underpriced")
                                ? "red"
                                : data[format(date, "yyyy-MM-dd")]["flags"][
                                  ota
                                ].includes("overpriced")
                                  ? "red"
                                  : ""
                          : ""
                      }
                    >
                      {/* <Tooltip title={data[format(date, "yyyy-MM-dd")]["flags"][ota]} style={{ postion: 'absoulte', top: '50%' }} arrow> */}
                      <div
                        data-card={format(date, "M-yyyy")}
                        className="d-flex flex-column justify-content-between"
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div style={{ width: "8em", paddingRight: "0" }}>
                            {isSameDate(date, new Date(today)) ? (
                              <RippleContainer>
                                <Chip
                                  label={format(date, "dd/MM/yyyy")}
                                  size="small"
                                  sx={{
                                    fontSize: "0.8em",
                                    fontWeight: "500",
                                    // backgroundColor: "#FF8B00",
                                    backgroundColor: "black",

                                    color: "white",
                                  }}
                                />
                              </RippleContainer>
                            ) : (
                              <>
                                <div>
                                  <Chip
                                    label={format(date, "dd/MM/yyyy")}
                                    size="small"
                                    sx={{
                                      fontSize: "0.8em",
                                      fontWeight: "300",
                                      backgroundColor: "#29335c",
                                      color: "white",
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          <div className="d-flex flex-end flex-gap gap-1">
                            <div>
                              {format(date, "yyyy-MM-dd") in equRoomData &&
                                "is_equ_room" in
                                equRoomData[format(date, "yyyy-MM-dd")] &&
                                equRoomData[format(date, "yyyy-MM-dd")][
                                "is_equ_room"
                                ] ? (
                                <Tooltip
                                  title={`${equRoomData[format(date, "yyyy-MM-dd")][
                                    "room_type"
                                  ]
                                    } | ${equRoomData[format(date, "yyyy-MM-dd")][
                                    "meal_plan"
                                    ]
                                    }`}
                                >
                                  <AutoFixHighIcon
                                    style={{
                                      color: "orange",
                                      fontSize: "1.5em",
                                    }}
                                  />
                                </Tooltip>
                              ) : null}
                            </div>

                            {format(date, "yyyy-MM-dd") in data &&
                              "flags" in data[format(date, "yyyy-MM-dd")] &&
                              Array.isArray(
                                data[format(date, "yyyy-MM-dd")]["flags"][ota]
                              ) &&
                              data[format(date, "yyyy-MM-dd")]["flags"][ota]
                                .length > 0 &&
                              data[format(date, "yyyy-MM-dd")]["flags"][
                                ota
                              ].map((item, index) => {
                                return (
                                  <FlagIcon
                                    key={index}
                                    flag={item}
                                    filter={filterNames}
                                  />
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <div style={{ ...fontSizeCal }}>
                          <>
                            <p className="mb-0">
                              P:{" "}
                              <span className="bold">
                                ₹{" "}
                                {typeof data[format(date, "yyyy-MM-dd")] !==
                                  "undefined" &&
                                  data[format(date, "yyyy-MM-dd")][
                                  "base_room_price"
                                  ]
                                  ? data[format(date, "yyyy-MM-dd")][
                                    "base_room_price"
                                  ].toLocaleString("en-IN")
                                  : format(date, "yyyy-MM-dd") in equRoomData
                                    ? equRoomData[format(date, "yyyy-MM-dd")][
                                      "base_room_price"
                                    ].toLocaleString("en-IN")
                                    : "-"}
                              </span>
                            </p>

                            {tenantId == 33 ? (
                              <>
                                <p className="mb-0">
                                  O:{" "}
                                  <span className="">
                                    {typeof data[format(date, "yyyy-MM-dd")] !==
                                      "undefined" &&
                                      "inventory_data" in
                                      data[format(date, "yyyy-MM-dd")] &&
                                      "overall_inv" in
                                      data[format(date, "yyyy-MM-dd")][
                                      "inventory_data"
                                      ]
                                      ? `${parseInt(
                                        ((data[format(date, "yyyy-MM-dd")][
                                          "inventory_data"
                                        ]["overall_inv"] -
                                          data[format(date, "yyyy-MM-dd")][
                                          "inventory_data"
                                          ]["overall_avl"]) /
                                          data[format(date, "yyyy-MM-dd")][
                                          "inventory_data"
                                          ]["overall_inv"]) *
                                        100
                                      )}%`
                                      : "-"}
                                  </span>
                                </p>
                                <p className="mb-0">
                                  A/T:{" "}
                                  <span className="">
                                    {typeof data[format(date, "yyyy-MM-dd")] !==
                                      "undefined" &&
                                      "inventory_data" in
                                      data[format(date, "yyyy-MM-dd")]
                                      ? `${data[format(date, "yyyy-MM-dd")][
                                      "inventory_data"
                                      ]["avl_inventory"]
                                      }/${data[format(date, "yyyy-MM-dd")][
                                      "inventory_data"
                                      ]["total_inventory"]
                                      }`
                                      : "-"}
                                  </span>
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="mb-0">
                                  RT:{" "}
                                  <span className="medium">
                                    {typeof data[format(date, "yyyy-MM-dd")] !==
                                      "undefined" &&
                                      "room_type" in
                                      data[format(date, "yyyy-MM-dd")]
                                      ? deslugify(
                                        data[format(date, "yyyy-MM-dd")][
                                        "room_type"
                                        ]
                                      ).slice(0, 20)
                                      : format(date, "yyyy-MM-dd") in
                                        equRoomData
                                        ? deslugify(
                                          equRoomData[
                                          format(date, "yyyy-MM-dd")
                                          ]["room_type"]
                                        ).slice(0, 20)
                                        : "-"}
                                  </span>
                                </p>
                                <p className="mb-0">
                                  MP:{" "}
                                  <span className="medium">
                                    {typeof data[format(date, "yyyy-MM-dd")] !==
                                      "undefined" &&
                                      "meal_plan" in
                                      data[format(date, "yyyy-MM-dd")]
                                      ? deslugify(
                                        data[format(date, "yyyy-MM-dd")][
                                        "meal_plan"
                                        ]
                                      )
                                      : format(date, "yyyy-MM-dd") in
                                        equRoomData
                                        ? deslugify(
                                          equRoomData[
                                          format(date, "yyyy-MM-dd")
                                          ]["meal_plan"]
                                        )
                                        : "-"}
                                  </span>
                                </p>
                              </>
                            )}
                          </>
                        </div>
                        <hr className="my-1" />
                        <div className="row">
                          <div className="col-8">
                            <small className="mb-0 text-muted">
                              COMPETITION
                            </small>
                            {format(date, "yyyy-MM-dd") in data &&
                              "comp_price" in data[format(date, "yyyy-MM-dd")] &&
                              ota in
                              data[format(date, "yyyy-MM-dd")]["comp_price"] ? (
                              <p className="mb-0">
                                LCP:{" "}
                                <span className="bold">
                                  ₹{" "}
                                  {(() => {
                                    // Extract the prices from the data object
                                    const prices = Object.values(
                                      data[format(date, "yyyy-MM-dd")][
                                      "comp_price"
                                      ][ota]
                                    )
                                      .filter((item) => item.base_fare != 0)
                                      .map((item) => item.base_fare);

                                    // Find the minimum price from the data object
                                    const minPriceFromData = prices.length
                                      ? Math.min(...prices)
                                      : "";

                                    // Check if min price from data is an empty string or not available
                                    if (
                                      minPriceFromData === "" &&
                                      format(date, "yyyy-MM-dd") in
                                      equRoomData &&
                                      "comp_price" in
                                      equRoomData[format(date, "yyyy-MM-dd")]
                                    ) {
                                      // Fetch minimum price from equRoomData
                                      const equRoomPrices = Object.values(
                                        equRoomData[format(date, "yyyy-MM-dd")][
                                        "comp_price"
                                        ][ota]
                                      )
                                        .filter((item) => item.base_fare != 0)
                                        .map((item) => item.base_fare);

                                      const minPriceFromEquRoom =
                                        equRoomPrices.length
                                          ? Math.min(...equRoomPrices)
                                          : "";

                                      // If min price from equRoomData is available, return it; otherwise, show '-'
                                      return minPriceFromEquRoom !== ""
                                        ? minPriceFromEquRoom.toLocaleString(
                                          "en-IN"
                                        )
                                        : "-";
                                    }

                                    // Return min price from data if it's available
                                    return minPriceFromData.toLocaleString(
                                      "en-IN"
                                    );
                                  })()}
                                </span>
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-4 d-flex justify-content-center align-items-center">
                            {trainData &&
                              format(date, "yyyy-MM-dd") in trainData &&
                              "train" in trainData[format(date, "yyyy-MM-dd")] &&
                              trainData[format(date, "yyyy-MM-dd")]["train"]
                                .length &&
                              trainData[format(date, "yyyy-MM-dd")][
                                "train"
                              ].includes("DemandSpike") ? (
                              <Tooltip
                                title={`${trainData[format(date, "yyyy-MM-dd")][
                                  "avl_seats"
                                ]
                                  } / ${trainData[format(date, "yyyy-MM-dd")][
                                  "total_seats"
                                  ]
                                  }`}
                              >
                                <TrainIcon
                                  style={{ color: "black", fontSize: "1.5em" }}
                                />
                              </Tooltip>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {/* </Tooltip> */}
                    </Card>
                  </div>
                ))}
            </Grid>
          ))}
        </Grid>

        <Dialog
          open={open}
          fullWidth={true}
          maxWidth="lg"
          onClose={() => {
            setOpen(false);
            setExpanded(false);
          }}
          PaperProps={{
            style: {
              width: "95%",
              margin: 0,
              maxWidth: "100%",
              overflow: 'hidden'
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ position: "relative" }}>


            <DialogTitle style={{ paddingBottom: 0, height: "7em" }}>
              <div className="w-100 d-flex flex-column">
                {/* div for first row */}
                <div className="w-100 d-flex justify-content-between align-items-center">

                  <div className="d-flex flex-column col-6">
                    <p className="fw-bold m-0 fs-8">Hotel Details</p>
                    <p className="m-0 fs-8">
                      <span className="fw-bold d-flex align-items-center">
                        {selectedPropertyName} {modalData.master_doc_missing === true && <AutoFixHighIcon sx={{ height: "20px", color: "orange" }} />}
                      </span>
                    </p>
                    <p className="m-0 fs-8">
                      Check in - {convertDateFormat(modalDate)},{" "}
                      {getDayOfWeek(modalDate)}
                    </p>
                    <p className="m-0 fs-8">
                      Base Room: {modalData.room_type_id}
                    </p>
                    <p className="m-0 fs-8">
                      Base Meal Plan: {modalData.meal_plan_name} ({modalData.meal_plan})
                    </p>
                  </div>

                  {clubedRoomDetails && (
                    <div className="d-flex col-5 justify-content-end mr-3 w-30">
                      <div className="title-card font-weight-bold mb-2">
                        <div className="card-item-title">
                          A/T : {clubedRoomDetails.avl_inventory}/
                          {clubedRoomDetails.total_inventory}
                        </div>
                        <div className="card-item-title">
                          Curr Occ{" "}
                          {isNaN(clubedRoomDetails.curr_occ)
                            ? ": -"
                            : ` : ${clubedRoomDetails.curr_occ}%`}
                        </div>
                      </div>
                    </div>

                  )}

                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => {
                      setOpen(false);
                      setExpanded(false);
                    }}
                    aria-label="close"
                    style={{ position: 'absolute', right: '1rem', top: '1rem', cursor: 'pointer' }}
                  >
                    <CloseIcon />
                  </IconButton>



                </div>
                {/* div for first row */}
                <div className="w-100 d-flex justify-content-between align-items-start mt-2">
                  <div className="d-flex justify-content-start col-4">
                    <Chip
                      variant="outlined"
                      label={"View Prev Date"}
                      onClick={() => {
                        handlePrevNextClick("prev", modalDate);
                      }}
                      size="small"
                      className="me-2"
                      disabled={(new Date(modalDate)) < new Date(startDate)}
                    />
                    <Chip
                      variant="outlined"
                      label={"View Next Date"}
                      onClick={() => {
                        handlePrevNextClick("next", modalDate);
                      }}
                      size="small"
                      disabled={(new Date(modalDate)) > new Date(endDate)}
                    />
                  </div>
                  <div className="underprice d-flex justify-content-center col-4">

                    {open &&
                      filterNames.map((filter) => {
                        if (
                          modalData &&
                          modalData.flags &&
                          modalData.flags[ota].includes(filter.val)
                        ) {
                          return (
                            <Chip
                              key={filter.val}
                              label={filter.flagShort}
                              sx={{
                                background: filter.background,
                                color: "white",
                                "& .MuiChip-deleteIcon": {
                                  color: "white",
                                },
                                fontSize: "0.5em",
                              }}
                              className={"me-2"}
                              deleteIcon={
                                filter.icon === "inc" ? (
                                  <NorthIcon
                                    sx={{
                                      pointerEvents: "none",
                                      height: "0.7em",
                                      width: "0.7em",
                                    }}
                                  />
                                ) : (
                                  <SouthIcon
                                    sx={{
                                      pointerEvents: "none",
                                      height: "0.7em",
                                      width: "0.7em",
                                    }}
                                  />
                                )
                              }
                              onDelete={() => { }}
                            />
                          );
                        }
                        return null;
                      })}

                  </div>
                  <div className="d-flex justify-content-end col-4 mr-2">
                    <PillShapedButton onClick={() => { setPriceHistoryOpen(true) }} startIcon={<EqualizerIcon />}>Price History</PillShapedButton>
                  </div>
                </div>
              </div>
            </DialogTitle>
            <hr />
            <DialogContent sx={{ marginBottom: "1em", paddingTop: "0", height: '60vh', overflowY: 'scroll', overflowX: 'hidden' }}>
              <DialogContentText id="alert-dialog-description">
                <div>
                  <>
                    {modalData ? (
                      <>
                        <RoomDetails
                          tenantId={tenantId}
                          propertyId={selectedProperty}
                          ota={ota}
                          pms={pms}
                          date={modalDate}
                          modalData={modalData}
                          setClubedRoomDetails={setClubedRoomDetails}
                        />
                      </>
                    ) : null}
                  </>

                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    className="mb-3"
                    style={{ background: "#eaeaea" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{
                        minHeight: "100% !important",
                        height: "3em !important",
                      }}
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        <h6 className="bold">Focus Competition</h6>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ background: "white" }}>
                      {modalData && modalDate ? (
                        <>
                          <FocusComp
                            tenantId={tenantId}
                            propertyId={selectedProperty}
                            roomType={modalData.room_type_id}
                            mealPlan={modalData.meal_plan}
                            baseFare={modalData.base_fare}
                            ota={ota}
                            date={modalDate}
                          />
                        </>
                      ) : null}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    style={{ background: "#eaeaea" }}
                    className="mb-3"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                      sx={{
                        minHeight: "100% !important",
                        height: "3em !important",
                      }}
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        <h6 className="bold">Rate Parity</h6>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ background: "white" }}>
                      {modalDate ? (
                        <>
                          <RateParity
                            tenantId={tenantId}
                            propertyId={selectedProperty}
                            roomType={modalData.room_type_id}
                            mealPlan={modalData.meal_plan}
                            ota={ota}
                            date={modalDate}
                          />
                        </>
                      ) : null}
                    </AccordionDetails>
                  </Accordion>

                  {/* <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    style={{ background: "#eaeaea" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                      sx={{
                        minHeight: "100% !important",
                        height: "3em !important",
                      }}
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        <h6 className="bold">Price History</h6>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ background: "white" }}>
                      {modalDate ? (
                        <>
                          <PriceHistory
                            tenantId={tenantId}
                            propertyId={selectedProperty}
                            roomType={modalData.room_type_id}
                            mealPlan={modalData.meal_plan}
                            ota={ota}
                            date={modalDate}
                          />
                        </>
                      ) : null}
                    </AccordionDetails>
                  </Accordion> */}
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
      </Box>
      <PriceHistoryDialogue
        open={priceHistoryOpen}
        setOpen={setPriceHistoryOpen}
        tenantId={tenantId}
        propertyId={selectedProperty}
        roomType={modalData.room_type_id}
        mealPlan={modalData.meal_plan}
        ota={ota}
        date={modalDate}
      />
    </>
  );
};

export default Calendar;
