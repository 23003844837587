import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Flags = ({flagsModal, setFlagsModal, flagsSummary, flagsSummaryTable}) => {

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return(
		<Dialog
			fullWidth='md'
			maxWidth='md'
			open={flagsModal}
			onClose={() => setFlagsModal(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			>
			<DialogTitle id="alert-dialog-title">
			  <h5 className='mt-2 bold'>Flags Summary</h5>
			  <Box>
		        <Tabs  value={value} onChange={handleChange} aria-label="basic tabs example">
		          <Tab label="Card view" {...a11yProps(0)} />
		          <Tab label="Table view" {...a11yProps(1)} />
		        </Tabs>
		      </Box>
			</DialogTitle>
			<section className={value ? 'px-1' : 'px-5'} style={{backgroundColor: "#f6f8fa"}}>
			  <CustomTabPanel value={value} index={0}>
		        {
					Object.keys(flagsSummary).length ? Object.keys(flagsSummary).map(key => (
						<div className='row mt-3 mb-2 corner bg-white p-3' style={{border: '1.25px solid #e3e9f4'}}>
				  			<div className='col-12'>
				  				<div className='d-flex align-items-center row'>
				  					<div className='col-1 d-flex justify-content-center align-items-center'>
				  						<div className='flag-card-icon p-2'><i class="fa-solid fa-calendar"></i></div>
				  					</div>
				  					<div className='col-6'>
				  						<h6 className='bold'>Week #{key}</h6>
				  						<p className='bold small grey-2'>{flagsSummary[key]['from_date']} to {flagsSummary[key]['to_date']}</p>
				  					</div>
				  					
				  				</div>
				  			</div>
				  			<div className='mt-3 row'>
		  				<div className='col-12 d-flex align-items-center'>
		  				<div className='mx-2' style={{width: '18%'}}>
	  						<div className='flag p-2'>
	  							<div className='mx-4'><h4 className='bold mt-1'>{flagsSummary[key]['overpriced_count']}</h4></div>
	  							<div className='mt-3 d-flex align-items-center'>
	  								<i class="fa-solid fa-circle-up mr-2"></i>
	  								<p style={{marginBottom: '0'}} className='bold small-2 py-1 grey-2'>Overpriced</p>
	  							</div>
	  						</div>
	  					</div>
	  					<div className='mx-2' style={{width: '18%'}}>
	  						<div className='flag p-2'>
	  							<div className='mx-4'><h4 className='bold mt-1'>{flagsSummary[key]['underpriced_count']}</h4></div>
	  							<div className='mt-3 d-flex align-items-center'>
	  								<i class="fa-solid fa-circle-down mr-2"></i>
	  								<p style={{marginBottom: '0'}} className='bold small-2 py-1 grey-2'>Underpriced</p>
	  							</div>
	  						</div>
	  					</div>
	  					{/* <div style={{width: '18%'}}> */}
	  					{/* 	<div className='flag p-2'> */}
	  					{/* 		<div className='mx-4'><h4 className='bold mt-1'>{flagsSummary[key]['train_demand_spike']}</h4></div> */}
	  					{/* 		<div className='d-flex align-items-center'> */}
	  					{/* 			<i class="fa-solid fa-train-subway mr-2"></i> */}
	  					{/* 			<p style={{marginBottom: '0'}} className='bold small-2 grey-2'>Train demand spike</p> */}
	  					{/* 		</div> */}
	  					{/* 	</div> */}
	  					{/* </div> */}
	  					{/* <div style={{width: '18%'}}> */}
	  					{/* 	<div className='flag p-2'> */}
	  					{/* 		<div className='mx-4'><h4 className='bold mt-1'>{flagsSummary[key]['comp_price_increase']}</h4></div> */}
	  					{/* 		<div className='d-flex align-items-center'> */}
	  					{/* 			<i class="fa-solid fa-arrow-trend-up mr-2"></i> */}
	  					{/* 			<p style={{marginBottom: '0'}} className='bold small-2 grey-2'>Competition price increase</p> */}
	  					{/* 		</div> */}
	  					{/* 	</div> */}
	  					{/* </div> */}
	  					{/* <div style={{width: '18%'}}> */}
	  					{/* 	<div className='flag p-2'> */}
	  					{/* 		<div className='mx-4'><h4 className='bold mt-1'>{flagsSummary[key]['comp_price_decrease']}</h4></div> */}
	  					{/* 		<div className='d-flex align-items-center'> */}
	  					{/* 			<i class="fa-solid fa-arrow-trend-down mr-2"></i> */}
	  					{/* 			<p style={{marginBottom: '0'}} className='bold small-2 grey-2'>Competition price decrease</p> */}
	  					{/* 		</div> */}
	  					{/* 	</div> */}
	  					{/* </div> */}
	  					</div>
		  			</div>
				  		</div>

					))
					:
					null
				}
		      </CustomTabPanel>
		      <CustomTabPanel value={value} index={1}>

						<section className='p-2'>
		        <table class="table table-striped">
						  <thead className='thead-dark'>
						    <tr className='thead-dark'>
						      <th scope="col"></th>
						      {
					        	Object.keys(flagsSummary).length ? Object.keys(flagsSummary).map(item => (
					        		<th scope="col">W{item}</th>
					        	))
					        	:
					        	null
					        }
						      
						    </tr>
						  </thead>
						  <tbody>
					    	{
					    		flagsSummaryTable.map(item => (
					    			<tr>
					    				{
					    					item.map(val => (
					    						<td>{val}</td>
					    					))
					    				}
					    				
					    			</tr>
					    		))
					    	}
						  </tbody>
						</table>
						</section>		
		      </CustomTabPanel>
				
			</section>
			<DialogActions>
			  <Button onClick={() => setFlagsModal(false)}>Close</Button>
			</DialogActions>
			</Dialog>
	)
}

export default Flags;