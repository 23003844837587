export const validateEmail = (email) => {
	//Cannot be personal email id
	if(email.includes('gmail') || email.includes('yahoo') || email.includes('hotmail') || email.includes('icloud')){
		return false
	}
	// Regular expression for basic email validation
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	// Test the email against the regular expression
	return emailRegex.test(email);
}

export const validateName = (name) => {
	// Regular expression for basic name validation
	const nameRegex = /^[A-Za-z]+(?:[-\s][A-Za-z]+)*$/;
	// Test the name against the regular expression
	return nameRegex.test(name);
}


export function validatePassword(password) {
  // Minimum length check
  if (password.length < 8) {
    return false;
  }

  // At least one uppercase letter check
  if (!/[A-Z]/.test(password)) {
    return false;
  }

  // At least one special character check
  if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
    return false;
  }

  // All checks passed
  return true;
}