import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PermanentControls from "./PermanentControls";
import DOWControls from './DOWControls';
import DateRangeControls from './DateRangeControls';
import DateRangeDOWControls from "./DateRangeDOWControls";
import DOJControls from "./DOJControls";
import axios from "axios";
import { fetchToken } from "../../Auth";

const Controls = () => {
  const { propertyId } = useParams();
  const navigate = useNavigate();

  const [property, setProperty] = useState({});
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const [roomTypes, setRoomTypes] = useState([]);

  const getPropertyDetails = async () => {
    try {
      const response = await axios.get(
        `/fetch_property_details/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      if (response.status === 200) {
        setProperty(response.data);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (propertyId) getPropertyDetails();
  }, [propertyId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const columns = [
    { id: "name", label: "Room Type" },
    { id: "price_control_min_price", label: "Min. Price" },
    { id: "price_control_max_price", label: "Max. Price" },
    { id: "price_control_percent_lift", label: "% Lift" },
    { id: "check_in_min_los_inventory", label: "Inventory" },
    { id: "check_in_min_los_days", label: "Days" },
    { id: "check_in_max_los_inventory", label: "Inventory" },
    { id: "check_in_max_los_days", label: "Days" },
  ];

  function createData(a, b, c, d, e, f, g, h) {
    return {
      name: a,
      price_control_min_price: b,
      price_control_max_price: c,
      price_control_percent_lift: d,
      check_in_min_los_inventory: e,
      check_in_min_los_days: f,
      check_in_max_los_inventory: g,
      check_in_max_los_days: h,
    };
  }

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (Object.keys(property).length) {
      let temp = [];
      for (let item of property.room_types) {
        temp.push(
          createData(
            `${item["sciative_room_type_name"]} ${
              item["is_anchor"] ? "(Base Room)" : ""
            }`,
            <input min="0" className="custom-input w-100" type="number" />,
            <input min="0" className="custom-input w-100" type="number" />,
            <input min="0" className="custom-input w-100" type="number" />,
            <input min="0" className="custom-input w-100" type="number" />,
            <input min="0" className="custom-input w-100" type="number" />,
            <input min="0" className="custom-input w-100" type="number" />,
            <input min="0" className="custom-input w-100" type="number" />
          )
        );
      }
      setRows(temp);
    }
  }, [property]);

  return (
    <div className="admin-card p-1 m-2">
      <div className="container-fluid">
        <section className="my-4">
          {Object.keys(property).length ? (
            <div className="row">
              <div className="col-1 d-flex align-items-center justify-content-center">
                <IconButton onClick={() => navigate("/rooms/properties")}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className="col-2">
                <div className="d-flex flex-column">
                  <p className="mb-0 thin text-muted">SCIATIVE PROPERTY ID</p>
                  <p className="mb-0 large-2 bold text-orange">
                    {property.property_id}
                  </p>
                </div>
              </div>
              <div className="col-5">
                <div className="d-flex flex-column">
                  <p className="mb-0 thin text-muted">PROPERTY NAME</p>
                  <p className="mb-0 large-2 bold text-orange">
                    {property.property_name}
                  </p>
                </div>
              </div>
              <div className="col-2">
                <div className="d-flex flex-column">
                  <p className="mb-0 thin text-muted">TOTAL ROOMS</p>
                  <p className="mb-0 large-2 bold text-orange">
                    {property.rooms_count}
                  </p>
                </div>
              </div>
              <div className="col-2">
                <div className="d-flex flex-column">
                  <p className="mb-0 thin text-muted">LOCATION</p>
                  <p className="mb-0 large-2 bold text-orange">
                    {property.property_level_details.location_details.city}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </section>
        <Divider style={{ backgroundColor: "black", height: "1px" }} />

        <section className="mt-3 p-3">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0}>
              <Grid item xs={8}>
                <div className="p-3 bg-dark text-white">
                  <p className="bold medium-2 mb-0">Configuration Name</p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="p-3 bg-dark text-white">
                  <p className="bold medium-2 mb-0">Action</p>
                </div>
              </Grid>
            </Grid>
            <Grid className="border-bottom" container spacing={0}>
              <Grid item xs={8}>
                <div className="p-2">
                  <p className="medium-2 mb-0">Room Type Controls</p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="p-2">
                  <Button onClick={() => setOpen(true)}>
                    <EditIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid className="border-bottom" container spacing={0}>
              <Grid item xs={8}>
                <div className="p-2">
                  <p className="medium-2 mb-0">Meal Plan Controls</p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="p-2">
                  <Button>
                    <EditIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </section>
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle>
          <h5 className="bold">Room Type Controls</h5>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Permanent Controls" {...a11yProps(0)} />
              <Tab label="DOW Controls" {...a11yProps(1)} />
              <Tab label="Date Range Controls" {...a11yProps(2)} />
              <Tab label="Date Range-DoW" {...a11yProps(3)} />
              <Tab label="DOJ Controls" {...a11yProps(4)} />
            </Tabs>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <CustomTabPanel value={value} index={0}>
              <PermanentControls
                columns={columns}
                rows={rows}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                roomTypes={property.room_types}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <DOWControls
                columns={columns}
                rows={rows}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                roomTypes={property.room_types}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <DateRangeControls
                columns={columns}
                rows={rows}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                roomTypes={property.room_types}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <DateRangeDOWControls
                columns={columns}
                rows={rows}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                roomTypes={property.room_types}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <DOJControls
                columns={columns}
                rows={rows}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                roomTypes={property.room_types}
              />
            </CustomTabPanel>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Controls;
