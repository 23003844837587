import { useNavigate } from "react-router";
import axios from 'axios'
import {fetchToken, deleteToken} from '../Auth' 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Profile() {
  const navigate = useNavigate();

  const signOut = () => {
    // localStorage.removeItem("temitope");
    deleteToken()
    navigate("/login");
  };

  const testing = () => {
    return toast.success("Success Notification !", {
      position: toast.POSITION.TOP_CENTER
    });
  }

  return (
    <>
      <div style={{ marginTop: 20, minHeight: 700 }}>
        <h1>Profile page</h1>
        <p>Hello there, welcome to your profile page</p>

        <ToastContainer />
        <button onClick={() => testing()}>Click Me</button>
        <button onClick={signOut}>sign out</button>
      </div>
    </>
  );
}