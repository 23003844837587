import { useLocation, Navigate, useNavigate } from "react-router-dom"
import {setCookie, getCookie, deleteCookie} from "./services/auth"
import axios from 'axios';


export const setToken = (token)=>{
    setCookie('zetta_access_token', token, 1)
}

export const fetchToken = (token)=>{
    let auth = getCookie(token);
    return auth
}

export function RequireToken({children}){
    let auth = fetchToken('zetta_access_token')
    let location = useLocation()
    if(!auth){
        return <Navigate to='/login' state={{from : location}}/>;
    }
    return children;
}

export const deleteToken = () => {
    deleteCookie('zetta_access_token')
}

export const isValidRoute = async (id) => {
    try {
      const response = await axios.get(`/check_valid_route/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
          },
      });
      if(response.status == 200){
        return {'valid': true, 'redirect': ''}
      }
    } catch (err) {
      if(err.response !== undefined && err.response.status === 401){
        return {'valid': false, 'redirect': '/login'}; 
      }
      if(err.response !== undefined && err.response.status === 403){
        return {'valid': false, 'redirect': '/error'}; 
      }
    }
}