import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Table from '../../components/common/Table/Table';
import Grow from '@mui/material/Grow';
import axios from 'axios';
import {fetchToken} from '../../Auth'
import Button from '@mui/material/Button';

import ClientDashboards from './ClientDashboards';

const Client = () => {

	const [totalClients, setTotalClients] = useState('');
	const [activeClients, setActiveClients] = useState('');
	const [totalRegions, setTotalRegions] = useState('');
	const [activeRegions, setActiveRegions] = useState('');
	const [domestic, setDomestic] = useState(0);
	const [international , setInternational] = useState(0);
	const [tableData, setTableData] = useState([]);

	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	
	const [cols, setCols] = useState([
	    { field: 'id', headerName: 'Client name', width: '200', flex: 1},
	    { field: 'domain', headerName: 'Domain', width: '190', flex: 1},
	    { field: 'head_office', headerName: 'Head Office', width: '150', flex: 1},
	    { field: 'num_props', headerName: 'Properties', width: '100', flex: 1},
	    {
	      field: 'status',
	      headerName: 'Status',
	      disableColumnMenu: true,
	      sortable: false,
	      renderCell: (params) => (
	        params.row.status ? <div className='success-pill p-1'>Active</div> : <div className='warning-pill p-1'>Inactive</div>
	      ),
	      edit: '80', flex: 1
	    },
	    { field: 'createdAt', headerName: 'Created At',   width: '120', flex: 1, type: "dateTime",
	      renderCell: (params) => {
	        let date = '';
	        let time = '';
	        if(params.row.createdAt){
	          date = `${params.row.createdAt.getDate()} ${months[params.row.createdAt.getMonth()]} ${params.row.createdAt.getFullYear()}` 
	          time = `${params.row.createdAt.getHours()}:${params.row.createdAt.getMinutes().toString().padStart(2, '0')}`
	        }
	        return(
	          <div>
	            <p className='mb-0'>{date}</p>
	            <p className='small grey mb-0'>{time}</p>
	          </div>
	        )
	      }
	    }
	]);

	const navigate = useNavigate();

	const getData = async () => {
		try {
	      const response = await axios.get("/get_client_dashboard_data", {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	      });
	      if(response.status == 200 && response.data !== undefined){
	        setTotalClients(response.data.data.total_clients)
	        setActiveClients(response.data.data.active_clients)
	        setTotalRegions(response.data.data.total_regions)
	        setActiveRegions(response.data.data.active_regions)
	        setDomestic(response.data.data.domestic_clients)
	        setInternational(response.data.data.international_clients)
	        let temp = []
	        setTableData(response.data.clients.map(item => ({
	        	'id': item[1],
	        	'domain': item[2],
	        	'head_office': item[3],
	        	'num_props': item[4],
	        	'status': item[5],
	        	'createdAt': new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4'))
	        })))
	      }
	    } catch (error) {
	      console.error("Error", error);
	    }
	}

	useEffect(() => {
		getData()
	}, [])

	return(
		<>
		<section className='p-3'>
			{/* <div><Link to="/client/setup-client">Setup Client</Link></div>  */}
			{/* <div><Link to="/client/client-dashboards">Client Dashboards</Link></div>  */}
			<div className='w-100 row'>
				<div className='col-9'>
					{/* <div className='row'> */}
					{/* 	<div className='col-6'> */}
					{/* 		<div className='dashboard-card bg-blue p-4'> */}
					{/* 			<div className='w-100 d-flex align-items-center px-1'> */}
					{/* 				<div className='dashboard-card-icon'> */}
					{/* 					<i class="fa-solid fa-hotel"></i> */}
					{/* 				</div> */}
					{/* 				<h4 className='mx-3 font-weight-bold dashboard-card-title text-black'>Clients</h4> */}
					{/* 			</div> */}
					{/* 			<div className='mt-3 d-flex justify-content-between align-items-center'> */}
					{/* 				<div className='w-50 d-flex flex-column border-right'> */}
					{/* 					<p className='dashboard-card-text'>Total Clients</p> */}
					{/* 					<h2 className='dashboard-number text-black'>{totalClients}</h2> */}
					{/* 				</div> */}
					{/* 				<div className='w-50 px-3 d-flex flex-column'> */}
					{/* 					<p className='dashboard-card-text'>Active Clients</p> */}
					{/* 					<h2 className='dashboard-number text-black'>{activeClients}</h2> */}
					{/* 				</div> */}
					{/* 			</div> */}
					{/* 		</div> */}
					{/* 	</div> */}
					{/* 	<div className='col-6'> */}
					{/* 		<div className='dashboard-card bg-blue p-4'> */}
					{/* 			<div className='w-100 d-flex align-items-center px-1'> */}
					{/* 				<div className='dashboard-card-icon'> */}
					{/* 					<i class="fa-solid fa-location-dot"></i> */}
					{/* 				</div> */}
					{/* 				<h4 className='mx-3 font-weight-bold dashboard-card-title text-black'>Regions</h4> */}
					{/* 			</div> */}
					{/* 			<div className='mt-3 d-flex justify-content-between align-items-center'> */}
					{/* 				<div className='w-50 border-right d-flex flex-column'> */}
					{/* 					<p className='dashboard-card-text'>Total Regions</p> */}
					{/* 					<h2 className='dashboard-number text-black'>{totalRegions}</h2> */}
					{/* 				</div> */}
					{/* 				<div className='w-50 px-3 d-flex flex-column'> */}
					{/* 					<p className='dashboard-card-text'>Active Regions</p> */}
					{/* 					<h2 className='dashboard-number text-black'>{activeRegions}</h2> */}
					{/* 				</div> */}
					{/* 			</div> */}
					{/* 		</div> */}
					{/* 	</div> */}
					{/* </div> */}
				</div>
			</div>

			<div className="mt-1 w-100 row">
				<Grow
			      in={true}
			      {...(true ? { timeout: 700 } : {})}
			    >
				<div className='p-4 white-card'>
					<div className="mt-3">
						<div className='bg-grey p-1 py-3 corner w-100 row'>
							<div className='col-5'>
								<h3 class='dashboard-card-title'>Clients Summary</h3>
								<p className='mt-1 text-muted'><span className='bold'>{tableData.length}</span> total clients</p>
							</div>
							<div className='col-7 d-flex justify-content-between align-items-center'>
								<div className='border-right w-30 d-flex flex-column justify-content-center align-items-center'>
									<h4 className='bold'>{domestic}</h4>
									<p className='text-muted'>Domestic</p>
								</div>
								<div className='w-30 d-flex flex-column justify-content-center align-items-center'>
									<h4 className='bold'>{international}</h4>
									<p className='text-muted'>International</p>
								</div>
								<Button onClick={() => navigate('/client/setup-client')} variant='contained'>Onboard new client</Button>
							</div>
						</div>

						<div className='w-100 mt-3'>
							<Table disableSelect={true} cols={cols} rows={tableData} />
						</div>
					</div>
				</div>
				</Grow>
			</div>


			
		</section>

		<section>
			<div className='mt-3'>
				<ClientDashboards />
			</div>
		</section>

		</>
	)
}

export default Client;