import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grow from '@mui/material/Grow';
import Switch from '@mui/material/Switch';
import Rating from '@mui/material/Rating';
import PublicIcon from '@mui/icons-material/Public';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchToken } from '../../Auth';

const ManageHotels = () => {

    const [ota, setOTA] = useState('');
    const [otas, setOTAs] = useState([]);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState('');
    const [properties, setProperties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState('');

    const [hotelData, setHotelData] = useState(null);
    const [scrapedData, setScrapedData] = useState(null);

    const [anchorRoom, setAnchorRoom] = useState('');
    const [latestRoomData, setLatestRoomData] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState('');
    const [newPackage, setNewPackage] = useState('');

    const [fcDetails, setFCDetails] = useState(null);
    const [focusCompRoomData, setFocusCompRoomData] = useState(null);
    const [selectedFC, setSelectedFC] = useState('');
    const [selectedFCRoom, setSelectedFCRoom] = useState('');
    const [newFCPackage, setNewFCPackage] = useState('');

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const [loading, setLoading] = useState(false);

    const otaNames = {
        'emt': 'EaseMyTrip',
        'agoda': 'Agoda',
        'booking': 'Booking.com',
        'mmt': 'MakeMyTrip'
    }

    const navigate = useNavigate();

    const changeOTA = (val) => {
        setOTA(val)
        // setHotelData(null)
        // setScrapedData(null)
        // setAnchorRoom('')
        // setLatestRoomData(null)
    }

    const getAllOTAs = async () => {
        try {
            const response = await axios.get('/get_client_otas', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setOTAs(response.data);
                setOTA(response.data[0]);
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const getAllCities = async (ota) => {
        try {
            const response = await axios.get(`/get_client_cities/${ota}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                let c = Object.keys(response.data)[0]
                setCity(c)
                setCities(Object.keys(response.data))
                setProperties(response.data[c]['properties'])
                setSelectedProperty(response.data[c]['properties'][0]['property_id'])
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const getLatestRoomData = async (ota, hid) => {
        try {
            const response = await axios.get(`/get_room_types/${ota}/${hid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setLatestRoomData(response.data)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const getFocusCompRoomData = async (ota, ids) => {
        try{
            const response = await axios.get(`/get_room_types_multiple/${ota}/${ids}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setFocusCompRoomData(response.data)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const getHotelData = async () => {
        try {
            const response = await axios.get(`/get_client_hotel/${city}/${selectedProperty}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setHotelData(response.data)
                setAnchorRoom((response.data['room_types'].find(room => room['is_anchor'] === true))['room_type_name'])
                getScrapedHotelData(ota, response.data['property_id'])
                getLatestRoomData(ota, response.data['property_id'])
                getFocusCompRoomData(ota, Object.keys(response.data['focus_comp'][ota === 'emt' ? 'EMT' : ota]).join(','))

                getFCData(ota, city, response.data['property_id'])
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const getFCData = async (ota, city, id) => {
        try{
            const response = await axios.get(`/get_fc_details/${city}/${ota==='emt'?'EMT':ota}/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setFCDetails(response.data)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const getScrapedHotelData = async (ota, hid) => {
        try {
            const response = await axios.get(`/get_scraped_hotel/${ota}/${hid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setScrapedData(response.data)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const handleModalOpen = (newRoomType) => {
        setSelectedRoom(newRoomType)
        setOpen(true)
    }

    const handleModal2Open = (fc, newRoomType) => {
        setSelectedFC(fc)
        setSelectedFCRoom(newRoomType)
        setOpen2(true)
    }

    const handleChangeAnchorRoom = async () => {
        setLoading(true)
        try {
            const response = await axios.post('/change_anchor_room', {
                'new_anchor_room': selectedRoom,
                'new_package_id': newPackage,
                'old_anchor_room': anchorRoom,
                'hotel_id': selectedProperty,
                'city': city
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setLoading(false)
                setAnchorRoom(selectedRoom);
                setSelectedRoom('');
                setNewPackage('');
                toast.success('Anchor room updated successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.SUCCESS
                });
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const handleChangeFCAnchorRoom = async () => {
        setOpen2(false)
        setLoading(true)
        try{
            const response = await axios.post('/change_fc_anchor_room', {
                'ota': ota==='emt'?'EMT':ota,
                'city': city,
                'self_hotel_id': selectedProperty,
                'hotel_id': selectedFC,
                'new_anchor_room': selectedFCRoom,
                'new_package_id': newFCPackage,
                'old_anchor_room': hotelData['focus_comp'][ota==='emt'?'EMT':ota][selectedFC]['set_anchor_room_type']['type_name'],
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setLoading(false)
                setHotelData(prevState => {
                    let newState = prevState;
                    for(let key in newState['focus_comp'][ota==='emt'?'EMT':ota]){
                        if(key === selectedFC){
                            newState['focus_comp'][ota==='emt'?'EMT':ota][key]['set_anchor_room_type']['type_name'] = selectedFCRoom
                            newState['focus_comp'][ota==='emt'?'EMT':ota][key]['set_anchor_room_type']['package_id'] = newFCPackage
                        }
                    }

                    return newState;
                })
                setSelectedFC('');
                setSelectedFCRoom('');
                setNewFCPackage('');
                toast.success('FC Anchor room updated successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.SUCCESS
                });
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        getAllOTAs()
    }, []);


    useEffect(() => {
        if (ota) getAllCities(ota);
    }, [ota]);

    useEffect(() => {
        if (selectedProperty) {
            getHotelData()
        }
    }, [selectedProperty])

    return (
        <>
            <Grow
                in={true}
                {...(true ? { timeout: 500 } : {})}
            >
                <div className='px-4 py-2 mt-3 white-card'>
                    <div className="mt-3">
                        <div className='bg-grey p-1 py-2 corner w-100 row'>
                            <div className='col-6'>
                                <h3 className='dashboard-card-title'>Manage Hotels</h3>
                                <p className='grey-2 small mt-1'>Manage room types, anchor rooms and anchor packages across all OTAs</p>
                            </div>
                            <div className='col-2 d-flex justify-content-between align-items-center'>
                                <PublicIcon style={{ fontSize: '1.8em' }} className='grey-2' />
                                <select value={ota} onChange={(evt) => changeOTA(evt.target.value)} className="mx-2 form-select" aria-label="Default select example">
                                    {
                                        otas.length ? otas.map(item => <option value={item}>{otaNames[item]}</option>) : null
                                    }
                                </select>
                            </div>
                            <div className='col-2 d-flex justify-content-between align-items-center'>
                                <LocationOnIcon style={{ fontSize: '1.8em' }} className='grey-2' />
                                <select value={city} onChange={(evt) => setCity(evt.target.value)} className="mx-2 form-select" aria-label="city select">
                                    {
                                        cities.map(city => <option value={city}>{city}</option>)
                                    }
                                </select>
                            </div>
                            <div className='col-2 d-flex justify-content-between align-items-center'>
                                <BusinessIcon style={{ fontSize: '1.8em' }} className='grey-2' />
                                <select value={selectedProperty} onChange={(evt) => setSelectedProperty(evt.target.value)} className="mx-2 form-select" aria-label="hotel select">
                                    {
                                        properties.map(property => <option value={property['property_id']}>{property['property_name']}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </Grow>

            <Grow
                in={true}
                {...(true ? { timeout: 700 } : {})}
            >
                <div className='w-100 row'>
                    <div className='col-12'>
                        <div className='px-4 py-2 mt-3 white-card'>
                            {
                                hotelData && scrapedData ?
                                    <div className='d-flex flex-column justify-content-end align-items-center w-100 hotel-listing-card-4 mx-1 ' style={{ backgroundImage: `url(${scrapedData['image_url']})` }}>
                                        <div className='w-100 px-2 hotel-listing-card-gradient-4 d-flex flex-column justify-content-end'>
                                            <Rating
                                                value={hotelData['rating']}
                                                readOnly
                                            />
                                            <h3 className='w-100 text-white bold'>{hotelData['property_name']}</h3>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </Grow>

            <Grow
                in={true}
                {...(true ? { timeout: 700 } : {})}
            >
                <div className='w-100 row'>
                    {
                        hotelData && scrapedData && latestRoomData ?
                            <>
                                <div className='col-8'>
                                    <div className='px-2 py-3 mt-3 white-card'>
                                        <h5 className='mx-2 bold'>Currently set Room Types</h5>
                                        <div className='px-3 py-1 mt-3'>
                                            {
                                                hotelData['room_types'].sort((a, b) => a.priority - b.priority).map(item => (
                                                    <div className='my-2 w-100 row'>
                                                        <div className='col-1'><i class="text-muted medium fa-solid fa-bars"></i></div>
                                                        <div className='col-1'><span className='bold text-orange'>#{item['priority']}</span></div>
                                                        <div className='col-5'>{item['room_type_name'].replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</div>
                                                        <div className='col-3 d-flex justify-content-end align-items-center'>
                                                            <p className='text-muted small-2 mr-2 mb-0'>Anchor</p> <Switch onClick={() => handleModalOpen(item['room_type_name'])} checked={item['room_type_name'] === anchorRoom} />
                                                        </div>
                                                        <div className='col-1 d-flex align-items-center justify-content-center'>
                                                            {
                                                                item['room_type_name'] in latestRoomData['room_details'] ?
                                                                    <Tooltip className='pointer' placement="right" title="Room Type present in the latest room level scrape">
                                                                        <i className="text-success medium-2 fa-solid fa-circle-check"></i>
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip className='pointer' placement="right" title="Room Type not found in the latest room level scrape!">
                                                                        <i className="text-danger medium-2 fa-solid fa-circle-exclamation"></i>
                                                                    </Tooltip>
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4'>
                          
                                </div>
                            </>
                            :
                            null
                    }
                </div>
            </Grow>

            <Grow
                in={true}
                {...(true ? { timeout: 900 } : {})}
            >
                <div className='w-100 row'>
                    {
                        fcDetails && hotelData && focusCompRoomData ?
                        Object.keys(focusCompRoomData).map(comp => (
                            <div className='col-lg-12'>
                                <div className='px-2 py-3 mt-3 white-card'>
                                    <h5 className='mx-2 bold'>{fcDetails[comp]['hotel_name']}</h5>
                                    <div className='px-3 py-1 mt-3'>
                                        {
                                            focusCompRoomData[comp].map(roomType => (
                                                <div className='my-2 w-100 row'>
                                                    <div className='col-1'><i class="text-muted medium fa-solid fa-bars"></i></div>
                                                    <div className='col-6'><span className=''>{roomType['room_type'].replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</span></div>
                                                    <div className='col-3 d-flex justify-content-end align-items-center'>
                                                        <p className='text-muted small-2 mr-2 mb-0'>Anchor</p> <Switch onClick={() => handleModal2Open(comp, roomType['room_type'])} checked={hotelData['focus_comp'][ota==='emt'?'EMT':ota][comp]['set_anchor_room_type']['type_name']===roomType['room_type']} />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        null
                    }
                </div>
            </Grow>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                fullWidth={true}
            >
                    
                <section className='p-3'>
                    <h5 className='bold'>Choose an Anchor package for this Anchor room</h5>
                    <div className='mt-3 w-100 p-2 d-flex flex-wrap'>
                        {
                            latestRoomData && selectedRoom in latestRoomData['room_details'] ?
                            Object.keys(latestRoomData['room_details'][selectedRoom]).map((key, idx) => (
                                <div className='w-40 bordered corner-2 p-2 my-1 mx-2'>
                                    <div className='w-100 d-flex justify-content-between align-items-center'>
                                        <p className='bold text-orange'>Package #{idx+1}</p>
                                        <Checkbox checked={key === newPackage} onChange={() => setNewPackage(key)}/>
                                    </div>
                                    {latestRoomData['room_details'][selectedRoom][key]['room_identifier'].filter(amen => amen !== '').map(amen => (
                                        <div className='my-2 w-100 d-flex align-items-center'>
                                            <i className="fa-regular fa-circle-check mr-2"></i>
                                            <p className='mb-0 small text-muted'>{amen}</p>
                                        </div>
                                    ))}
                                </div>
                            ))
                            :
                            'This room type is not present in the latest fetched data'
                        }
                    </div>
                </section>
                <DialogActions>
                    {loading ? <p className='small text-muted mb-0'>This might take a few seconds...</p> : null}
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button style={{width: '8em', maxWidth: '8em'}} onClick={() => handleChangeAnchorRoom()} disabled={newPackage === ""} variant="contained" autoFocus>
                        {
                            loading ?
                            <CircularProgress className='small' style={{height: '20px', width: '20px', color: 'white'}} />
                            :
                            "Continue"
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open2}
                onClose={() => setOpen2(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                fullWidth={true}
            >
                    
                <section className='p-3'>
                    <h5 className='bold'>Choose an Anchor package for this FC Anchor room</h5>
                    <div className='mt-3 w-100 p-2 d-flex flex-wrap'>
                        {
                            focusCompRoomData && selectedFC && selectedFCRoom && selectedFC in focusCompRoomData ?
                            focusCompRoomData[selectedFC].find(roomType => roomType['room_type'] === selectedFCRoom) ?
                            Object.keys((focusCompRoomData[selectedFC].find(roomType => roomType['room_type'] === selectedFCRoom))['hashes']).map((hash, idx) => (
                                <div className='w-40 bordered corner-2 p-2 my-1 mx-2'>
                                    <div className='w-100 d-flex justify-content-between align-items-center'>
                                        <p className='bold text-orange'>Package #{idx+1}</p>
                                        <Checkbox checked={hash===newFCPackage} onChange={() => setNewFCPackage(hash)}/>
                                    </div>
                                    {
                                        (focusCompRoomData[selectedFC].find(roomType => roomType['room_type'] === selectedFCRoom))['hashes'][hash]['room_identifier'].filter(item => item !== "").map(item => (
                                            <div className='my-2 w-100 d-flex align-items-center'>
                                                <i className="fa-regular fa-circle-check mr-2"></i>
                                                <p className='mb-0 small text-muted'>{item}</p>
                                            </div>
                                        ))   
                                    }
                                </div>
                            ))
                            :
                            null
                            :
                            null
                        }
                    </div>
                </section>
                <DialogActions>
                    <Button onClick={() => setOpen2(false)}>Cancel</Button>
                    <Button style={{width: '8em', maxWidth: '8em'}} onClick={() => handleChangeFCAnchorRoom()} disabled={newFCPackage === ""} variant="contained" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            
            <Dialog
                open={loading}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='sm'
                fullWidth={true}
            >
                <selection className='px-3 py-4 w-100 d-flex flex-column justify-content-center align-items-center'>
                    
                    <h2 className='bold mt-3'>Updating changes...</h2>
                    <h6 className='medium mt-1'>Please hang on. This may take a few seconds.</h6>
                </selection>
            </Dialog>
        </>
    )
}

export default ManageHotels;