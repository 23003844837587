import { useState, useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '../../components/common/Table/Table';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Grow from '@mui/material/Grow';
import moment from 'moment';
import { toast } from 'react-toastify';
import {fetchToken} from '../../Auth';

import auth from '../../assets/img/auth.png';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Dashboards = ({dashboards, setDashboards}) => {

  const [dashboardName, setDashboardName] = useState("");
  const [dashboardURL, setDashboardURL] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [editName, setEditName] = useState("");
  const [editURL, setEditURL] = useState("");
  const [editNameValid, setEditNameValid] = useState(true);
  const [editURLValid, setEditURLValid] = useState(true);

  const [selectedForDelete, setSelectedForDelete] = useState('');
  const [selectedForEdit, setSelectedForEdit] = useState('');

  const [createNotif, setCreateNotif] = useState(false);
  const [deleteNotif, setDeleteNotif] = useState(false);
  const [editNotif, setEditNotif] = useState(false);

  const [openPassword, setOpenPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [mode, setMode] = useState('');

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const [dashboardCols, setDashboardCols] = useState([
    { field: 'id', headerName: 'ID', width: '60', headerClassName: 'bg-grey-3', flex: 1},
    { field: 'name', headerName: 'Name', minWidth: 140, headerClassName: 'bg-grey-3', flex: 1},
    { field: 'url', headerName: 'URL', width: '130', headerClassName: 'bg-grey-3', flex: 1},
    { field: 'createdBy', headerName: 'Created By', width: '120', headerClassName: 'bg-grey-3 bold', flex: 1},
    { field: 'createdAt', headerName: 'Created At',   width: '130', headerClassName: 'bg-grey-3 bold', flex: 1, type: "dateTime",
      renderCell: (params) => {
        let date = '';
        let time = '';
        if(params.row.createdAt){
          date = `${params.row.createdAt.getDate()} ${months[params.row.createdAt.getMonth()]} ${params.row.createdAt.getFullYear()}` 
          time = `${params.row.createdAt.getHours()}:${params.row.createdAt.getMinutes().toString().padStart(2, '0')}`
        }
        return(
          <div>
            <p className='mb-0'>{date}</p>
            <p className='small grey mb-0'>{time}</p>
          </div>
        )
      }
    },
    { field: 'updatedAt', headerName: 'Updated At',   width: '130', headerClassName: 'bg-grey-3 bold', flex: 1, type: "dateTime",
      renderCell: (params) => {
        let date = '';
        let time = '';
        if(params.row.updatedAt){
          date = `${params.row.updatedAt.getDate()} ${months[params.row.updatedAt.getMonth()]} ${params.row.updatedAt.getFullYear()}` 
          time = `${params.row.updatedAt.getHours()}:${params.row.updatedAt.getMinutes().toString().padStart(2, '0')}`
        }
        return(
          <div>
            <p className='mb-0'>{date}</p>
            <p className='small grey mb-0'>{time}</p>
          </div>
        )
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Switch onClick={async () =>{
          setMode({'mode': 'dashboardStatus', 'id': params.row.id, 'status': params.row.status, 'func': updateDashboardStatus})
          handleClickPasswordOpen()
          } } checked={params.row.status ? true : false} />
      ),
      width: '40',
      headerClassName: 'bg-grey-3', flex: 1
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton size="small" aria-label="delete">
          <EditIcon onClick={async () => {
            setMode({'mode': 'dashboardEdit', 'func': editDashboard })
            setEditName(params.row.name)
            setEditURL(params.row.url)
            setSelectedForEdit(params.row.id)
            handleClickOpenEdit()
          } } fontSize="inherit"/>
        </IconButton>
      ),
      width: '40',
      headerClassName: 'bg-grey-3', flex: 1
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton size="small" aria-label="delete">
          <DeleteIcon onClick={() => {
            setMode({'mode': 'dashboardDelete', 'func': deleteDashboard })
            setSelectedForDelete(params.row.id)
            handleClickOpenDelete()
          } } fontSize="inherit"/>
        </IconButton>
      ),
      width: '40',
      headerClassName: 'bg-grey-3', flex: 1
    }
  ]);


  const navigate = useNavigate();

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseNotif = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setCreateNotif(false);
    setDeleteNotif(false);
    setEditNotif(false);
  };

  const handleClickPasswordOpen = () => {
    handleCloseDelete()
    handleCloseEdit()
    setOpenPassword(true);
  };

  const handlePasswordClose = () => {
    if(mode['mode'] === 'dashboardStatus'){
      let func = mode['func']
      func(mode['id'], mode['status'], password)
    }
    else if(mode['mode'] === 'dashboardEdit'){
      let func = mode['func']
      editDashboard(password)
    }
    else if(mode['mode'] === 'dashboardDelete'){
      let func = mode['func']
      deleteDashboard(password)
    }
    setMode(null)
    setOpenPassword(false);
  };


  const updateDashboardStatus = async (dashboardId, currStatus, password) => {   
    let makeStatus;
    if(currStatus){
      makeStatus = 0;
    }else{
      makeStatus = 1;
    }
    try {
      const response = await axios.post("/update_dashboard_status", {dashboardId, makeStatus, password}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
          },
      });
      if(response.status == 200){
        setPassword('')
        let temp = response.data.map(item => ({'id': item[0], 'name': item[1], 'url': item[2], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
        setDashboards(temp)
        toast.success('Dashboard status updated!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.SUCCESS
        });
      }
    } catch (error) {
      console.error("Error", error);
      setPassword('')
      if(error.response !== undefined && error.response.status === 401){
        let temp = error.response.data.data.map(item => ({'id': item[0], 'name': item[1], 'url': item[2], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
        setDashboards((preVal) => {
          return temp;
        })
        toast.error('Invalid credentials!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
      }
    }
  }

  const addDashboard = async () => {
    if(!dashboardName){
      toast.error('Dashboard name cannot be blank!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.ERROR
      });
      return;
    }
    if(/^\d+$/.test(dashboardName)){
      toast.error('Dashboard name cannot contain only numerical characters!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.ERROR
      });
      return;
    }
    if(!/^[a-zA-Z\s]+$/.test(dashboardName)){
      toast.error('Dashboard name should contain only alphabetical characters!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.ERROR
      });
      return;
    }
    if(!dashboardURL){
      toast.error('Dashboard URL cannot be blank!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
      return;
    }
    if(dashboardURL[0] !== '/' || dashboardURL === '/'){
      toast.error('Dashboard URL must be a valid one!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
      return;
    }
    try {
      const response = await axios.post("/create_dashboard", {dashboardName, dashboardURL}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
          },
      });
      if(response.status == 200 && response.data !== undefined){
        setDashboardName("");
        setDashboardURL("");
        setDashboards([...dashboards, {id: response.data[0], name: response.data[1], url: response.data[2], createdBy: response.data[4], createdAt: new Date(response.data[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), updatedAt: response.data[6] ? new Date(response.data[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', status: response.data[3]}])
        setCreateNotif(true)
      }
    } catch (error) {
      console.error("Error", error);
    }
  }


  const editDashboard = async (password) => {
    if(!editNameValid){
      return
    }
    if(!editURLValid){
      return
    }
    if(!editURL){
      return
    }
    try {
      const response = await axios.post("/edit_dashboard", {'dashboardId': selectedForEdit, "name": editName, "url": editURL, "password": password}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
          },
      });
      if(response.status == 200 && response.data !== undefined){
        let temp = response.data.map(item => ({'id': item[0], 'name': item[1], 'url': item[2], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
        setDashboards(temp)
        setSelectedForEdit("");
        setEditName("");
        setEditURL("");
        setPassword('');
        toast.success('Dashboard details updated!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.SUCCESS
        });
      }
    } catch (error) {
      console.error("Error", error);
      setPassword('')
      if(error.response !== undefined && error.response.status === 401){
        let temp = error.response.data.data.map(item => ({'id': item[0], 'name': item[1], 'url': item[2], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
        setDashboards((preVal) => {
          return temp;
        })
        toast.error('Invalid credentials!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
      }
    } 
  }

  const deleteDashboard = async (password) => {
    try {
      const response = await axios.post("/delete_dashboard", {'dashboardId': selectedForDelete, 'password': password}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
          },
      });
      if(response.status == 200 && response.data !== undefined){
        let temp = response.data.map(item => ({'id': item[0], 'name': item[1], 'url': item[2], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
        setDashboards(temp)
        handleCloseDelete()
        setSelectedForDelete('');
        setDeleteNotif(true)
      }
    } catch (error) {
      console.error("Error", error);
      setPassword('')
      if(error.response !== undefined && error.response.status === 401){
        let temp = error.response.data.data.map(item => ({'id': item[0], 'name': item[1], 'url': item[2], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
        setDashboards((preVal) => {
          return temp;
        })
        toast.error('Invalid credentials!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
      }
    }
  }

  useEffect(() => {
    if(!editName){
      setEditNameValid(false)
    }else{
      setEditNameValid(true)
    }
  }, [editName])

  useEffect(() => {
    if(!editURL || editURL[0] !== '/'){
      setEditURLValid(false)
    }else{
      setEditURLValid(true)      
    }
  })

  return(
  	<section className="p-3">
		<div className="row">
			<div className="col-12">
      <Grow
        in={true}
        {...(true ? { timeout: 500 } : {})}
      >
				<div className="admin-card bg-white p-4">
					<h6 className="admin-card-header">Manage Dashboards</h6>

					<div class="admin-card-section p-4 mt-4 d-flex justify-content-between align-items-center">
						<input value={dashboardName} onChange={(e) => setDashboardName(e.target.value)} type="text" className="mx-2 form-control" id="dashboard-name" placeholder="Dashboard name"/>
						<input value={dashboardURL} onChange={(e) => setDashboardURL(e.target.value)} type="text" className="mx-2 form-control" id="dashboard-url" placeholder="Dashboard URL"/>
						<button onClick={addDashboard} class="mx-2 admin-card-button"><i className='bx bx-plus'></i> Add</button>
					</div>

					<div className="row mt-3">
						<div className="col-12">
							<Table disableSelect={true} cols={dashboardCols} rows={dashboards} />
            {/* <DataGrid {...data} /> */}
						</div>
					</div>
				</div>
      </Grow>
			</div>
		</div>

      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit dashboard"}
        </DialogTitle>
        <DialogContent className="py-4">
            <div className="w-100 row">
              <div className="col-12">
                <TextField 
                  error={!editNameValid}
                  helperText={!editNameValid ? "Invalid dashboard name" : ""}  
                  className="w-100 mb-5" 
                  label="Dashboard Name" 
                  defaultValue={editName} 
                  onChange={(evt) => setEditName(evt.target.value) } 
                  size="small" 
                  variant="outlined" />
              </div>
              <div className="col-12">
                <TextField error={!editURLValid} helperText={!editURLValid ? "Invalid URL" : ""} className="w-100" label="Dashboard URL" defaultValue={editURL} onChange={(evt) => setEditURL(evt.target.value) } size="small" variant="outlined" />
              </div>
            </div>  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit}>Cancel</Button>
          <Button variant="contained" onClick={() => handleClickPasswordOpen() } autoFocus>
            <i class="fa-solid fa-circle-check mx-2"></i> Save
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this dashboard?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            All functionalities associated with this dashboard will also be deleted.
            Clients and User roles to whom this dashboard is assigned, will no longer be able to see this dashboard.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button variant="contained" onClick={() => handleClickPasswordOpen() } autoFocus>
            <i class="fa-solid fa-trash mx-2"></i> Delete
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog
        open={openPassword}
        onClose={handlePasswordClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
            <div className='w-100 row d-flex justify-content-between align-items-center'>
              <div className='col-5 d-flex justify-content-center align-items-center'>
                <img style={{height: '10em'}} src={auth}/>
              </div>
              <div className='col-7'>
                <h5 className='bold mt-3'>Authentication required</h5>
                <p className='text-muted small mt-1'>Authentication is required in order to perform this action. Please enter your credentials below.</p>
                <input type='password' className='w-100 mt-2 form-control' placeholder='Enter your password' onChange={(evt) => setPassword(evt.target.value) }/>
              </div>
            </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handlePasswordClose} autoFocus>
            Verify
          </Button>
        </DialogActions>
      </Dialog>



      <Snackbar open={createNotif} autoHideDuration={4000} onClose={handleCloseNotif} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={handleCloseNotif} severity="success" sx={{ width: '100%' }}>
          New dashboard created!
        </Alert>
      </Snackbar>

      <Snackbar open={deleteNotif} autoHideDuration={4000} onClose={handleCloseNotif} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={handleCloseNotif} severity="success" sx={{ width: '100%' }}>
          Dashboard deleted!
        </Alert>
      </Snackbar>
	</section>
  )

};

export default Dashboards;