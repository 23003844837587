var cities = [
  "Agra", "Ahmedabad", "Ajmer", "Akola", "Aligarh", "Allahabad", "Amravati", "Amritsar",
  "Aurangabad", "Bangalore", "Bareilly", "Belgaum", "Bhilai", "Bhopal", "Bhubaneswar", "Bikaner", "Chandigarh",
  "Chennai", "Cuttack", "Davanagere", "Dehradun", "Dhanbad", "Faridabad", "Firozabad", "Gandhinagar", "Gaya",
  "Ghaziabad", "Gorakhpur", "Gurgaon", "Guwahati", "Hyderabad", "Indore", "Jabalpur", "Jaipur",
  "Jalandhar", "Jamnagar", "Jamshedpur", "Jhansi", "Jodhpur", "Kanpur", "Kolkata",
  "Kolhapur", "Kota", "Kochi", "Lucknow", "Ludhiana", "Madurai", "Mangalore", "Meerut",
  "Mumbai", "Mysore", "Nagpur", "Nanded", "Nashik", "Navi Mumbai", "New Delhi", "Nellore", "Noida", "Patna",
  "Pune", "Raipur", "Rajkot", "Ranchi", "Saharanpur", "Siliguri",
  "Srinagar", "Tiruchirapalli", "Thane", "Udaipur", "Ujjain", "Varanasi", "Vijayawada", "Visakhapatnam", "Warangal"
];

export default cities;
