import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { fetchToken } from "../../../Auth";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts"
import '../../../assets/styles/zetta-charts.css';
import { Switch, TextField, styled, InputAdornment, IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ClearIcon from '@mui/icons-material/Clear';

// Styled Switch component
const StyledSwitch = styled(Switch)(({ theme }) => ({
    width: 46,
    height: 26,
    padding: 5,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(4px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(16px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 24,
        height: 24,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M18.6 6.62c-1.44 0-2.8.56-3.77 1.53L12 10.66 10.48 12h.01L7.8 14.39c-.64.64-1.49.99-2.4.99-1.87 0-3.39-1.51-3.39-3.38S3.53 8.62 5.4 8.62c.91 0 1.76.35 2.44 1.03l1.13 1 1.51-1.34L9.22 8.2C8.2 7.18 6.84 6.62 5.4 6.62 2.42 6.62 0 9.04 0 12s2.42 5.38 5.4 5.38c1.44 0 2.8-.56 3.77-1.53l2.83-2.5.01.01L13.52 12h-.01l2.69-2.39c.64-.64 1.49-.99 2.4-.99 1.87 0 3.39 1.51 3.39 3.38s-1.52 3.38-3.39 3.38c-.9 0-1.76-.35-2.44-1.03l-1.14-1.01-1.51 1.34 1.27 1.12c1.02 1.01 2.37 1.57 3.82 1.57 2.98 0 5.4-2.41 5.4-5.38s-2.42-5.37-5.4-5.37z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 13,
    },
}));

const PriceHistory = ({
    tenantId,
    propertyId,
    roomType,
    mealPlan,
    ota,
    date,
}) => {
    const navigate = useNavigate();
    const [fcData, setFcData] = useState([]);
    const [seriesData, setSeriesData] = useState([]);
    const [legends, setLegends] = useState([]);
    const [useSeriesData, setUseSeriesData] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const getPriceHistory = async (
        tenantId,
        propertyId,
        roomType,
        mealPlan,
        ota,
        date
    ) => {
        try {
            const response = await axios.get(
                `/fetch_price_history/${ota}/${tenantId}/${propertyId}/${date}/${roomType}/${mealPlan}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
                    },
                }
            );
            if (response.status === 200) {
                setFcData(response.data.fc_data);
                setSeriesData(response.data.series_data);
                setLegends(response.data.legends);
            }
        } catch (error) {
            console.error("Error Fetching Price History!", error);
            if (error.response && error.response.status === 401) {
                navigate("/login");
            }
        }
    };

    useEffect(() => {
        if (tenantId && propertyId && roomType && mealPlan && ota && date) {
            getPriceHistory(tenantId, propertyId, roomType, mealPlan, ota, date);
        }
    }, [tenantId, propertyId, roomType, mealPlan, ota, date]);

    const filteredData = (useSeriesData ? seriesData : fcData).filter((item, index) => {
        if (index === 0) return true;
        if (!searchTerm) return true;

        if (item.name && typeof item.name === 'string') {
            return item.name.toLowerCase().includes(searchTerm.toLowerCase());
        }

        return Object.values(item).some(
            value => typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
    const getUniqueDates = (data) => {
        const uniqueDates = new Set();
        data.forEach(series => {
            series.data.forEach(point => {
                uniqueDates.add(new Date(point[0]).setHours(0, 0, 0, 0));
            });
        });
        return Array.from(uniqueDates).sort((a, b) => a - b);
    };


    const options = {
        chart: {
            type: 'line',
            zoomType: 'x',
            resetZoomButton: {
                position: {
                    align: 'left',
                    verticalAlign: 'bottom',
                    x: 10,
                    y: -50
                },
                relativeTo: 'chart'
            }
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: "Refresh Date",
            },
            labels: {
                format: '{value:%d-%m-%Y}'
            },
            tickPositioner: function () {
                const uniqueDates = getUniqueDates(filteredData);
                return uniqueDates.map(date => date);
            }
        },
        yAxis: {
            title: {
                text: 'Price (₹)'
            },
            labels: {
                format: '₹{value}'
            }
        },
        tooltip: {
            shared: true,
            crosshairs: true,
            formatter: function () {
                const date = new Date(this.x);
                const day = ('0' + date.getDate()).slice(-2);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();
                const hours = ('0' + date.getHours()).slice(-2);
                const minutes = ('0' + date.getMinutes()).slice(-2);

                const formattedDate = `${day}-${month}-${year}`;
                const formattedTime = `${hours}:${minutes}`;

                let s = `<b>Date: ${formattedDate}, ${formattedTime}</b><br/>`;
                this.points.forEach(function (point) {
                    if (point.y !== null) {
                        s += `<span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>₹${point.y}</b><br/>`;
                    }
                });
                return s;
            }
        },
        legend: {
            enabled: true
        },
        series: filteredData.map((s, index) => ({
            name: s.name || legends[index] || `Series ${index + 1}`,
            data: s.data.map(point => [new Date(point[0]).getTime(), point[1]])
        })),

    };

    return (
        <div style={{ width: "100%" }} id="price-history-chart-container">
            <div className="d-flex justify-content-between align-items-center mt-3" style={{ marginBottom: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <FilterListIcon style={{ marginRight: '10px' }} /> */}
                    <span style={{ marginRight: '10px' }}>Focus Competition</span>
                    <StyledSwitch
                        checked={useSeriesData}
                        onChange={() => setUseSeriesData(!useSeriesData)}
                    />
                    <span style={{ marginLeft: '10px' }}>All</span>

                    {/* <AllInclusiveIcon style={{ marginLeft: '10px' }} /> */}
                </div>
                <TextField
                    label="Search Hotels"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchTerm && (
                                    <IconButton
                                        aria-label="clear search"
                                        onClick={() => setSearchTerm("")}
                                        edge="end"
                                        size="small"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                        style: {
                            borderRadius: '20px',
                            backgroundColor: '#f5f5f5',
                        }
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'primary.main',
                            },
                        },
                    }}
                />
            </div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    );
};

export default PriceHistory;