import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Link, Redirect, useNavigate } from "react-router-dom";
import axios from "axios";
import {setCookie} from '../services/auth.js';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import '../assets/styles/base.css';
import '../assets/styles/login.css';
import logo from '../assets/img/sciative_logo.png'


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const SignUp = () => {

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showPassword, setShowPassword] = React.useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

	const [open, setOpen] = React.useState(false);

	const navigate = useNavigate();

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		setOpen(false);
	};

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
	event.preventDefault();
	};

	const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

	const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
	event.preventDefault();
	};


	const signup = async () => {
		if(password !== confirmPassword){
			alert('Passwords do not match!');
			return;
		}

	    try {
	      const response = await axios.post("/signup", {
	        username: email,
	        password: password,
	        name: name,
	      }, {
	      	headers: {
            	'Content-Type': 'application/json',
          	}
          }
          );
          if(response.status == 200 && response.data !== undefined){
          	setCookie('zetta_access_token', response.data.access_token, 1);
          	navigate("/home");
          }
	      // navigate("/home");
	    } catch (err) {
	      console.error("Login failed", err);
	      if(err.response !== undefined && err.response.status === 409){
	      	setOpen(true)
	      }
	    }
	};

	return(

		<div className="row w-100 h-100">
			<div className="col-sm-12 col-md-6 login-img-section d-flex flex-column justify-content-center align-items-center">
				<img src={logo} className="login-logo" />

				<p className="mt-4 text-white login-presents"><i>presents</i></p>
				<h2 className="heading-2 text-white mt-4">PROJECT ZETTA</h2>
			</div>

			<div className="login-form-section col-sm-12 col-md-6 d-flex justify-content-center align-items-center flex-column">
				<h1 className="greeting">CREATE AN ACCOUNT</h1>
				<div className="orange-line"></div>

				<p class="mt-4 text-muted text-small">Already have an account? <a href='/login/'>Login</a> here</p>

				<div className="w-100 mt-3 d-flex flex-column justify-content-center align-items-center">
					<FormControl sx={{ m: 1, width: '75%' }} variant="standard">
		            	<TextField id="full-name" label="Full Name" variant="standard" onChange={(evt) => setName(evt.target.value) } />
		            </FormControl>
	          	</div>

	          	<div className="w-100 mt-3 d-flex flex-column justify-content-center align-items-center">
					<FormControl sx={{ m: 1, width: '75%' }} variant="standard">
		            	<TextField id="email" label="Email ID" variant="standard" onChange={(evt) => setEmail(evt.target.value) } />
		            </FormControl>
	          	</div>


	          	<div class="w-100 mt-3 d-flex flex-column justify-content-center align-items-center">
		          	<FormControl sx={{ m: 1, width: '75%' }} variant="standard">
			          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
			          <Input
			            id="password"
			            type={showPassword ? 'text' : 'password'}
			            onChange={(evt) => setPassword(evt.target.value) }
			            endAdornment={
			              <InputAdornment position="end">
			                <IconButton
			                  aria-label="toggle password visibility"
			                  onClick={handleClickShowPassword}
			                  onMouseDown={handleMouseDownPassword}
			                >
			                  {showPassword ? <VisibilityOff /> : <Visibility />}
			                </IconButton>
			              </InputAdornment>
			            }
			          />
			        </FormControl>
				</div>

				<div class="w-100 mt-3 d-flex flex-column justify-content-center align-items-center">
		          	<FormControl sx={{ m: 1, width: '75%' }} variant="standard">
			          <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
			          <Input
			            id="confirm-password"
			            type={showConfirmPassword ? 'text' : 'password'}
			            onChange={(evt) => setConfirmPassword(evt.target.value) }
			            endAdornment={
			              <InputAdornment position="end">
			                <IconButton
			                  aria-label="toggle password visibility"
			                  onClick={handleClickShowConfirmPassword}
			                  onMouseDown={handleMouseDownConfirmPassword}
			                >
			                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
			                </IconButton>
			              </InputAdornment>
			            }
			          />
			        </FormControl>
				</div>

				<button class="btn-orange mt-4" onClick={signup}>Sign Up</button>

			</div>


			<Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
		        <Alert onClose={handleClose} severity="error" sx={{ width: '25%' }}>
		          This username already exists!
		        </Alert>
		    </Snackbar>
		</div>
	)
}

export default SignUp;