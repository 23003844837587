import { useState, useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PublicIcon from '@mui/icons-material/Public';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import { LineChart } from '@mui/x-charts/LineChart';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Grow from '@mui/material/Grow';
import axios from 'axios';
import {fetchToken} from '../../Auth' 
import { toast } from 'react-toastify';

import Chart from './components/Chart';
import RecommendedPrices from './components/RecommendedPrices';
import TravelDemand from './components/TravelDemand';
import Flags from './components/Flags';

const CompetitivePricing = () => {

	const [loading, setLoading] = useState(true);

	const [name, setName] = useState("");
	const [dates, setDates] = useState([]);
	const [dateRange, setDateRange] = useState([]);
	const [sites, setSites] = useState([]);
	const [site, setSite] = useState('emt');
	const [week, setWeek] = useState(0);
	const [cities, setCities] = useState([])
	const [selectedCity, setSelectedCity] = useState('');
	const [properties, setProperties] = useState([]);
	const [selectedProperty, setSelectedProperty] = useState('');
	const [hotelMappings, setHotelMappings] = useState({});
	const [selectedHotelId, setSelectedHotelId] = useState('');

	const [selfData, setSelfData] = useState([]);
	const [focusComp, setFocusComp] = useState({});
	const [chartData, setChartData] = useState([]);
	const [data, setData] = useState(null);
	const [flags, setFlags] = useState(null);

	const [flagsModal, setFlagsModal] = useState(false);
	const [flagsSummary, setFlagsSummary] = useState(null);
	const [flagsSummaryTable, setFlagsSummaryTable] = useState([]);

	const [trainData, setTrainData] = useState([]);

	const [recPrices, setRecPrices] = useState(null);
	const [roomTypes, setRoomTypes] = useState([]);
	const [anchorRoomData, setAnchorRoomData] = useState(null);

	const [errors, setErrors] = useState(null);

	const stationCodes = {
		'Mumbai': "CSMT",
		'Navi Mumbai': "CSMT",
		'Chennai': "MAS",
		'Goa': "MAO"
	}

	const handleWeekChange = (event, value) => {
		setWeek(value-1);
	};

	//Modals
	const [priceModal, setPriceModal] = useState(false);

	const navigate = useNavigate();

	const getAllOTAs = async () => {
		try {
	        const response = await axios.get('/get_client_otas', {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
	        	setSites(response.data);
				setSite(response.data[0]);
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response && err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}
	const getAllCities = async (ota) => {
		try {
	        const response = await axios.get(`/get_client_cities/${ota}`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
	        	let c = Object.keys(response.data)[0]
	        	setSelectedCity(c)
	        	setCities(Object.keys(response.data))
				console.log(response.data[c]['properties'])
	        	setProperties(response.data[c]['properties'])
	        	setSelectedProperty(response.data[c]['properties'][0]['property_id'])
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response && err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	const getProperties = async () => {
		try {
	        const response = await axios.get(`/get_client_hotels/${selectedCity}`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
	        	setProperties(response.data)
				setSelectedProperty('')
	        	// setSelectedProperty(response.data[0]['property_id'])
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response && err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	const getTrainData = async () => {
		try{
			const response = await axios.get(`/get_train_data/${stationCodes[selectedCity]}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if(response.status == 200 && response.data !== undefined){
				setTrainData(response.data)
			}
		} catch (err) {
			console.error("Error", err);
			if(err.response.status === 401){
			  navigate('/login')
			}
		}
	}

	const getChartData = async () => {
		try {
	        const response = await axios.get(`/get_comp_data_v2/${site}/${selectedProperty}/${selectedCity}`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined && response.data !== null){
	        	let daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
				setHotelMappings(response.data.client_hotel_mapping)
				setSelectedHotelId(response.data.client_hotel_mapping[`${site}_id`])
	        	setDates(response.data.dates.map(item => ({'key': item, 'date': `${(new Date(item)).getUTCDate().toString().padStart(2, '0')} ${(new Date(item)).toLocaleString('default', { month: 'short' })}`, 'day': daysOfWeek[(new Date(item)).getDay()]})))
	        	setSelfData(response.data.self_data)
				setFocusComp(response.data.focus_comp)
	        	setChartData(response.data.comp_data)
	        	setData(response.data.data)
	        	setRecPrices(response.data.rec_prices)
	        	setRoomTypes(response.data.room_types)
				setAnchorRoomData(response.data.anchor_room_data)
	        	setFlags(response.data.flags)
	        	setErrors(response.data.errors)
				setWeek(0)
	        	setLoading(false)
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	const getFlags = () => {
	    setFlagsModal(true);
	}

	useEffect(() => {
		getAllOTAs();
	}, []);

	useEffect(() => {
		if(site) getAllCities(site);
	}, [site]);

	useEffect(() => {
		if(dates.length){
			setDateRange(dates.slice(week*7, (week*7)+6+1))
		}
	}, [dates, week])

	useEffect(() => {
		const fetchData = async () => {
			if(selectedCity && selectedProperty && site){
				// Fetch data concurrently
				await Promise.all([
					getChartData(),
					getTrainData()
				]);
			}
		};
	
		fetchData();
	}, [selectedProperty]);

	useEffect(() => {
		if(selectedCity && cities.length){
			getProperties();
		}
	}, [selectedCity]);

	

	return(
		
		<>
		<section className='admin-card py-3 px-4 mt-4 mb-3 w-100'>
			<div className='row'>
				<div className='col-5'>
					<h2 className='bold'>Competition Prices</h2>
					<h6 className='grey-2 text-muted'>{(new Date()).toDateString()}</h6>
				</div>
				<div className='col-7 d-flex flex-row align-items-center justify-content-evenly'>
					<div className='w-100 row'>
						<div className='col-3'>
							<FormControl className='d-flex flex-row align-items-center'>
								<PublicIcon style={{fontSize: '1.8em'}} className='grey-2' />
				        		<Select
				        			className='w-100 mx-1'
				        			style={{borderRadius: '200px', border: "1.8px solid #eee, padding: '0.5em', color: '#eee'"}}
				        			size='small'
				        			value={site}
					          		displayEmpty
					          		onChange={(evt) => setSite(evt.target.value)}
						        >
				          			<MenuItem value='emt'>EaseMyTrip</MenuItem>
					        	</Select>
					        </FormControl>
						</div>
						<div className='col-3'>
							<FormControl className='d-flex flex-row align-items-center' >
					        	<LocationOnIcon style={{fontSize: '1.8em'}} className='grey-2' />
					        	{
					        		cities.length ?
					        		<Select
						        		style={{borderRadius: '200px', border: "1.8px solid #eee, padding: '0.5em', color: '#eee'"}}
						        	    className='w-100 mx-1'
					        			size='small'
					        			value={selectedCity}
					        			onChange={(evt) => setSelectedCity(evt.target.value)}
						          		displayEmpty
							        >	{
							        		cities.map(city => <MenuItem value={city}>{city}</MenuItem>)
							        	}
						        	</Select>
						        	:
						        	null
					        	}
				      		</FormControl>
						</div>
						<div className='col-3'>
							<FormControl className='d-flex flex-row align-items-center'>
				      			<BusinessIcon style={{fontSize: '1.8em'}} className='grey-2' />
				      			{
									properties && properties.length > 0 && 
					        		<Select
						        		style={{borderRadius: '200px', border: "1.8px solid #eee, padding: '0.5em', color: '#eee'"}}
						        	    className='w-100 mx-1'
					        			size='small'
					        			value={selectedProperty}
					        			onChange={(evt) => setSelectedProperty(evt.target.value)}
						          		
							        >	
										{
							        		properties.filter(property => 'property_id' in property && 'property_name' in property).map(property => <MenuItem value={property['property_id']}>{property['property_name']}</MenuItem>)	
							        	}
						        	</Select>
								}
				      		</FormControl>
						</div>
						{/* <div className='col-3 d-flex justify-content-end align-items-center'>
							<Button size='small' variant='outlined'>Submit</Button>
						</div> */}
					</div>
				</div>
			</div>


			<div className='mt-3 row d-flex p-1'>
				<Chart 
					loading={loading}
					ota={site}
					city={selectedCity}
					selectedHotelId={selectedHotelId}
					dates={dateRange}
					site={site} 
					cities={cities} 
					selfData={selfData}
					trainData={trainData}
					focusComp={focusComp}
					details={data}
					chartData={chartData} 
					setChartData={setChartData}
					flags={flags} 
					roomTypes={roomTypes}
					anchorRoomData={anchorRoomData}
					setPriceModal={setPriceModal}
					week={week}
					errors={errors}
				/>

			</div>

			{
				flagsModal ? <Flags flagsModal={flagsModal} setFlagsModal={setFlagsModal} flagsSummary={flagsSummary} flagsSummaryTable={flagsSummaryTable} /> : null
			}

		</section>

		<section className='admin-card py-3 px-4 mt-4 mb-3 w-100'>
			<div className='row d-flex'>
				<RecommendedPrices 
					loading={loading}
					dates={dateRange}
					selfData={selfData}
					site={site} 
					cities={cities} 
					week={week}
					recPrices={recPrices}
					roomTypes={roomTypes}
					errors={errors}
				/>
			</div>
		</section>
		
		{
			!loading ?
			<section className='w-100 comp-pagination py-3 px-4 mt-4 mb-3'>
				<div className='col-12 d-flex justify-content-end align-items-center'>
					<Stack spacing={2}>
						<Pagination count={Math.floor(dates.length/7)} page={week+1} onChange={handleWeekChange} />
					</Stack>
				</div>
			</section>
			:
			null
		}

		</>
	)
};

export default CompetitivePricing;