import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import MenuItem from './MenuItem';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';
import {fetchToken, deleteToken} from '../../../Auth' 

import '../../../assets/styles/sidebar.css';
import logo from '../../../assets/img/zetta_logo.png';

const Sidebar = ({ user, setUser, setStaff, setAssignedClients, setSelectedClientId }) => {

    const [dashboards, setDashboards] = useState([]);
    const [active, setActive] = useState('');
    const [open, setOpen] = useState(false);

    const icons = {
        1: "fa-solid fa-user-tie",
        2: "fa-solid fa-coins",
        3: "fa-solid fa-lightbulb",
        4: "fa-solid fa-trophy",
        5: "fa-solid fa-gears",
        6: "fa-solid fa-users",
        11: "bx bxs-buildings",
        15: "fa-solid fa-door-closed",
        27: "fa-solid fa-gear",
        32: "fa-solid fa-hourglass-start",
        33: "fa-solid fa-code-fork",
        34: "fa-solid fa-hotel",
        35: "fa-solid fa-coins",
        36: "fa-solid fa-magnifying-glass-chart",
        'home': "fa-solid fa-house",
        'default': "bx bxs-grid-alt"
    }

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if(dashboards.length){
            if(location.pathname === '/home'){
                setActive(0)
            }else{
                for(let dash of dashboards){
                    let item = (dash[2].split('/'))[1]
                    if(location.pathname.includes(item)){
                        setActive(parseInt(dash[0]))
                        break;
                    }
                }
            }
        }
    }, [dashboards])

    const getAssignedDashboards = async () => {
        try{
            const response = await axios.get("/get_assigned_dashboards", {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if(response.status == 200 && response.data !== undefined){
                setDashboards(response.data)
            }
        } catch (err) {
          console.error("Error", err);
          if(err.response.status === 401){
            navigate('/login')
          }
        }
    }

    const getUser = async () => {
        try{
            const response = await axios.get("/get_current_user", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if(response.status == 200 && response.data !== undefined){
                setUser(response.data)
                setStaff(response.data.is_staff)
                setAssignedClients(response.data.assigned_clients)
                setSelectedClientId(response.data.client_id)
            }
        } catch (err) {
          console.error("Error", err);
          if(err.response.status === 401){
            navigate('/login')
          }
        }
    }

    const signOut = () => {
        // localStorage.removeItem("temitope");
        deleteToken()
        navigate("/login");
    };

    useEffect(() => {
        let sidebar = document.querySelector(".sidebar");
        let closeBtn = document.querySelector("#btn");
        closeBtn.addEventListener("click", ()=>{
          sidebar.classList.toggle("open");
          menuBtnChange();//calling the function(optional)
        });

        // following are the code to change sidebar button(optional)
        function menuBtnChange() {
             if(sidebar.classList.contains("open")){
               closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");//replacing the iocns class
             }else {
               closeBtn.classList.replace("bx-menu-alt-right","bx-menu");//replacing the iocns class
             }
        }

        getAssignedDashboards()
        getUser()
    }, []);

    return(
        <>
            <div className={open ? 'sidebar open' : 'sidebar'}>
                <div className="logo-details">
                    {/* <img className='sidebar-logo' src={logo} /> */}
                    <div className="logo_name">Zetta.ai</div>
                    <i className='bx bx-menu' id="btn" ></i>
                </div>
                <ul className="nav-list">
                    <MenuItem active={active === 0} link='/home' menuTitle={'Home'} icon={icons['home']} />
                    {
                        dashboards.map(d => <MenuItem active={d[0] === active} link={d[2]} menuTitle={d[1]} icon={d[0] in icons ? icons[d[0]] : icons['default']} />)
                    }
                    
                    {/* <li className="profile">
                        <div className="profile-details">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1024px-Default_pfp.svg.png" alt="profile"/>
                            <div className="name_job">
                                <div className="name">{user.first_name} {user.last_name}</div>
                                <div className="email text-white">{user.username}</div>
                            </div>
                        </div> */}
                        {/* <i className='bx bx-log-out' id="log_out" ></i> */}
                        {/* <IconButton onClick={() => signOut()} id="log_out" aria-label="delete" size="large">
                          <LogoutIcon className="text-white" />
                        </IconButton> */}
                    {/* </li> */}
                </ul>
            </div>
        </>
    );
}

export default Sidebar