// AppContext.js
import React, { createContext, useState, useContext } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [currUser, setCurrUser] = useState({});
  const [count, setCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [config, setConfig] = useState({});

  const increment = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const decrement = () => {
    setCount((prevCount) => prevCount - 1);
  };

  const contextValue = {
    currUser,
    setCurrUser,
    count,
    setCount,
    increment,
    decrement,
    users,
    setUsers,
    config,
    setConfig
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

export { AppProvider, useAppContext };
