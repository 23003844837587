import { useState, useEffect, Component } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import Alert from '@mui/material/Alert';
import Grow from '@mui/material/Grow';
import { MotionAnimate } from 'react-motion-animate';
import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import { fetchToken } from '../Auth';
import { validateEmail, validateName, validatePassword } from '../services/validators';
import { ToastContainer, toast } from 'react-toastify';
import { useAnimate, stagger, motion } from "framer-motion";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import Lottie from 'react-lottie'

import FCMap from './Settings/components/Map'
import cities from '../services/cities.js';

import '../assets/styles/onboarding.css';
import 'react-toastify/dist/ReactToastify.css';

import onboarding from '../assets/img/onboarding.png';
import hotel from '../assets/img/hotel.png';
import emt from '../assets/img/emt_logo.png';
import mmt from '../assets/img/mmt_logo.svg';
import agoda from '../assets/img/agoda_logo.png';
import booking from '../assets/img/booking_logo.png';
import loadingGIF from '../assets/gif/onboarding-loading'

const useStyles = makeStyles((theme) => ({
	stepper: {
		// Adjust the height as needed
		maxHeight: '100px', // Example height
	},
}));

const Intro = () => {
	return (
		<div className='w-100 d-flex flex-column justify-content-center align-items-center'>
			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5'>
				<img className='intro-img' src={onboarding} />
			</MotionAnimate>

			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.5'>
				<h1 className='bold'>Welcome to <span className='text-orange'>Zetta.ai</span></h1>
			</MotionAnimate>

			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.85'>
				<h6 className='grey-2'>Let's get you onboarded quickly!</h6>
			</MotionAnimate>
		</div>
	)
}

const BasicDetails = ({ firstName, setFirstName, lastName, setLastName, email, setEmail, password, setPassword, confirmPassword, setConfirmPassword, clientName, setClientName }) => {
	const [firstNameValid, setFirstNameValid] = useState(true);
	const [lastNameValid, setLastNameValid] = useState(true);
	const [emailValid, setEmailValid] = useState(true);
	const [passwordValid, setPasswordValid] = useState(true);
	const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);

	useEffect(() => {
		if (firstName && !validateName(firstName)) {
			setFirstNameValid(false)
		} else {
			setFirstNameValid(true)
		}
	}, [firstName])

	useEffect(() => {
		if (lastName && !validateName(lastName)) {
			setLastNameValid(false)
		} else {
			setLastNameValid(true)
		}
	}, [lastName])

	useEffect(() => {
		if (email && !validateEmail(email)) {
			setEmailValid(false)
		} else {
			setEmailValid(true)
		}
	}, [email])

	useEffect(() => {
		if (password && !validatePassword(password)) {
			setPasswordValid(false)
		} else {
			setPasswordValid(true)
		}
	}, [password])

	useEffect(() => {
		if (confirmPassword && password !== confirmPassword) {
			setConfirmPasswordValid(false)
		} else {
			setConfirmPasswordValid(true)
		}
	}, [confirmPassword])

	return (
		<div className='w-100 d-flex flex-column justify-content-center align-items-center'>
			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5'>
				<h1 className='bold'>Basic Details</h1>
			</MotionAnimate>

			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.5'>
				<h6 className='grey-2'>Create an account quickly to sign in and start using dashboards</h6>
			</MotionAnimate>

			<div className='w-100 mt-4 d-flex flex-column align-items-center justify-content-center'>

				<div className='mt-3 w-80 row'>
					<div className='col-6'>
						<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.85'>
							<TextField value={firstName} error={!firstNameValid ? true : false} style={{ background: '#fff' }} size="small" onChange={(evt) => setFirstName(evt.target.value)} className="w-100" id="first-name" label="First Name" variant="outlined" />
						</MotionAnimate>
						{!firstNameValid ? <small className='mx-2 text-danger'>Invalid first name</small> : null}
					</div>

					<div className='col-6'>
						<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.85'>
							<TextField value={lastName} error={!lastNameValid ? true : false} style={{ background: '#fff' }} size="small" onChange={(evt) => setLastName(evt.target.value)} className="w-100" id="last-name" label="Last Name" variant="outlined" />
						</MotionAnimate>
						{!lastNameValid ? <small className='mx-2 text-danger'>Invalid last name</small> : null}
					</div>
				</div>
				<div className='mt-4 w-80 row'>
					<div className='col-12'>
						<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='1.2'>
							<TextField type='text' value={clientName} style={{ background: '#fff' }} size="small" onChange={(evt) => setClientName(evt.target.value)} className="w-100" id="client-name" label="Company Name" variant="outlined" />
						</MotionAnimate>
					</div>
				</div>
				<div className='mt-4 w-80 row'>
					<div className='col-12'>
						<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='1.2'>
							<TextField type='email' value={email} error={!emailValid ? true : false} style={{ background: '#fff' }} size="small" onChange={(evt) => setEmail(evt.target.value)} className="w-100" id="email" label="Email address" variant="outlined" />
						</MotionAnimate>
						{!emailValid ? <small className='mx-2 text-danger'>Invalid email address</small> : null}
					</div>
				</div>
				<div className='mt-4 w-80 row'>
					<div className='col-6'>
						<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='1.55'>
							<TextField type='password' value={password} error={!passwordValid ? true : false} style={{ background: '#fff' }} size="small" onChange={(evt) => setPassword(evt.target.value)} className="w-100" id="password" label="Password" variant="outlined" />
						</MotionAnimate>
						{!passwordValid ? <small className='mx-2 text-danger'>Password should contain minimum 8 characters, one uppercase letter and one special character</small> : null}
					</div>
					<div className='col-6'>
						<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='1.55'>
							<TextField type='password' value={confirmPassword} error={!confirmPasswordValid ? true : false} style={{ background: '#fff' }} size="small" onChange={(evt) => setConfirmPassword(evt.target.value)} className="w-100" id="confirm-password" label="Confirm Password" variant="outlined" />
						</MotionAnimate>
						{!confirmPasswordValid ? <small className='mx-2 text-danger'>Passwords do not match!</small> : null}
					</div>
				</div>
			</div>
		</div>
	)
}

const ClientDetails = ({ clientCity, setClientCity }) => {
	return (
		<div className='w-100 d-flex flex-column justify-content-center align-items-center'>
			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5'>
				<h1 className='bold'>Your Organisation</h1>
			</MotionAnimate>
			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.5'>
				<h6 className='grey-2'>Setup your organisation in our system.</h6>
			</MotionAnimate>

			<div className='w-100 mt-4 d-flex flex-column align-items-center justify-content-center'>
				<div className='mt-1 w-80 row'>
					<div className='col-12'>
						<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.85'>
							<h6 className='small bold'>Organisation Name</h6>
							<input type="text" class="form-control" placeholder="" aria-label="orgname" />
						</MotionAnimate>
					</div>
				</div>
				<div className='mt-3 w-80 row'>
					<div className='col-12'>
						<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='1.2'>
							<h6 className='small bold'>Website</h6>
							<div class="input-group mb-3">
								<span class="input-group-text" id="basic-addon3">www.</span>
								<input type="text" class="form-control" id="basic-url" aria-describedby="website" />
							</div>
						</MotionAnimate>
					</div>
				</div>
				<div className='mt-3 w-80 row'>
					<div className='col-12'>
						<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='1.55'>
							<h6 className='small bold'>Choose a city where your hotel is located</h6>
							<Select value={clientCity} onChange={opt => {
								setClientCity(opt)
							}} options={cities.map(item => ({ 'label': item, 'value': item }))} className="w-100" />
						</MotionAnimate>
					</div>
				</div>
			</div>
		</div>
	)
}

const Hotel = ({ ota, selectedHotel, setSelectedHotel, hotelName, setHotelName }) => {

	const [hotels, setHotels] = useState([]);
	const [show, setShow] = useState(false);
	const [scope, animate] = useAnimate();

	const staggerList = stagger(0.1, { startDelay: 0.25 });

	useEffect(() => {
		if (show && hotels.length && scope.current) {
			animate(
				".hotel-listing-card",
				{ opacity: 0, scale: 0.3, y: -50 },
				{
					duration: 0.2,
					delay: staggerList
				}
			);
		}
	}, [show, hotels]);

	const getHotels = async () => {
		try {
			const response = await axios.post(`/search_hotels/${ota}`, { hotel_name: hotelName });
			if (response.status == 200 && response.data !== undefined) {
				setHotels(response.data)
				setShow(true);
			}
		} catch (err) {
			console.error("Error", err);
		}
	}


	return (
		<div className='w-100 d-flex flex-column justify-content-center align-items-center'>
			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5'>
				<h1 className='bold'>Your Hotel</h1>
			</MotionAnimate>
			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.5'>
				<h6 className='grey-2'>Search for your hotel</h6>
			</MotionAnimate>

			<div className='w-80 row mt-4'>
				<div className='col-10'>
					<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.85'>
						<input className='form-control' placeholder='Search your hotel name...' onChange={(evt) => setHotelName(evt.target.value)} value={hotelName} />
					</MotionAnimate>
				</div>
				<div className='col-2'>
					<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.85'>
						<button className='search-btn' onClick={getHotels}><i class="fa-solid fa-magnifying-glass"></i></button>
					</MotionAnimate>
				</div>
			</div>


			{
				show ?
					<div className='w-80 mt-4 row'>
						<div className='col-12 d-flex justify-content-center align-items-center'>
							<h5 className='grey'>{hotels.length} results found</h5>
						</div>
						{
							hotels.map((hotel, idx) => (
								<motion.div key={idx} className='col-4'>
									<div onClick={() => setSelectedHotel(hotel['hid'])} style={{ backgroundImage: `url(${hotel['img_url']})` }} className='d-flex flex-column justify-content-between align-items-center w-100 hotel-listing-card p-0 my-3'>
										<div className='w-100 d-flex justify-content-end align-items-center'>
											<Checkbox style={{ color: '#FF8B00', backgroundColor: "#fff" }} size='small' className='m-1' checked={selectedHotel === hotel['hid']} />
										</div>
										<div className='w-100 px-2 py-2 hotel-listing-card-gradient'>
											<Rating
												value={hotel['star']}
												readOnly
												size='small'
											/>
											<h6 className='w-100 text-white bold'>{hotel['hotel_name']}</h6>
										</div>
									</div>
								</motion.div>
							))
						}
					</div>
					:
					null
			}
		</div>
	)
}

const OTA = ({ ota, setOTA }) => {
	return (
		<div className='w-100 d-flex flex-column justify-content-center align-items-center'>
			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5'>
				<h1 className='bold'>Preferred OTA</h1>
			</MotionAnimate>
			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.5'>
				<h6 className='grey-2'>Select an OTA from which your hotel recieves maximum bookings</h6>
			</MotionAnimate>

			<div className='w-100 mt-4 d-flex flex-wrap align-items-center justify-content-around'>
				<div className="checkbox-wrapper-16">
					<label className="mx-3 checkbox-wrapper">
						<input checked={ota === 'emt'} onChange={() => setOTA('emt')} value='external' type="checkbox" className="checkbox-input" />
						<span className="checkbox-tile">
							<span className="checkbox-icon">
								<img src={emt} className='ota-logo' />
							</span>
						</span>
					</label>
				</div>

				{/* <div className="checkbox-wrapper-16">
					<label className="mx-3 checkbox-wrapper">
						<input value='external' type="checkbox" className="checkbox-input" />
						<span className="checkbox-tile">
						  <span className="checkbox-icon">
						    <img src={mmt} className='ota-logo'/>
						  </span>
						</span>
					</label>
				</div> */}

				{/* <div className="checkbox-wrapper-16">
					<label className="mx-3 checkbox-wrapper">
						<input value='external' type="checkbox" className="checkbox-input" />
						<span className="checkbox-tile">
						  <span className="checkbox-icon">
						    <img src={agoda} className='ota-logo'/>
						  </span>
						</span>
					</label>
				</div> */}

				{/* <div className="checkbox-wrapper-16">
					<label className="mx-3 checkbox-wrapper">
						<input value='external' type="checkbox" className="checkbox-input" />
						<span className="checkbox-tile">
						  <span className="checkbox-icon">
						    <img src={booking} className='ota-logo' style={{height: '1.5em'}}/>
						  </span>
						</span>
					</label>
				</div> */}

			</div>
		</div>
	)
}

const RoomTypes = ({ ota, priority, setPriority, selectedHotel, clientRoomTypes, setClientRoomTypes, anchorRoom, setAnchorRoom, anchorPackage, setAnchorPackage }) => {

	const [show, setShow] = useState(false);

	const getRoomTypes = async () => {

		try {
			const response = await axios.get(`get_room_types/${ota}/${selectedHotel}`);
			if (response.status == 200 && response.data !== undefined) {
				setClientRoomTypes(response.data.room_details)
				setPriority(Object.keys(response.data.room_details))
				setShow(true);
			}
		} catch (err) {
			console.error("Error", err);
		}
	}

	useEffect(() => {
		if (selectedHotel && !priority.length) {
			getRoomTypes()
		}
	}, [selectedHotel]);


	// useEffect(() => {
	// 	if(anchorRoom) setAnchorPackage('')
	// }, [anchorRoom])

	const itemStyles = {
		zIndex: 5,
		cursor: 'grab'
	};

	const SortableItem = SortableElement(({ value }) => {
		return (
			<div className='outline-card p-3 row' style={itemStyles}>
				<div className='mt-2 col-1'>
					<i class="fa-solid fa-bars"></i>
				</div>
				<div className='mt-2 col-1 d-flex justify-content-end align-items-center'>
					<p className='priority small'>#{value.idx + 1}</p>
				</div>
				<div className='mt-2 col-7'>
					<div className='w-100 d-flex align-items-start'>
						<h6 className='bold mx-2'>{value.item.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</h6>
					</div>
				</div>
				<div className='col-3 d-flex justify-content-center align-items-center'>
					<Checkbox onChange={(evt) => value.setAnchorRoom(value.item)} checked={value.item === value.anchorRoom} label="Anchor room" />
					<label>Anchor room</label>
				</div>

				{
					value.item === value.anchorRoom ?
						<div className='mt-3 row col-12 px-2'>
							<h6 className='bold'>Packages offered</h6>

							{
								Object.keys(value.roomTypes[value.anchorRoom]).map((pckg, idx) => (
									<div className='bg-light-yellow col-3 rounded p-2 m-1 d-flex flex-column justify-content-between'>
										<div>
											{
												Array.from(new Set(value.roomTypes[value.anchorRoom][pckg]['room_identifier'].filter(item => item !== ''))).map((item, idx) => (
													<div className='my-2 w-100 row'>
														<div className='col-2'><i class="fa-solid fa-circle-check"></i></div>
														<div style={{ lineHeight: '0.9' }} className='col-10'><span style={{ lineHeight: '0.9' }} className='small-2'>{item}</span></div>
													</div>
												))
											}
										</div>
										<div className='mt-3 d-flex align-items-center'>
											<Checkbox onChange={(evt) => value.setAnchorPackage(pckg)} checked={pckg === value.anchorPackage} label="Anchor room" />
											<label>Anchor package</label>
										</div>
									</div>
								))
							}
						</div>
						:
						null
				}
			</div>
		)
	});

	const SortableList = SortableContainer((props) => {
		return (
			<div>
				{
					props.items.map((item, idx) => (
						<SortableItem key={idx} index={idx} value={{ item, idx, 'roomTypes': props.roomTypes, 'anchorRoom': props.anchorRoom, 'setAnchorRoom': props.setAnchorRoom, 'anchorPackage': props.anchorPackage, 'setAnchorPackage': props.setAnchorPackage }} />
					))
				}
			</div>
		);
	});

	const onSortEnd = ({ oldIndex, newIndex }) => {
		setPriority(arrayMoveImmutable([...priority], oldIndex, newIndex))
	};

	return (
		<div className='w-100 d-flex flex-column justify-content-center align-items-center'>
			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5'>
				<h1 className='bold'>Your Room Types</h1>
			</MotionAnimate>
			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.5'>
				<h6 className='grey-2 text-center center w-80'>Drag and drop the tiles to set the priority order of your room types.</h6>
			</MotionAnimate>

			{
				priority.length ?
					<div className='w-90 mt-5'>
						<SortableList setAnchorRoom={setAnchorRoom} anchorRoom={anchorRoom} anchorPackage={anchorPackage} setAnchorPackage={setAnchorPackage} roomTypes={clientRoomTypes} items={priority} onSortEnd={onSortEnd} />
					</div>
					: null
			}
		</div>
	)
}

const FC = ({ ota, selectedHotel, recommendedHotels, setRecommendedHotels, checked, setChecked }) => {

	const [lat, setLat] = useState('');
	const [lng, setLng] = useState('');

	const getRecommendedFC = async () => {
		try {
			const response = await axios.get(`/get_recommended_fc_v2/${ota}/${selectedHotel}/15`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if (response.status == 200 && response.data !== undefined) {
				setRecommendedHotels(response.data.data)
				setLat(response.data.lat)
				setLng(response.data.lng)
			}
		} catch (err) {
			console.error("Error", err);
		}
	}

	useEffect(() => {
		getRecommendedFC()
	}, []);

	const handleClick = (id, hotelName, star) => {
		if (checked.map(item => item.id).includes(id)) {
			setChecked(checked.filter(item => item.id !== id))
		} else {
			if (checked.length === 5) {
				alert('You are allowed only 5 competitions in the trial version...')
			} else {
				setChecked([{ id, hotelName, star }, ...checked])
			}
		}
	}

	return (
		<div className='w-100 d-flex flex-column justify-content-center align-items-center'>
			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5'>
				<h1 className='bold'>Focus Competition</h1>
			</MotionAnimate>
			<MotionAnimate animation='fadeInUp' speed='0.8' ease='easeInOut' reset='true' threshold='0.5' delay='0.5'>
				<h6 className='grey-2'>Based on your location, choose which hotels you think are competitors.</h6>
			</MotionAnimate>

			<div className='w-100 mt-4 d-flex flex-column align-items-center justify-content-center'>
				<div className='mt-1 w-90 row'>
					{
						lat && lng && recommendedHotels.length ?
							<FCMap radius={15} hotels={recommendedHotels} lat={lat} long={lng} /> : null
					}
				</div>
				<div className='mt-3 w-90 row'>
					{
						recommendedHotels.map((hotel, idx) => (
							<div key={idx} className='col-4'>
								<div onClick={(evt) => handleClick(hotel['_id'], hotel['hotel_name'], hotel['star'])} id={hotel['_id']} style={{ backgroundImage: `url(${hotel['image_url']})` }} className='d-flex flex-column justify-content-between align-items-center w-100 hotel-listing-card p-0 my-3'>
									<div className='w-100 d-flex justify-content-end align-items-center'>
										<Checkbox checked={checked.map(item => item.id).includes(hotel['_id'])} style={{ color: '#FF8B00', backgroundColor: "#fff" }} size='small' className='m-1' />
									</div>
									<div className='w-100 px-2 py-2 hotel-listing-card-gradient'>
										<Rating
											value={hotel['star']}
											readOnly
											size='small'
										/>
										<h6 className='w-100 text-white bold'>{hotel['hotel_name']}</h6>
									</div>
								</div>
							</div>
						))
					}
				</div>
			</div>
		</div>
	)
}

const Priority = ({ selectedHotels, fcPriority, setFCPriority }) => {

	const SortableItem = SortableElement(({ value }) => {
		return (
			<>
				{
					selectedHotels.length ?
						<div className='bg-white rounded shadow p-3 my-4 row'>
							<div className='col-2'>
								<p className='priority small'>#{value.idx + 1}</p>
							</div>
							<div className='col-8'>
								<h5 className='bold'>{(selectedHotels.find(hotel => hotel.id === value.item))['hotelName']}</h5>
							</div>
							<div className='col-2'>
								<Rating
									value={parseInt((selectedHotels.find(hotel => hotel.id === value.item))['star'])}
									readOnly
									size='small'
								/>
							</div>

						</div>
						:
						null
				}
			</>
		)
	});

	const SortableList = SortableContainer((props) => {
		return (
			<div>
				{
					props.fcPriority.map((item, idx) => (
						<SortableItem key={idx} index={idx} value={{ item, idx }} />
					))
				}
			</div>
		);
	});

	const onSortEnd = ({ oldIndex, newIndex }) => {
		setFCPriority(arrayMoveImmutable([...fcPriority], oldIndex, newIndex))
	};

	return (
		<div className='w-100 d-flex flex-column justify-content-center align-items-center'>
			<h1 className='bold'>FC Priority</h1>
			<h6 className='grey-2'>Assign priorities to your chosen FCs</h6>

			<div className='w-90 mt-5'>
				<SortableList onSortEnd={onSortEnd} selectedHotels={selectedHotels} fcPriority={fcPriority} setFCPriority={setFCPriority} />
			</div>
		</div>
	)
}

const Mapping = ({ ota, selectedHotels, roomTypes, setRoomTypes, clientRoomTypes, mapping, setMapping, packageMapping, setPackageMapping }) => {

	const getRoomTypes = async (ids) => {
		try {
			const response = await axios.get(`/get_room_types_multiple/${ota}/${ids}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if (response.status == 200 && response.data !== undefined) {
				setRoomTypes(response.data)
			}
		} catch (err) {
			console.error("Error", err);
		}
	}

	useEffect(() => {
		if (selectedHotels.length) {
			let ids = (selectedHotels.map(item => item.id)).join(",")
			getRoomTypes(ids)
		}
	}, [])

	// const mapRoomType = (hotelId, roomType) => {
	// 	setMapping(prevMapping => {
	// 	    let temp = { ...prevMapping }; // Create a shallow copy of the mapping state

	// 	    return temp; // Return the updated state
	// 	});

	// }
	const handleMapping = (hotel, roomType) => {
		setMapping(prevMapping => {
			let temp = { ...prevMapping };
			temp[hotel] = roomType
			return temp;
		})
		setPackageMapping(prevMapping => {
			let temp = { ...prevMapping };
			temp[hotel] = ""
			return temp;
		})
	}

	const handlePackageMapping = (hotel, pckg) => {
		setPackageMapping(prevMapping => {
			let temp = { ...prevMapping };
			temp[hotel] = pckg
			return temp;
		})
	}

	return (
		<div className='w-100 d-flex flex-column justify-content-center align-items-center'>
			<h1 className='bold'>Room Type Mapping</h1>
			<h6 className='grey-2'>Map your room types to your chosen competitor's ones.</h6>

			<div className='w-100 mt-4 d-flex flex-column align-items-center justify-content-center'>
				<div className='mt-1 w-90 row'>
					{
						roomTypes && Object.keys(roomTypes).length ? Object.keys(roomTypes).map((hotel, idx) => (
							<div key={idx} className='col-12 my-2 p-3 room-type-card'>
								<h4 className='text-orange bold'>{(selectedHotels.find(h => h.id === hotel))['hotelName']}</h4>
								<p className='my-4 text-muted'>Choose Anchor room and anchor package for this competition</p>
								{
									roomTypes[hotel].map((roomType, idx) => (
										<>
											<div className='my-1 w-100 row'>
												<div className='col-8'>{roomType['room_type'].replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</div>
												<div className='col-4'><Checkbox checked={mapping[hotel] === roomType['room_type']} onChange={() => handleMapping(hotel, roomType['room_type'])} label="Map to anchor room" /></div>
											</div>
											<div className='mt-2 w-100 row'>
												{
													mapping[hotel] === roomType['room_type'] ? Object.keys(roomType['hashes']).map((hash, idx) => (
														<>
														<div className='bg-light-yellow col-3 rounded p-2 m-1 d-flex flex-column justify-content-between'>
															<div>
																{
																	(Array.from(new Set(roomType['hashes'][hash]['room_identifier']))).map(amen => (
																		<div className='my-2 w-100 row'>
																			<div className='col-2'><i class="fa-solid fa-circle-check"></i></div>
																			<div style={{ lineHeight: '0.9' }} className='col-10'><span style={{ lineHeight: '0.9' }} className='small-2'>{amen}</span></div>
																		</div>
																	))
																}
															</div>
															<div className='mt-3 d-flex align-items-center'>
																<Checkbox onChange={() => handlePackageMapping(hotel, hash)} checked={packageMapping[hotel] === hash} label="Anchor package" />
																<label>Anchor package</label>
															</div>
														</div>
														</>
													))
														:
														null
												}
											</div>
										</>
									))
								}
							</div>
						))
							:
							null
					}
				</div>
			</div>

		</div>
	)
}

const Final = ({ completed }) => {
	const gifOptions = {
		loop: true,
		autoplay: true,
		animationData: loadingGIF,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	}
	const navigate = useNavigate()
	return (
		<>
			<div className='w-100 mt-3 d-flex h-70 justify-content-center align-items-center'>
			<Grow
        in={true}
        {...(true ? { timeout: 500 } : {})}
      >
				<div className='mt-5 w-80 corner-2 shadow-1 bg-tan p-4 d-flex flex-column justify-content-center align-items-center'>
					{
						!completed ?
						<Grow
						in={true}
						{...(true ? { timeout: 700 } : {})}
					  >
								<div>
								<Lottie
									options={gifOptions}
									height={300}
									width={300}
								/>
								<h2 className='bold'>Onboarding you!</h2>
								<h6 className='small'>Hold on for a few seconds, this might take some time...</h6>
								</div>
							</Grow> :
							<Grow
							in={true}
							{...(true ? { timeout: 700 } : {})}
						  >
							<div>
								<img height={300} src={hotel}

								/>
								<h2 className='bold'>Congratulations! You're Onboarded...</h2>
								<h6 className='medium-1'>You can log into the application and start using dashboards</h6>
								<Button size='large' className="mt-4" variant="contained" onClick={() => navigate('/login')}>Login Now</Button>
							</div>
						</Grow>
					}
				</div>
				</Grow>
			</div>
		</>
	)
}

const Onboarding = () => {

	const [activeStep, setActiveStep] = useState(2);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [clientName, setClientName] = useState('');
	const [hotelName, setHotelName] = useState('');
	const [hotels, setHotels] = useState([]);

	// Client hotel
	const [selectedHotel, setSelectedHotel] = useState('');

	// Room types
	const [priority, setPriority] = useState([])
	const [anchorRoom, setAnchorRoom] = useState('');
	const [anchorPackage, setAnchorPackage] = useState('');

	const [clientCity, setClientCity] = useState('');
	const [star, setStar] = useState('');
	const [clientRoomTypes, setClientRoomTypes] = useState([]);

	const [address, setAddress] = useState('');
	const [lat, setLat] = useState('');
	const [lng, setLng] = useState('');

	// OTA selection
	const [ota, setOTA] = useState('');

	//FC 
	const [recommendedHotels, setRecommendedHotels] = useState([]);
	const [selectedHotels, setSelectedHotels] = useState([]);

	//FC Priority
	const [fcPriority, setFCPriority] = useState([]);

	const [roomTypes, setRoomTypes] = useState(null);

	// FC Room type and package mapping
	const [mapping, setMapping] = useState({});
	const [packageMapping, setPackageMapping] = useState({});

	//Completion status
	const [completed, setCompleted] = useState(false);

	const validSubmission = (step) => {
		if (step === 1) {
			if (!firstName || !validateName(firstName)) {
				toast.error('Enter a valid first name!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
				});
				return false
			}
			if (!lastName || !validateName(lastName)) {
				toast.error('Enter a valid last name!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
				});
				return false
			}
			if (!email || !validateEmail(email)) {
				toast.error('Enter a valid email address!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
				});
				return false
			}
			if (!password || !validatePassword(password)) {
				toast.error('Enter a valid password!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
				});
				return false
			}
			if (confirmPassword !== password) {
				toast.error('Passwords do not match!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
				});
				return false
			}
		}
		else if (step === 2) {
			if (!ota) {
				toast.error('Please select an OTA!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
				});
				return false
			}
		}
		else if (step === 3) {
			if (!selectedHotel) {
				toast.error('Hotel not selected!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
				});
				return false
			}
		}
		else if (step === 4) {
			if (!anchorRoom) {
				toast.error('Please select an anchor room!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
				});
				return false
			}
			else if (!anchorPackage) {
				toast.error('Please select an anchor package!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
				});
				return false
			}
		}

		return true
	}

	const handleNext = () => {
		if(activeStep == 7){
			setActiveStep(activeStep+1)
			onboardClient()
		}
		else if (validSubmission(activeStep)) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const classes = useStyles();

	const clickHotel = (id, name) => {
		if (selectedHotels.find(item => item.id === id) !== undefined) {
			setSelectedHotels(prevSelectedHotels => prevSelectedHotels.filter(item => item.id !== id));

			setMapping(prevMapping => {
				let temp = { ...prevMapping };
				delete temp['emt'][id];
				return temp;
			});
		} else {
			if (selectedHotels.length === 5) {
				alert('You are allowed only 5 competitions in the trial version...');
			} else {
				setSelectedHotels([{ id, name }, ...selectedHotels]);

				setMapping(prevMapping => {
					let temp = { ...prevMapping };
					temp['emt'][id] = [];
					return temp;
				});
			}
		}

	};

	const onboardClient = async () => {
		try {
			const response = await axios.post(`/onboard_client`, {
				ota,
				firstName,
				lastName,
				email,
				password,
				clientName,
				selectedHotel,
				priority,
				anchorRoom,
				anchorPackage,
				clientRoomTypes,
				selectedHotels,
				roomTypes,
				fcPriority,
				mapping,
				packageMapping
			});
			if (response.status == 200 && response.data !== undefined) {
				setCompleted(true);
			}
		} catch (err) {
			console.error("Error", err);
		}
	}

	const steps = [
		{
			label: 'Start Onboarding',
			description: ``,
		},
		{
			label: 'Basic Details',
			description: `Tell us about yourself and create a basic profile.`,
		},
		{
			label: 'Preferred OTA',
			description: `Select your preferred OTA`,
		},
		{
			label: 'Your Hotel',
			description: `Configure your property`,
		},
		{
			label: 'Room Types',
			description: `Configure various room types present in your hotel`,
		},
		{
			label: 'Choose Competitors',
			description: `Choose your Focus Competitions based on your location`,
		},
		{
			label: 'FC Priority',
			description: `Assign priorities to your chosen FCs`,
		},
		{
			label: 'Room Type Mapping',
			description: `Map your room types with your competitors' room type`,
		},
	];

	useEffect(() => {
		if (selectedHotel) {
			setRecommendedHotels([])
			setSelectedHotels([])
		}
	}, [selectedHotel])

	useEffect(() => {
		if (selectedHotels.length) {
			setFCPriority(selectedHotels.map(hotel => hotel.id))
		}
	}, [selectedHotels])

	return (
		<div className='main-onboarding-section w-100 row'>

			<div className='col-9 py-4 px-3'>
				{/* <div className='w-100 d-flex justify-content-end align-items-center'> */}
				{/* 	<div> */}
				{/* 		<span><i class="fa-solid fa-circle-question text-muted medium-2"></i></span> */}
				{/* 	</div> */}
				{/* </div> */}

				{
					activeStep === 0 ?
						<Intro />
						:
						null
				}

				{
					activeStep === 1 ?
						<BasicDetails clientName={clientName} setClientName={setClientName} password={password} setPassword={setPassword} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} email={email} setEmail={setEmail} firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} />
						:
						null
				}

				{
					activeStep === 2 ?
						<OTA
							ota={ota}
							setOTA={setOTA}
						/>
						:
						null
				}

				{
					activeStep === 3 ?
						<Hotel
							ota={ota}
							hotelName={hotelName}
							setHotelName={setHotelName}
							selectedHotel={selectedHotel}
							setSelectedHotel={setSelectedHotel}
						/>
						:
						null
				}

				{
					activeStep === 4 ?
						<RoomTypes
							ota={ota}
							priority={priority}
							setPriority={setPriority}
							anchorRoom={anchorRoom}
							setAnchorRoom={setAnchorRoom}
							anchorPackage={anchorPackage}
							setAnchorPackage={setAnchorPackage}
							selectedHotel={selectedHotel}
							clientRoomTypes={clientRoomTypes}
							setClientRoomTypes={setClientRoomTypes}
						/>
						:
						null
				}

				{
					activeStep === 5 ?
						<FC
							ota={ota}
							selectedHotel={selectedHotel}
							checked={selectedHotels}
							setChecked={setSelectedHotels}
							recommendedHotels={recommendedHotels}
							setRecommendedHotels={setRecommendedHotels}
						/>
						:
						null
				}

				{
					activeStep === 6 ?
						<Priority
							selectedHotels={selectedHotels}
							fcPriority={fcPriority}
							setFCPriority={setFCPriority}
						/>
						:
						null
				}

				{
					activeStep === 7 ?
						<Mapping
							ota={ota}
							selectedHotels={selectedHotels}
							roomTypes={roomTypes}
							setRoomTypes={setRoomTypes}
							clientRoomTypes={clientRoomTypes}
							mapping={mapping}
							setMapping={setMapping}
							packageMapping={packageMapping}
							setPackageMapping={setPackageMapping}
						/>
						:
						null
				}

				{
					activeStep > 7 ?
						<Final completed={completed} />
						:
						null
				}

				<div className='mt-4 w-100 d-flex flex-column justify-content-center align-items-center'>
					{
						activeStep < 8 ?
							<Button size='large' variant='contained' onClick={handleNext} sx={{ mt: 1, mr: 1 }}>Continue</Button> : null
					}

					{
						activeStep !== 0 && activeStep < 8 ?
							<Button className='mt-4' variant="text" onClick={handleBack}>Back</Button> : null
					}
				</div>



				{/* <Button */}
				{/* 	variant="contained" */}
				{/* 	onClick={handleNext} */}
				{/* 	sx={{ mt: 1, mr: 1 }} */}
				{/* 	> */}
				{/* 	{index === steps.length - 1 ? 'Finish' : 'Continue'} */}
				{/* 	</Button> */}
				{/* 	<Button */}
				{/* 	disabled={index === 0} */}
				{/* 	onClick={handleBack} */}
				{/* 	sx={{ mt: 1, mr: 1 }} */}
				{/* 	> */}
				{/* 	Back */}
				{/* </Button> */}

			</div>
			<div className='h-80 timeline-card px-5 py-2 col-3'>
				<Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
					{steps.map((step, index) => (
						<Step key={step.label}>
							<StepLabel
							>
								{step.label}
							</StepLabel>
							<StepContent>
								<p className='small-2 grey-2'>{step.description}</p>
							</StepContent>
						</Step>
					))}
				</Stepper>

			</div>


			<ToastContainer />
		</div>
	)
};

export default Onboarding;