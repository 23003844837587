import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grow from '@mui/material/Grow';
import Table from '../../components/common/Table/Table';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchToken } from '../../Auth';
import auth from '../../assets/img/auth.png';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, TextField } from '@mui/material';
import Chip from '@mui/material/Chip';
import useUserStore from "../../context/UserContext";
import ApprovalStore from "../../context/RoomTypeMealTypeApprovalContext";
import RefreshIcon from '@mui/icons-material/Refresh';
import MealPlanApprovalModal from "./MealPlanApprovalModal";
import Alert from '@mui/material/Alert';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import { InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';



const MealPlanMappings = () => {
    const {
        roomTypeApprovalCount,
        mealPlanApprovalCount,
        incrementRoomTypeApprovalCount,
        decrementRoomTypeApprovalCount,
        incrementMealPlanApprovalCount,
        decrementMealPlanApprovalCount,
        fetchApprovalCount
    } = ApprovalStore();
    const { name, email, role } = useUserStore();
    const [mappings, setMappings] = useState([]);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [selectedForDelete, setSelectedForDelete] = useState('');
    const [password, setPassword] = useState('');
    const [mappedMealPlans, setMappedMealPlans] = useState()
    const [rowModesModel, setRowModesModel] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [rows, setRows] = useState([]);
    const [updatedRow, setUpdatedRow] = useState({});
    const [mealPlanApprovalAccess, setMealPlanApprovalAccess] = useState(false);
    const [mealPlanEditAccess, setMealPlanEditAccess] = useState(false);
    const [addNewMealPlanAccess, setAddNewMealPlanAccess] = useState(false);
    const [popUpOpen, setPopUpOpen] = useState(false)
    const [approvalData, setApprovalData] = useState({})
    const [openAddNewModal, setOpenAddNewModal] = useState(false);
    const [newMealPlan, setNewMealPlan] = useState("");
    const [showNewMealPlanAdditionAlert, setShowMealPlanAdditionAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");


    const [searchText, setSearchText] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);

    const handleSearch = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setSearchText((preVal) => searchValue);
        const filtered = rows.filter((row) =>
            Object.values(row).some((value) =>
                String(value).toLowerCase().includes(searchValue)
            )
        );
        setFilteredRows(filtered);
    };

    const popUpClose = () => {
        setPopUpOpen(false)
    }
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        if (!isEditing) {
            setIsEditing(true)
        } else {
            return;
        }

        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: "meal_plan" },
        }));
        const editedRow = rows.find((row) => row.id === id);
    };
    const handleCancelClick = (id) => () => {
        setIsEditing(false)
        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        }));
        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }

    };
    const processRowUpdate = (newRow) => {
        // console.log(newRow)
        setUpdatedRow((prevRow) => ({ ...newRow }))
        return { ...newRow, isNew: false };
    };
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };
    const handleSaveClick = (id) => async () => {
        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.View },
        }));
    };
    const updateMapping = async (id) => {
        await updateMealPlanMapping(id);
    }

    useEffect(() => {
        if (Object.keys(updatedRow).length) {
            updateMapping(parseInt(updatedRow['id']))
        }
    }, [updatedRow])
    const updateMealPlanMapping = async () => {
        try {
            const data = {
                ...updatedRow,
                mapping_approval_access: mealPlanApprovalAccess
            }
            if (!data.meal_plan || !mappedMealPlans.includes(data.meal_plan)) {
                toast.error("Invalid meal plan.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                });
                setIsEditing(false)
                setUpdatedRow({});
                return
            }
            const response = await axios.post("/update_meal_plan_mapping", data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
                },
            })
            if (response.status === 200) {
                setIsEditing(false)
                setUpdatedRow({});
                getMealPlanMappings()
                if (!mealPlanApprovalAccess) {
                    incrementMealPlanApprovalCount()
                }
                toast.success(response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.SUCCESS
                });
                setRows((prevRows) =>
                    prevRows.map((row) =>
                        row.id === updatedRow.id ? { ...updatedRow, isNew: false } : row
                    )
                );
            }


        } catch (error) {
            console.error("Error adding mapping", error);
            setIsEditing(false)
            setUpdatedRow({});
            toast.error("Error updating meal plan mapping.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
                type: toast.TYPE.ERROR
            });
        }
    }
    const cols = [
        // {
        //     field: 'id', headerName: "Meal Plan ID", headerClassName: 'bg-grey-3', flex: 1,
        //     renderCell: (params) => (
        //         <span className='text-orange bold'>{params.row.id}</span>
        //     )
        // },
        { field: 'key', headerName: "Key", headerClassName: 'bg-grey-3', flex: 3 },
        {
            field: "meal_plan",
            headerName: "Meal Plan",
            headerClassName: 'bg-grey-3',
            flex: 2,
            editable: true,
            renderEditCell: (params) => (
                <Autocomplete
                    // freeSolo
                    options={mappedMealPlans || []}
                    value={params.value}
                    onChange={(event, newValue) => {
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue });
                    }}
                    // onInputChange={(event, newInputValue) => {
                    //     params.api.setEditCellValue({ id: params.id, field: params.field, value: newInputValue });
                    // }}
                    renderInput={(params) => <TextField {...params} />}
                    fullWidth
                />
            ),
        },
        {
            field: "status",
            headerName: "Status",
            headerClassName: "bg-grey-3",
            // width: 250,
            flex: 2,
            sortable: false,
            // align: 'center',
            // headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.is_approved) {
                    return (
                        <Chip
                            label="Approved"
                            color="success"
                            size="small"
                            sx={{ "width": "15em" }}
                        />
                    );
                } else if (!params.row.is_approved && params.row.approval_pending && params.row.auto_approval) {
                    return (
                        <Chip
                            label="Automated Mapping"
                            sx={{ backgroundColor: "#0091ea", color: "white", "width": "15em" }}
                            size="small"
                        />
                    )
                } else if (!params.row.is_approved && params.row.approval_pending) {
                    return (
                        <Chip
                            label="Awaiting Checker’s Approval"
                            sx={{ backgroundColor: "#f57f17", color: "white", "width": "15em" }}
                            size="small"
                        />
                    )
                }
                return null;
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            headerClassName: "bg-grey-3",
            // width: 100,
            flex: 1,
            cellClassName: "actions",
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{ color: "primary.main" }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                        disabled={row.approval_pending || !mealPlanEditAccess}
                    />,
                ];
            },
        },
        {
            field: "approvals",
            type: "actions",
            headerName: "Approvals",
            headerClassName: "bg-grey-3",
            flex: 1,
            cellClassName: "approvals",
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={<UpdateOutlinedIcon />}
                        label="Approve"
                        disabled={!mealPlanApprovalAccess || row.is_approved}
                        onClick={() => {
                            setApprovalData(row)
                            setPopUpOpen(true)
                        }}
                    />
                ];
            },
        },
    ]

    const navigate = useNavigate();


    const getMealPlanMappings = async () => {
        try {
            const response = await axios.get("/get_meal_plan_mappings", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200) {
                setRows(response.data);
                if (searchText) {
                    const filtered = response.data.filter((row) =>
                        Object.values(row).some((value) =>
                            String(value).toLowerCase().includes(searchText)
                        )
                    );
                    setFilteredRows(filtered)
                } else {
                    setFilteredRows(response.data)
                }
                setMappings(response.data);
            }
        } catch (error) {
            console.error("Error", error);
            if (error.response.status === 401) {
                navigate('/login');
            }
        }
    }

    const getMappedMealPlans = async () => {
        try {
            const response = await axios.get("/get_mapped_meal_plans", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200) {
                // console.log(response.data)
                setMappedMealPlans(response.data);
            }
        } catch (error) {
            console.error("Error getting mapped meal plans", error)
        }
    }

    useEffect(() => {
        getMealPlanMappings();
        getMappedMealPlans();
    }, [])

    const getApprovalAccessMapping = async () => {
        try {
            const data = {
                user_role_id: role,
                functionality_id: 37,
            }
            const response = await axios.post("/meal_access", data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
                },
            })
            if (response.status === 200) {
                if (response.data.user_privilege === 3) {
                    setMealPlanApprovalAccess(true)
                } else {
                    setMealPlanApprovalAccess(false)
                }
            }
        } catch (error) {
            console.error("Error getting approval mapping", error)
            if (error.response && error.response.status === 401) {
                navigate("/login");
            }
        }
    }
    const getApprovalEditAccess = async () => {
        try {
            const data = {
                user_role_id: role,
                functionality_id: 38,
            }
            const response = await axios.post("/meal_access", data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
                },
            })
            if (response.status === 200) {
                if (response.data.user_privilege === 3 || response.data.user_privilege === 2) {
                    setMealPlanEditAccess(true)
                } else {
                    setMealPlanEditAccess(false)
                }
            }
        } catch (error) {
            console.error("Error getting approval mapping", error)
            if (error.response && error.response.status === 401) {
                navigate("/login");
            }
        }
    }
    const getNewMealPlanMappingAccess = async () => {
        try {
            const data = {
                user_role_id: role,
                functionality_id: 38,
            }
            const response = await axios.post("/meal_access", data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
                },
            })
            if (response.status === 200) {
                if (response.data.user_privilege === 3) {
                    setAddNewMealPlanAccess(true)
                } else {
                    setAddNewMealPlanAccess(false)
                }
            }
        } catch (error) {
            console.error("Error getting approval mapping", error)
            if (error.response && error.response.status === 401) {
                navigate("/login");
            }
        }
    }
    useEffect(() => {
        getApprovalAccessMapping()
        getApprovalEditAccess()
        getNewMealPlanMappingAccess()
    }, [role])
    const handleAddMealPlan = async () => {
        try {
            const data = {
                new_meal_plan: newMealPlan
            }
            if (newMealPlan.trim() === "" || !newMealPlan) {
                setAlertMessage("Enter a valid meal plan")
                setShowMealPlanAdditionAlert(true);
                return;
            }
            const response = await axios.post("/add_meal_plan_mapping", data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
                },
            });
            if (response.status == 200) {

                setOpenAddNewModal(false);
                getMappedMealPlans()
                toast.success("Meal Plan added successfully!");
            }
        } catch (error) {
            console.error("Error adding meal plan", error)
            if (error.response && error.response.status === 400) {
                setAlertMessage(error.response.data.msg);
                setShowMealPlanAdditionAlert(true)
            }

            if (error.response && error.response.status === 401) {
                navigate("/login");
            }
        }
        setNewMealPlan("")
    }


    return (
        <>
            <Grow
                in={true}
                {...(true ? { timeout: 500 } : {})}
            >
                <div className='px-4 py-2 mt-3 white-card'>
                    <div className="mt-3">
                        <div className='bg-grey p-1 py-2 corner w-100 row'>
                            <div className='col-10'>
                                <h3 className='dashboard-card-title'>Meal Plan Mappings</h3>
                                <p className='grey-2 small mt-1'>View meal plans mapped across all OTAs</p>
                            </div>
                            {addNewMealPlanAccess
                                &&
                                <div className='col-2 d-flex justify-content-between align-items-center'>
                                    <Button variant='contained' size='small' onClick={() => { setOpenAddNewModal(true) }}>Map new meal plan</Button>
                                </div>
                            }
                        </div>
                    </div>

                    <div className='mt-1'>
                        <TextField
                            className='my-3 w-40'
                            size='small'
                            variant="outlined"
                            placeholder="Search..."
                            value={searchText}
                            onChange={handleSearch}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchText && (
                                            <IconButton
                                                aria-label="clear search"
                                                onClick={() => {
                                                    handleSearch({ target: { value: '' } });
                                                }}
                                                edge="end"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {filteredRows.length > 0 &&
                            <DataGrid
                                autoHeight={true}
                                disableSelect={true}
                                columns={cols}
                                rows={filteredRows?.map(item => ({
                                    'id': item['meal_plan_id'],
                                    'key': item['key'],
                                    'meal_plan': item['meal_plan'],
                                    'delete': "",
                                    approval_pending: item.approval_pending,
                                    auto_approval: item.auto_approval,
                                    is_approved: item.is_approved,
                                    changed_meal_plan: item.changed_meal_plan,
                                    approval_sent_by: item.approval_sent_by,
                                }))}
                                pageSizeOptions={[5, 10, 50, 100]}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                editMode="row"
                                rowModesModel={rowModesModel}
                                onRowModesModelChange={handleRowModesModelChange}
                                onRowEditStop={handleRowEditStop}
                                processRowUpdate={processRowUpdate}
                                onProcessRowUpdateError={(error) => console.log(error)}
                                sx={{ borderCollapse: "collapse", border: "none" }}
                            />
                        }
                    </div>
                </div>
            </Grow>


            <Dialog
                open={openAddNewModal}
                onClose={() => {
                    setNewMealPlan("")
                    setOpenAddNewModal(false)
                    setShowMealPlanAdditionAlert(false)
                    setAlertMessage("")
                }}
                aria-labelledby="add-room-type-mapping"
            >
                <DialogTitle id="add-room-type-mapping">
                    <h5 className="bold">Add New Meal Plan</h5>
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: 0 }}>
                    <div>
                        <div className="mb-4">
                            <label htmlFor="room-type">Meal Plan</label>
                            <input
                                type="text"
                                id="room-type"
                                value={newMealPlan}
                                onChange={(e) => setNewMealPlan(e.target.value)}
                                className="form-control"
                            />
                        </div>
                    </div>
                </DialogContent>
                {showNewMealPlanAdditionAlert && <Alert severity="error">{alertMessage}</Alert>}
                <DialogActions>
                    <Button onClick={() => {
                        setNewMealPlan("")
                        setOpenAddNewModal(false)
                        setShowMealPlanAdditionAlert(false)
                        setAlertMessage("")
                    }}>Cancel</Button>
                    <Button onClick={handleAddMealPlan} autoFocus>
                        Add Mapping
                    </Button>
                </DialogActions>

            </Dialog>
            <div className='modal-container'>
                <MealPlanApprovalModal open={popUpOpen} onClose={popUpClose} approvalData={approvalData} mappedMealPlans={mappedMealPlans} getMealPlanMappings={getMealPlanMappings} />
            </div>
        </>
    )
}

export default MealPlanMappings;
