import React, { useEffect, useState } from 'react'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import rightArrow from "../../../assets/img/rightArrow.png";
import leftArrow from "../../../assets/img/leftArrow.png";
import Typography from "@mui/material/Typography";
import axios from 'axios';
import { fetchToken } from '../../../Auth'
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';


const RoomDetails = ({ tenantId, propertyId, ota, pms, date, setClubedRoomDetails, modalData }) => {

    const navigate = useNavigate();
    const [roomData, setRoomData] = useState([])
    useEffect(() => {
        populateRoomDetails()
    }, [date])


    const getPaginationBullets = () => {
        const numberOfBullets = Math.ceil(roomData.length / 4);
        return Array.from({ length: numberOfBullets }, (_, index) => (
            <button
                key={index}
                className={`glide__bullet ${currentSlide === index ? 'active' : ''}`}
                data-glide-dir={`=${index}`}
            ></button>
        ));
    };

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        if (roomData && roomData.length !== 0) {
            const glide = new Glide('.glide', {
                type: 'carousel',
                startAt: 0,
                perView: 1,
                focusAt: 'center',
                gap: 10,
                peek: 50,
                breakpoints: {
                    1200: {
                        perView: 3
                    },
                    800: {
                        perView: 2
                    },
                    500: {
                        perView: 1
                    }
                }
            });

            glide.on('run.after', () => {
                setCurrentSlide(glide.index);
            });

            glide.mount();

            return () => glide.destroy();
        }
    }, [roomData]);

    const calculateAvailability = (data) => {
        let totalInventorySum = 0;
        let avlInventorySum = 0;

        data.forEach(item => {
            totalInventorySum += item.total_inventory;
            avlInventorySum += item.avl_inventory;
        });

        const availability = avlInventorySum;
        const percentage = ((totalInventorySum - avlInventorySum) / totalInventorySum) * 100;

        return {
            avl_inventory: availability,
            total_inventory: totalInventorySum,
            curr_occ: parseInt(percentage)

        };
    };

    const populateRoomDetails = async () => {
        try {
            const response = await axios.get(`/fetch_pms_room_types/${ota}/${pms}/${tenantId}/${propertyId}/${date}`, // Removed the extra quotation mark
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                    },
                });
            if (response.status === 200) {
                const clubedDetails = calculateAvailability(response.data)
                setClubedRoomDetails(clubedDetails)
                setRoomData(response.data)
            }

        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }


    const capitalizeFirstWord = (str) => {
        const words = str.split(' ');
        words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
        return words.join(' ');
    };


    return (
        <>
            {roomData && roomData.length ? 
                <>
                    <h5 style={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>Room Details</h5>
                    <div className="glide" id="roomCarousel" style={{ position: 'relative' }}>
                        <div className="glide__track" data-glide-el="track" >
                            <ul className="glide__slides">
                                {roomData && roomData.length !== 0 && (
                                    roomData.map((item, index) => {

                                        if (index % 4 === 0) {
                                            const groupItems = roomData.slice(index, index + 4);
                                            let colClass = 'col-2';

                                            if (groupItems.length === 1 || groupItems.length === 2) {
                                                colClass = 'col-4'; // Updated to col-4
                                            } else if (groupItems.length === 3) {
                                                colClass = 'col-3';
                                            }

                                            return (
                                                <li key={index} className="glide__slide">
                                                    <div className="d-flex justify-content-evenly">
                                                        {groupItems.map((innerItem, innerIndex) => (
                                                            <div key={innerIndex} className={`card-insights-container ${colClass} m-2`}>
                                                                <div className="mb-2 card-img-container" style={{ backgroundImage: `url(${innerItem.room_image_url || 'https://images.pexels.com/photos/1457842/pexels-photo-1457842.jpeg?cs=srgb&dl=pexels-jvdm-1457842.jpg&fm=jpg'})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                                                                    <div className="extended-room-chip"
                                                                        style={{
                                                                            background: modalData && modalData.flags && innerItem.base_room ? modalData.flags[ota].includes('overpriced') || modalData.flags[ota].includes('underpriced')
                                                                                ? 'linear-gradient(90deg, #FFBBBB 30%, #FF3737 100%)'
                                                                                : modalData.flags[ota].includes('comp_price_inc') || modalData.flags[ota].includes('comp_price_dec')
                                                                                    ? 'linear-gradient(90deg, #FFC46B 30%, #FF5C01 100%)'
                                                                                    : 'white' : 'white'
                                                                        }}


                                                                    >{capitalizeFirstWord(innerItem['mapped_room_type'])} Room</div>

                                                                    {innerItem['base_room'] &&
                                                                        <div className="base-room-chip">
                                                                            {/* {capitalizeFirstWord(innerItem['mapped_room_type'])} */}
                                                                            Base Room</div>
                                                                    }

                                                                </div>
                                                                <div className="card-body-insights">
                                                                    <div className="card-item">
                                                                        <div>CP</div>
                                                                        <div>{innerItem['base_fare'] ? `₹ ${innerItem['base_fare']}` : '-'}</div>
                                                                    </div>
                                                                    <div className="card-item">
                                                                        <div>A/T</div>
                                                                        <div>{innerItem['avl_inventory'] && innerItem['total_inventory'] ? `${innerItem['avl_inventory']}/${innerItem['total_inventory']}` : '-'}</div>
                                                                    </div>
                                                                    <div className="card-item">
                                                                        <div>Curr Occ :</div>
                                                                        <div>{innerItem['curr_occ'] ? `${innerItem['curr_occ']}%` : '-'}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </li>
                                            );
                                        }
                                        return null;
                                    })
                                )}
                            </ul>
                        </div>

                        {roomData.length > 4 && (
                            <>
                                <div className="glide__arrows" data-glide-el="controls">
                                    <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
                                        <KeyboardArrowLeftIcon sx={{ color: 'black', fontWeight: 'bold', fontSize: '2em' }} />
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
                                        <KeyboardArrowRightIcon sx={{ color: 'black', fontSize: '2em' }} />
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>

                                <div className="glide__bullets" data-glide-el="controls[nav]">
                                    {getPaginationBullets()}
                                </div>
                            </>
                        )}


                    </div>

                    <div className="carousel-legend mb-2 d-flex justify-content-center">
                        {/* <div className="legend-item">
                        <span className="legend-color overselling"></span>
                        <span>Over Selling</span>
                        </div> */}
                            {/* <div className="legend-item">
                        <span className="legend-color underselling"></span>
                        <span>Under Selling</span>
                        </div> */}
                        <div className="legend-item">
                            <span>CP: Current Price</span>
                        </div>
                        <div className="legend-item">
                            <span>A/T: Rooms Available</span>
                        </div>
                        <div className="legend-item">
                            <span>Curr Occ: Current Room Occupancy</span>
                        </div>
                        {/* <div className="legend-item">
                        <span>Past Occ: Past 4 weeks same DoW Occupancy</span>
                        </div> */}
                    </div>
                </>
                :
                <>
                    <h5 style={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>Room Details</h5>
                    <div className='row py-4 px-5'>
                        <div className='d-flex justify-content-center col-4 p-1'>
                            <Stack className='w-100' spacing={1}>
                                <Skeleton variant="rounded" width={330} height={80} />
                                <Skeleton variant="rounded" width={330} height={100} />
                            </Stack>
                        </div>
                        <div className='d-flex justify-content-center col-4 p-1'>
                            <Stack className='w-100' spacing={1}>
                                <Skeleton variant="rounded" width={330} height={80} />
                                <Skeleton variant="rounded" width={330} height={100} />
                            </Stack>
                        </div>
                        <div className='d-flex justify-content-center col-4 p-1'>
                            <Stack className='w-100' spacing={1}>
                                <Skeleton variant="rounded" width={330} height={80} />
                                <Skeleton variant="rounded" width={330} height={100} />
                            </Stack>
                        </div>
                    </div>
                </>
            }
        </>

    )
}

export default RoomDetails