const cityDetails = {
	'Agra': {
		'state': 'Uttar Pradesh',
		'img': 'https://images.unsplash.com/photo-1564507592333-c60657eea523?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YWdyYXxlbnwwfHwwfHx8MA%3D%3D'
	},	
	'Ahmedabad': {
		'state': 'Gujarat',
		'img': 'https://media.istockphoto.com/id/1682148808/photo/jama-masjid-or-jumah-mosque-ahmedabad.webp?b=1&s=170667a&w=0&k=20&c=2uYekvrZDoZIkB8w7NB3zCPRm4o8yi5vKtVKP1WGe4E=',
	},
	'Ajmer': {
		'state': 'Rajasthan',
		'img': 'https://images.unsplash.com/photo-1496372412473-e8548ffd82bc?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGFqbWVyJTIwZGFyZ2FoJTIwc2hhcmlmfGVufDB8fDB8fHww',
	},
	'Akola': {
		'state': 'Maharashtra',
		'img': 'https://www.tourmyindia.com/states/maharashtra/images/best-time-to-visit-akola.jpg',
	},
	'Aligarh': {
		'state': 'Uttar Pradesh',
		'img': 'https://images.unsplash.com/photo-1639545865078-5945bd8a536e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YW11fGVufDB8fDB8fHww&w=1000&q=80',
	},
	'Allahabad': {
		'state': 'Uttar Pradesh',
		'img': 'https://images.unsplash.com/photo-1612981889051-27be7c2d52ec?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8YWxsYWhhYmFkfGVufDB8fDB8fHww',
	},
	'Amravati': {
		'state': 'Maharashtra',
		'img': 'https://upload.wikimedia.org/wikipedia/commons/0/03/Amravati_Court.jpg',
	},
	'Amritsar': {
		'state': 'Punjab',
		'img': 'https://images.unsplash.com/photo-1676490081752-2cd8404a8609?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YW1yaXRzYXIlMjBnb2xkZW4lMjB0ZW1wbGV8ZW58MHx8MHx8fDA%3D',
	},
	'Aurangabad': {
		'state': 'Maharashtra',
		'img': 'https://images.unsplash.com/photo-1620557812584-adee82f3c16d?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXVyYW5nYWJhZHxlbnwwfHwwfHx8MA%3D%3D',
	},
	'Bangalore': {
		'state': 'Karnataka',
		'img': 'https://media.istockphoto.com/id/1543179357/photo/largest-legislative-building-in-india-vidhan-soudha-bangalore-with-nice-blue-sky-background.webp?b=1&s=170667a&w=0&k=20&c=AaIPcukpHWuZnPXFHK3MdN1Bb--jcuNUJU1DNmi-JGA=',
	},
	'Bareilly': {
		'state': 'Uttar Pradesh',
		'img': 'https://upload.wikimedia.org/wikipedia/commons/7/78/Ahichchhatra_Shwethambar_Tirth_%281%29.jpg',
	},
	"Belgaum": {
		'state': 'Karnataka',
		'img': 'https://facts.net/wp-content/uploads/2023/07/31-facts-about-belgaum-1688780426.jpeg',
	},
	"Benaulim": {
		'state': 'Goa',
		'img': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8AWguLMeHaZ-qLFhI-ri33unDLJI03SHN3g&s'
	},
	"Bengaluru": {
		'state': 'Karnataka',
		'img': 'https://media.istockphoto.com/id/1543179357/photo/largest-legislative-building-in-india-vidhan-soudha-bangalore-with-nice-blue-sky-background.webp?b=1&s=170667a&w=0&k=20&c=AaIPcukpHWuZnPXFHK3MdN1Bb--jcuNUJU1DNmi-JGA=',
	},
	"Bhilai": {
		'state': 'Chattisgarh',
		'img': 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0b/a3/77/9e/jagannath-temple-sector.jpg?w=700&h=500&s=1',
	},
	"Bhopal": {
		'state': 'Madhya Pradesh',
		'img': 'https://media.istockphoto.com/id/1403056000/photo/taj-ul-masajid-bhopal-madhya-pradesh-india.webp?b=1&s=170667a&w=0&k=20&c=36a2cRkKd-EF8cm65LZ52gT4yRsivuFO5enxuLYMKAA=',
	},
	"Bhubaneswar": {
		'state': 'Odisha',
		'img': 'https://media.istockphoto.com/id/1403056000/photo/taj-ul-masajid-bhopal-madhya-pradesh-india.webp?b=1&s=170667a&w=0&k=20&c=36a2cRkKd-EF8cm65LZ52gT4yRsivuFO5enxuLYMKAA=',
	},
	"Bikaner": {
		'state': 'Rajasthan',
		'img': 'https://images.unsplash.com/photo-1674909634726-2a810e89d25c?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGJpa2FuZXJ8ZW58MHx8MHx8fDA%3D',
	},
	"Calangute": {
		'state': 'Goa',
		'img': 'https://resources.thomascook.in/images/holidays/sightSeeing/62896_Calangute_Beach_Goa_India_960.jpg'
	},
	"Candolim": {
		'state': 'Goa',
		'img': 'https://static.thehosteller.com/blogimage/1-1682321546492.jpg'
	},
	"Cansaulim": {
		'state': 'Goa',
		'img': "https://goa-tourism.org.in/images/places-to-visit/headers/cansaulim-beach-goa-entry-fee-timings-holidays-reviews-header.jpg"
	},
	"Cavelossim": {
		'state': 'Goa',
		'img': 'https://media1.thrillophilia.com/filestore/cg2tvbfb6ce8mesvxilrrixyjoz6_1432386185cavellossim.jpg?w=400&dpr=2'
	},
	"Chandigarh": {
		'state': 'Punjab',
		'img': 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Palace_of_Assembly_Chandigarh.jpg/188px-Palace_of_Assembly_Chandigarh.jpg',
	},
	"Chennai": {
		'state': 'Tamil Nadu',
		'img': 'https://images.unsplash.com/photo-1616843413587-9e3a37f7bbd8?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Y2hlbm5haSUyQyUyMGluZGlhfGVufDB8fDB8fHww',
	},
	"Cuttack": {
		'state': 'Odisha',
		'img': 'https://mediaim.expedia.com/destination/1/0373f2b3ab7293686bf72d1eed6f60d5.jpg',
	},
	"Davanagere": {
		'state': 'Karanataka',
		'img': 'https://facts.net/wp-content/uploads/2023/07/44-facts-about-davangere-1689315395.jpg',
	},
	"Dehradun": {
		'state': 'Uttarakhand',
		'img': 'https://images.unsplash.com/photo-1585032317668-2da731001cf3?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZGVocmFkdW58ZW58MHx8MHx8fDA%3D',
	},
	"Delhi": {
		'img': 'https://media.istockphoto.com/id/510795912/photo/india-gate.webp?b=1&s=170667a&w=0&k=20&c=Nts8vpG6WNkm8s3GSY-8LGmRGpwmzzJtowWLmSRvVks=',
		'state': 'Delhi',
	},
	"Dhanbad": {
		'state': 'Jharkhand',
		'img': 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/SAC_at_IIT_ISM_Dhanbad.jpg/640px-SAC_at_IIT_ISM_Dhanbad.jpg',
	},
	"Faridabad": {
		'state': 'Haryana',
		'img': 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Academic_Block%2C_ESIC_Medical_College_and_Hospital%2C_Faridabad.jpg/220px-Academic_Block%2C_ESIC_Medical_College_and_Hospital%2C_Faridabad.jpg',
	},
	"Firozabad": {
		'img': 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Academic_Block%2C_ESIC_Medical_College_and_Hospital%2C_Faridabad.jpg/220px-Academic_Block%2C_ESIC_Medical_College_and_Hospital%2C_Faridabad.jpg',
		'state': 'Uttar Pradesh',
	},
	"Gandhinagar": {
		'img': 'https://media.istockphoto.com/id/937419294/photo/borij-derasar-a-jain-temple-in-gandhinagar-gujarat-india.webp?b=1&s=170667a&w=0&k=20&c=i1G7b4IELo2MAnAyQO_wX8jthxrwcZZucYNW2i9ytz4=',
		'state': 'Gujarat',
	},
	"Gaya": {
		'img': 'https://img.traveltriangle.com/blog/wp-content/uploads/2023/01/Places-To-Visit-In-Bodh-Gaya-Copy.jpg',
		'state': 'Bihar',
	},
	"Ghaziabad": {
		'img': 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Shipra_Mall%2C_Ghaziabad.jpg/1280px-Shipra_Mall%2C_Ghaziabad.jpg',
		'state': 'Uttar Pradesh',
	},
	"Goa": {
		'state': 'Goa',
		'img': 'https://yu-hotel.com/new/wp-content/uploads/2023/03/best-luxury-hotels-in-north-goa-YU-Hotel.jpg'
	},
	"Gorakhpur": {
		'img': 'https://dd0w3jaz1pep7.cloudfront.net/assets/images/tourism/city/Gorakhpur/gorakhpur-4fcd3fcde44d78692f22ed11.jpg',
		'state': 'Uttar Pradesh',
	},
	"Gurgaon": {
		'img': 'https://upload.wikimedia.org/wikipedia/commons/2/29/DLF_Gateway_Tower.png',
		'state': 'Uttar Pradesh',
	},
	"Guwahati": {
		'img': 'https://upload.wikimedia.org/wikipedia/commons/1/11/Guwahati_citysky.jpg',
		'state': 'Assam',
	},
	"Hyderabad": {
		'img': 'https://images.unsplash.com/photo-1441911645956-df6e9bbc5496?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aHlkZXJhYmFkfGVufDB8fDB8fHww',
		'state': 'Telangana'
	},
	"Indore": {
		'img': 'https://media.istockphoto.com/id/668729904/photo/jahaz-mahal-ship-palace-in-mandu-india.webp?b=1&s=170667a&w=0&k=20&c=2isOj8cvfOsinfPRl5hJd4DDqY8b4vl-Y25Q8GfCVzM=',
		'state': ' Madhya Pradesh',
	},
	"Jabalpur": {
		'img': 'https://upload.wikimedia.org/wikipedia/commons/6/62/Marble_Rocks_-_Jabalpur.jpg',
		'state': 'Madhya Pradesh',
	},
	"Jaipur": {
		'img': 'https://images.unsplash.com/photo-1524230507669-5ff97982bb5e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8amFpcHVyfGVufDB8fDB8fHww',
		'state': 'Rajasthan',
	},
	"Jalandhar": {
		'img': 'https://upload.wikimedia.org/wikipedia/commons/9/96/Devi_Talab_Mandir.jpg',
		'state': 'Punjab',
	},
	"Jamnagar": {
		'img': 'https://www.gujarattourism.com/content/dam/gujrattourism/images/zone-wise-thumb-images/jamnagar.jpg',
		'state': 'Gujarat',
	},
	"Jamshedpur": {
		'img': 'https://upload.wikimedia.org/wikipedia/commons/6/65/Cityscapes_of_Jamshedpur.jpg',
		'state': 'Jharkhand',
	},
	"Jhansi": {
		'img': 'https://facts.net/wp-content/uploads/2023/07/38-facts-about-jhansi-1688709956.jpg',
		'state': 'Uttar Pradesh',
	},
	"Jodhpur": {
		'img': 'https://media.istockphoto.com/id/805563154/photo/mehrangharh-fort-and-jaswant-thada-mausoleum-in-jodhpur-rajasthan-india.webp?b=1&s=170667a&w=0&k=20&c=UMJ1yrvc6WBShcbeNeC0ujKNQFo_OHXaJuw5f5A578s=',
		'state': 'Rajasthan',
	},
	"Kanpur": {
		'img': 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0e/ed/01/90/iskcon-kanpur.jpg?w=500&h=400&s=1',
		'state': 'Uttar Pradesh',
	},
	"Kolkata": {
		'img': 'https://images.unsplash.com/photo-1536421469767-80559bb6f5e1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8a29sa2F0YXxlbnwwfHwwfHx8MA%3D%3D',
		'state': 'West Bengal',
	},
	"Kolhapur": {
		'img': 'https://media.istockphoto.com/id/1164386039/photo/howrah-bridge-on-river-ganges-at-kolkata-at-twilight-with-moody-sky.webp?b=1&s=170667a&w=0&k=20&c=bvlrRRZ8Jyz-j_6FrA0h7VW7qlD5i5D-volKfQha5V4=',
		'state': 'Maharashtra',
	},
	"Kota": {
		'img': 'https://img.traveltriangle.com/blog/wp-content/uploads/2020/01/OGPlacesKota0310.jpg',
		'state': 'Rajasthan',
	},
	"Kochi": {
		'img': 'https://media.istockphoto.com/id/478247780/photo/kochi-chinese-fishnets-in-twilight-kerala-fort-kochin.webp?b=1&s=170667a&w=0&k=20&c=1ug7IcrikBem4bN2a4u_x0IdrC2F8cCwkEwUsxGDCDs=',
		'state': 'Kerala',
	},
	"Lonavala": {
		'state': 'Maharashtra',
		'img': 'https://hblimg.mmtcdn.com/content/hubble/img/lonavaladestimgs/mmt/activities/t_ufs/m_Rajmachi_Fort_Lonavala_1_l_480_640.jpg'
	},
	"Lucknow": {
		'img': 'https://images.unsplash.com/photo-1601752874509-0e350467dc7b?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bHVja25vdyUyMHV0dGFyJTIwcHJhZGVzaCUyMGluZGlhfGVufDB8fDB8fHww',
		'state': 'Uttar Pradesh',
	},
	"Ludhiana": {
		'img': 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0c/25/d1/29/photo1jpg.jpg?w=500&h=-1&s=1',
		'state': 'Punjab',
	},
	"Madurai": {
		'img': 'https://www.abhibus.com/blog/wp-content/uploads/2023/04/Madurai-Meenakshi-Temple-History-Timings-How-to-Reach.jpg',
		'state': 'Tamil Nadu',
	},
	"Mahabaleshwar": {
		'img': 'https://www.holidify.com/images/bgImages/MAHABALESHWAR.jpg',
		'state': 'Maharashtra'
	},
	"Manali": {
		'img': 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e2/Manali_Mountains.jpg/1200px-Manali_Mountains.jpg',
		'state': 'Himachal Pradesh'
	},
	"Mangalore": {
		'img': 'https://im.hunt.in/cg/mangalore/City-Guide/abtmang.jpg',
		'state': 'Karnataka',
	},
	"Meerut": {
		'img': 'https://www.shutterstock.com/image-photo/new-delhi-delhiindia-aug-6-260nw-1791084479.jpg',
		'state': 'Uttar Pradesh',
	},
	"Moira": {
		'state': 'Goa',
		'img': 'https://static2.tripoto.com/media/filter/tst/img/226024/TripDocument/1504452217_aleksandra_boguslawska_509.jpg.webp'
	},
	"Mumbai": {
		'img': 'https://images.unsplash.com/photo-1598434192043-71111c1b3f41?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fG11bWJhaXxlbnwwfHwwfHx8MA%3D%3D',
		'state': 'Maharashtra',
	},
	"Munnar": {
		'img': 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Munnar_hill_station_.JPG/1200px-Munnar_hill_station_.JPG',
		'state': 'Kerala'
	},
	"Mussoorie": {
		'img': 'https://static.toiimg.com/photo/msid-80611280,width-96,height-65.cms',
		'state': 'Uttarakhand'
	},
	"Mysore": {
		'img': 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Mysore_Palace_-_Amba_Vilas_Palace.jpg/1200px-Mysore_Palace_-_Amba_Vilas_Palace.jpg',
		'state': 'Karnataka',
	},
	"Nagpur": {
		'img': 'https://media.istockphoto.com/id/1271158079/photo/deekshabhoomi-is-in-nagpur-maharashtra-a-location-regarded-as-a-pilgrimage-center-of-buddhism.webp?b=1&s=170667a&w=0&k=20&c=-_K8j-i4wzbip_G2QEwBHUXbuX3Ulsk2oREPiTduzls=',
		'state': 'Maharashtra',
	},
	"Nanded": {
		'img': 'https://i.pinimg.com/originals/f1/15/81/f11581862567610b8b9e61de3599909f.jpg',
		'state': 'Maharashtra',
	},
	"Nashik": {
		'img': 'https://images.unsplash.com/photo-1587899765674-f5c9e3bf9c5b?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8bmFzaGlrfGVufDB8fDB8fHww',
		'state': 'Maharashtra',
	},
	"Navi Mumbai": {
		'img': 'https://www.shutterstock.com/image-photo/navi-mumbai-india-6-december-260nw-385556155.jpg',
		'state': 'Maharashtra',
	},
	"New Delhi": {
		'img': 'https://media.istockphoto.com/id/510795912/photo/india-gate.webp?b=1&s=170667a&w=0&k=20&c=Nts8vpG6WNkm8s3GSY-8LGmRGpwmzzJtowWLmSRvVks=',
		'state': 'Delhi',
	},
	"Nellore": {
		'img': 'https://www.nativeplanet.com/img/2014/08/11-5-nellore.jpg',
		'state': 'Andhra Pradesh',
	},
	"Noida": {
		'img': 'https://wallpapercave.com/wp/wp11059400.jpg',
		'state': 'Uttar Pradesh',
	},
	"Ooty": {
		'img': 'https://clubmahindra.gumlet.io/blog/media/section_images/ultimate-o-8ac88a2da056a3d.jpg?w=376&dpr=2.6',
		'state': 'Tamil Nadu'
	},
	"Oragadam": {
		'img': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_md-M9tvXx1ofWLYdim88v24DfrMla_Py6Q&s',
		'state': 'Tamil Nadu'
	},
	"Panjim": {
		'img': 'https://static2.tripoto.com/media/filter/tst/img/2166627/TripDocument/1629527488_img_2666.jpg.webp',
		'state': 'Goa',
	},
	"Patna": {
		'img': 'https://images.unsplash.com/photo-1642069695904-528045db6443?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHBhdG5hfGVufDB8fDB8fHww',
		'state': 'Bihar',
	},
	"Pondicherry": {
		'state': "Tamil Nadu",
		'img': 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Pondicherry-Rock_beach_aerial_view.jpg/1200px-Pondicherry-Rock_beach_aerial_view.jpg'
	},
	"Pune": {
		'img': 'https://cdn.britannica.com/69/146869-050-676DD6B7/Aga-Khan-Palace-Pune-India.jpg',
		'state': 'Maharashtra',
	},
	"Raipur": {
		'img': 'https://facts.net/wp-content/uploads/2023/07/46-facts-about-raipur-1688540781.jpg',
		'state': 'Chattisgarh',
	},
	"Rajkot": {
		'img': 'https://facts.net/wp-content/uploads/2023/07/34-facts-about-rajkot-1688547041.jpeg',
		'state': 'Gujarat',
	},
	"Ranchi": {
		'img': 'https://images.unsplash.com/photo-1609991148865-40902bd1f594?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cmFuY2hpfGVufDB8fDB8fHww',
		'state': 'Jharkhand',
	},
	"Saharanpur": {
		'img': 'https://c0.wallpaperflare.com/preview/583/140/20/india-saharanpur.jpg',
		'state': 'Uttar Pradesh',
	},
	"Saligao": {
		'img': 'https://makeithappen.co.in/wp-content/uploads/2021/12/Saligao-G1-Mobile-8.jpg',
		'state': 'Goa'
	},
	"Shimla": {
		'img': 'https://t3.ftcdn.net/jpg/01/05/09/32/360_F_105093204_csAIVsj4UJPJJdWmamOjHThVdW9BdSN6.jpg',
		'state': 'Himachal Pradesh'
	},
	"Siliguri": {
		'img': 'https://www.shutterstock.com/image-photo/golpahar-viewpoint-tourist-spot-on-260nw-1371004850.jpg',
		'state': 'West Bengal',
	},
	"Srinagar": {
		'img': 'https://media.istockphoto.com/id/1323846766/photo/a-beautiful-view-of-dal-lake-in-winter-srinagar-kashmir-india.webp?b=1&s=170667a&w=0&k=20&c=BCC9Jlc7mLSxR3S7Y7GTtOhuBIcuNGhaydTbzsWzbeo=',
		'state': 'Jammu and Kashmir',
	},
	"Sriperumbudur": {
		'img': 'https://upload.wikimedia.org/wikipedia/commons/3/3b/Sriperumbudur-Sri-Adikesava-Perumal-Temple-4.JPG',
		'state': 'Chennai'
	},
	"Tiruchirapalli": {
		'img': 'https://images.unsplash.com/photo-1614519738232-27ba94d26403?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHRyaWNoeXxlbnwwfHwwfHx8MA%3D%3D',
		'state': 'Tamil Nadu',
	},
	"Thane": {
		'img': 'https://www.shutterstock.com/image-photo/thane-ghodbunder-city-maharashtra-india-260nw-2302137051.jpg',
		'state': 'Maharashtra',
	},
	"Udaipur": {
		'img': 'https://images2.alphacoders.com/545/545298.jpg',
		'state': 'Rajasthan',
	},
	"Ujjain": {
		'img': 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Mahakal_temple.jpg',
		'state': 'Madhya Pradesh',
	},
	"Utorda": {
		'state': 'Goa',
		'img': 'https://www.trawell.in/admin/images/upload/121905739Utorda_Beach_Main.jpg'
	},
	"Varanasi": {
		'img': 'https://media.istockphoto.com/id/537988165/photo/varanasi.webp?b=1&s=170667a&w=0&k=20&c=vqr-PzTfIycbIpbzgWms1FRQ3o4fnYxcivhlR3HuLNs=',
		'state': 'Uttar Pradesh',
	},
	"Vashi": {
		'img': 'https://lh3.googleusercontent.com/proxy/L-ErtdMa6mk1pkrTzpHrzplCZc9R_mRvDiFx2yDTmP-jwcmSwiZR8h5vqhU9QFkPJq88v08CO3YAQnPycHOcSGFO45ssAiwO2kuU01J8LDyE-6cTG9HIveGr1-7M6MHeTZ2dlJpLcg',
		'state': "Maharashtra"
	},
	"Vijayawada": {
		'img': 'https://t3.ftcdn.net/jpg/03/28/61/30/360_F_328613003_lfjwAEmb1qkVWnOzQZv7Mbp1yjNyYraA.jpg',
		'state': 'Andhra Pradesh',
	},
	"Visakhapatnam": {
		'img': 'https://images.unsplash.com/photo-1609854534028-b512f5246abc?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dmlzYWtoYXBhdG5hbXxlbnwwfHwwfHx8MA%3D%3D',
		'state': 'Andhra Pradesh',
	},
	"Warangal": {
		'img': 'https://media.istockphoto.com/id/1209304543/photo/warangal.jpg?s=612x612&w=0&k=20&c=5ehiM1Mvhi9ZkiuY7nm70lCzjNsIA1ONkMUGUe32JdM=',
		'state': 'Telangana'
	}

}


export default cityDetails