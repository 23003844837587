import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';

import emt from '../../assets/img/emt_logo_2.png';
import mmt from '../../assets/img/mmt_logo_2.png';

const ClientDetails = () => {

    return(
        <>
            <div className='mt-4 w-100 row'>
                <div className='col-9'></div>
                <div className='col-3'>
                    <div className='admin-card bg-white p-3'>
                        <div><h5 className='bold'>Assigned OTAs</h5></div>
                        <div className='mt-4 w-100 row'>
                            <div className='col-3'>
                                <img className='ota-logo-sm' src={emt}/>
                            </div>
                            <div className='col-6'>
                                <h6>EaseMyTrip</h6>
                            </div>
                            <div className='col-3 d-flex align-items-start justify-content-end'>
                                <input type="checkbox" className='form-check-input' />
                            </div>
                        </div>
                        <div className='mt-3 w-100 row'>
                            <div className='col-3'>
                                <img className='ota-logo-sm' src={mmt}/>
                            </div>
                            <div className='col-6'>
                                <h6>MakeMyTrip</h6>
                            </div>
                            <div className='col-3 d-flex align-items-start justify-content-end'>
                                <input type="checkbox" className='form-check-input' />
                            </div>
                        </div>
                        <div className='mt-4 w-100 d-flex justify-content-center'>
                            <button className=''>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ClientDetails;