import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import CallMissedOutgoingRoundedIcon from '@mui/icons-material/CallMissedOutgoingRounded';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import TrainRoundedIcon from '@mui/icons-material/Train';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNew';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import { fetchToken } from '../../../Auth'
import { toast } from 'react-toastify';

import PriceHistory from './PriceHistory';
import Tile from './Tile';

import bed from '../../../assets/img/double-bed.png'
import soldOut from '../../../assets/img/sold-out.png'
import coupon from '../../../assets/img/coupon.png'
import amenities from '../../../assets/img/waiter.png'

const Chart = ({ loading, ota, city, selectedHotelId, dates, site, cities, selfData, focusComp, trainData, details, chartData, roomTypes, anchorRoomData, setChartData, flags, setPriceModal, week, errors }) => {

	const [roomData, setRoomData] = useState(null);
	const [selectedDate, setSelectedDate] = useState('');
	const [selectedRoom, setSelectedRoom] = useState('');
	const [roomImg, setRoomImg] = useState('');
	const [selectedHotel, setSelectedHotel] = useState('');
	const [open, setOpen] = useState(false);
	const [data, setData] = useState({});
	const [drawerOpen, setDrawerOpen] = useState(false);

	const [compOpen, setCompOpen] = useState(false);
	const [compDate, setCompDate] = useState('');
	const [compData, setCompData] = useState({
		'self': {
			'img_url': "",
			'room_type': "",
			'bar': ""
		},
		'comp': {
			'img_url': "",
			'room_type': "",
			'bar': ""
		}
	});

	const [priceHistoryModal, setPriceHistoryModal] = useState(false);
	const [priceHistoryDate, setPriceHistoryDate] = useState('');

	const navigate = useNavigate();

	const getData = async (date, hotel_id) => {
		// setSelectedDate(date);
		// setSelectedHotel(hotel_id);
		// try {
		//        const response = await axios.get(`/get_type_wise_bar/${date}/${hotel_id}`, {
		//          headers: {
		//            'Content-Type': 'application/json',
		//            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
		//          },
		//        });
		//        if(response.status == 200 && response.data !== undefined){
		//          setData(response.data);
		//          setOpen(true)
		//        }
		//    } catch (err) {
		//      console.error("Error", err);
		//      if(err.response.status === 401){
		//        navigate('/login')
		//      }
		//    }

	}

	const showComparison = (date, img_url, room_type, bar, bed_type) => {
		// 		let self = {}
		// 		let comp = {}
		// 
		// 		for(let key in chartData){
		// 			if(chartData[key]['is_self']){
		// 				for(let item of chartData[key]['date_wise_data']){
		// 					if(item['date'] === date){
		// 						self['img_url'] = item['img_url']
		// 						self['bar'] = item['bar'].toLocaleString('en-IN')
		// 						self['room_type'] = item['room_type'].replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
		// 						self['bed_type'] = item['bed_type']
		// 					}
		// 				}
		// 				break
		// 			}
		// 		}
		// 
		// 		comp['img_url'] = img_url
		// 		comp['room_type'] = room_type
		// 		comp['bar'] = bar
		// 		comp['bed_type'] = bed_type ? bed_type : '-'
		// 
		// 		setCompData({self, comp})
		// 		setCompDate((new Date(date)).toDateString());
		// 		setCompOpen(true)
	}

	const showPriceHistory = (date) => {
		setPriceHistoryDate(date);
		setPriceHistoryModal(true)
	}

	const handleAmenitiesModal = async (mode, date, comp) => {
		setSelectedDate(date)
		let room = roomTypes.find(item => item.is_anchor === true)
		let anchor_room;
		let package_id;
		if (mode === 'self') {
			anchor_room = room['room_type_name']
			package_id = room['package_id']
		} else {
			anchor_room = anchorRoomData[comp]['anchor_room_type']
			package_id = anchorRoomData[comp]['anchor_room_package_id']
		}
		setSelectedRoom(anchor_room.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase()))

		try {
			const response = await axios.get(`/get_room_type_details/${ota}/${date}/${mode === 'self' ? selectedHotelId : comp}/${anchor_room}/${package_id}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if (response.status == 200 && response.data !== undefined) {
				setRoomData(response.data)
				setRoomImg(response.data.img_url)
				setOpen(true)
			}
		} catch (err) {
			console.error("Error", err);
			if (err.response.status === 401) {
				navigate('/login')
			}
		}


	}

	const handleOpenDrawer = () => {
		setDrawerOpen(true)
	}

	return (
		<>

			{
				!loading && typeof details[selectedHotelId] !== 'undefined' ?

					<div className='mt-3 w-100 row d-flex justify-content-center align-items-center'>
						<table className='chart w-100'>
							<thead className=''>
								<Grow
									in={true}
									{...(true ? { timeout: 500 } : {})}
								>
									<tr className='bold'>
										<th scope="col" className='c-2'><h5 className='bold text-orange'><i class="fa-regular fa-calendar mr-2"></i> Week #{week}</h5></th>
										{
											dates.length ?
												dates.map(item => (
													<th scope='col' className='c-1'>

														<div className='w-100'>
															<div className='d-flex justify-content-center align-items-center'>
																<h6 className=' text-center bold'>{item.day}</h6>
																{
																	errors && item.key in errors && 'self' in errors[item.key] && errors[item.key]['self'].length ?
																		<div style={{ position: 'relative' }}>
																			<Tooltip title={errors[item.key]['self'].map(err => err).join(' | ')}>
																				<IconButton>
																					<ErrorIcon />
																				</IconButton>
																			</Tooltip>
																		</div>
																		: null
																}
															</div>
															<p className='text-center grey-2 small'>{item.date}</p>
														</div>

													</th>
												))
												: null
										}
									</tr>
								</Grow>
							</thead>
							<tbody>
								<Grow
									in={true}
									{...(true ? { timeout: 600 } : {})}
								>
									<tr>
										<td scope='row' className='c-2'>
											<div className='d-flex flex-column justify-content-end align-items-center w-90 hotel-listing-card-3 p-0 ' style={{ backgroundImage: `url(${typeof details[selectedHotelId] !== 'undefined' ? details[selectedHotelId]['img_url'] : ''})` }}>
												<div className='w-100 px-2 hotel-listing-card-gradient-3 d-flex flex-column justify-content-end'>
													<Rating
														value={details[selectedHotelId]['star']}
														readOnly
														size='small'
													/>
													<p className='w-100 text-white bold'>{details[selectedHotelId]['hotel_name'].length > 21 ? details[selectedHotelId]['hotel_name'].slice(0, 18) + "..." : details[selectedHotelId]['hotel_name']}</p>
												</div>
											</div>
										</td>
										{
											dates && flags ? Object.keys(flags).filter(item => dates.find(dt => dt['key'] === item)).map((item, idx) => (
												<td>
													<div style={{ height: '15em', fontSize: '0.8em' }} className='w-90 p-2 rounded bg-grey-6 justify-content-center align-items-center d-flex flex-wrap'>
														<div className='h-20 w-100 d-flex justify-content-end'>
															{
																Object.keys(trainData).length && trainData[item]['trainDemand'] ?

																	<Tooltip title={`Seats Available: ${trainData[item]['avlSeats'].toLocaleString('en-IN') + ' / ' + trainData[item]['totalSeats'].toLocaleString('en-IN')}`} size='small' placement="right">
																		<IconButton onClick={() => handleOpenDrawer()} size='small'>
																			<TrainRoundedIcon style={{ color: "#ff8b00" }} />
																		</IconButton>
																	</Tooltip>
																	:
																	null
															}
														</div>
														<div className='d-flex flex-column justify-content-center align-items-center'>
															<p className='mb-0'>Your Price</p>
															<h6 className='bold large-2'>₹ {selfData[item]['anchor_room_price'] ? selfData[item]['anchor_room_price'].toLocaleString('en-IN') : '-'}</h6>
														</div>
														<div className='w-90 d-flex justify-content-around align-items-center'>
															{
																selfData[item]['anchor_room_price'] ?
																	flags[item]['overpriced'] ?
																		<div className='my-1 danger-pill py-1 px-2'><i class="fa-solid fa-circle-exclamation mr-2"></i> Overpriced</div>
																		:
																		flags[item]['underpriced'] ?
																			<div className='my-1 danger-pill py-1 px-2'><i class="fa-solid fa-circle-exclamation mr-2"></i> Underpriced</div>
																			:
																			<div className='my-1 success-pill py-1 px-2'><i class="fa-solid fa-circle-check mr-2"></i> Competitive</div>
																	:
																	null
															}

														</div>
														<div className='w-100 d-flex justify-content-evenly align-items-center'>
															<button onClick={() => showPriceHistory(item)} className='chart-btn-2'><i class="fa-solid fa-chart-line"></i></button>
															<button onClick={() => handleAmenitiesModal('self', item, null)} className='chart-btn-2'><i className="pointer fa-solid fa-up-right-from-square"></i></button>
														</div>

													</div>
												</td>
											))
											:
											null
										}
									</tr>
								</Grow>



								{
									details && Object.keys(chartData).length ? Object.keys(chartData).map((hotel, idx) => (
										<tr>
											<td scope='row'>
												<div className='d-flex flex-column justify-content-end align-items-center w-90 hotel-listing-card-3 p-0 ' style={{ backgroundImage: `url(${details[hotel]['img_url']})` }}>
													<div style={{ position: 'relative', right: '0', left: '30%', top: '-10px' }} className='orange-circle'>#{focusComp[hotel]['priority']}</div>
													<div className='w-100 px-2 hotel-listing-card-gradient-3 d-flex flex-column justify-content-end'>
														<Rating
															value={details[hotel]['star']}
															readOnly
															size='small'
														/>
														<p className='w-100 text-white bold'>{details[hotel]['hotel_name'].length > 21 ? details[hotel]['hotel_name'].slice(0, 18) + '...' : details[hotel]['hotel_name']}</p>
													</div>
												</div>
											</td>

											{
												dates.length ? dates.map((date, idx) => (
													<td key={idx} className='h-100 p-1'>
														<div style={{ height: '100px' }} className="py-2 rounded p-1 border corner">
															<div className='w-100 d-flex justify-content-end'>
																{
																	chartData[hotel][date['key']]['similar_anchor_package_taken'] ?
																		chartData[hotel][date['key']]['similar_anchor_room_taken'] ?
																			<Tooltip className='h-100' style={{ fontSize: '0.3em' }} title='Showing price for closest anchor room & package'>
																				<IconButton style={{ fontSize: '1em' }}>
																					<AutoFixHighRoundedIcon className='text-orange' style={{ fontSize: '1.2em' }} />
																				</IconButton>
																			</Tooltip>
																			:
																			<Tooltip className='h-100' style={{ fontSize: '0.3em' }} title='Showing price for closest anchor package'>
																				<IconButton style={{ fontSize: '1em' }}>
																					<AutoFixHighRoundedIcon className='text-orange' style={{ fontSize: '1.2em' }} />
																				</IconButton>
																			</Tooltip>
																		:
																		<IconButton onClick={() => handleAmenitiesModal('comp', date['key'], hotel)} style={{ fontSize: '1em' }}>
																			<OpenInNewRoundedIcon style={{ fontSize: '1.2em' }} />
																		</IconButton>

																}
															</div>
															<div className='mt-1 h-20 w-100 d-flex justify-content-center align-items-start'>
																<div className='px-2 h-10 mt-2 d-flex align-items-center'>
																	{
																		'anchor_room_price' in chartData[hotel][date['key']] ?
																			<h5 className='medium-3 bold'>₹ {chartData[hotel][date['key']]['anchor_room_price'].toLocaleString('en-IN')}</h5>
																			:
																			<h5 className='medium-3 bold'>₹ -</h5>
																	}
																</div>

															</div>
															<div className='mt-1 px-2 d-flex justify-content-around align-items-start'>


																{
																	'price_diff' in chartData[hotel][date['key']] ?
																		<p className='warning-pill px-1' >{parseInt(chartData[hotel][date['key']]['price_diff']) < 0 ? <i class="fa-solid fa-caret-up mr-2" style={{ fontSize: '1.5em' }}></i> : <i class="fa-solid fa-caret-down mr-2" style={{ fontSize: '1.5em' }}></i>} {Math.abs(chartData[hotel][date['key']]['price_diff'])}%</p>
																		: null
																}
															</div>


														</div>
													</td>
												))
													: null
											}
										</tr>
									))
										:
										null
								}
							</tbody>
						</table>


						{/* Price History Graphs */}
						<Dialog
							open={priceHistoryModal}
							onClose={() => setPriceHistoryModal(false)}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
							fullWidth='lg'
							maxWidth='lg'
						>
							<DialogTitle>
								<h5 className='bold'>Price History</h5>
							</DialogTitle>
							<DialogContent>
								<PriceHistory details={details} ota={ota} city={city} date={priceHistoryDate} selectedHotelId={selectedHotelId} />
							</DialogContent>
							<DialogActions>
								<Button onClick={() => setPriceHistoryModal(false)}>Close</Button>
							</DialogActions>
						</Dialog>



						<Dialog
							open={open}
							onClose={() => setOpen(false)}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
							fullWidth='md'
							maxWidth='md'
						>
							<DialogTitle id="alert-dialog-title">
								<h5 className='mt-1 bold'>Anchor Room Details ({selectedRoom})</h5>
								<p className='small text-muted'><i class="fa-solid fa-calendar mr-2"></i> {(new Date(selectedDate)).toDateString()}</p>
							</DialogTitle>
							{
								roomData ?
									<section className='p-3' style={{ backgroundColor: "#f6f8fa" }}>
										<div className='mt-4 w-100 row'>
											<div className='col-3 d-flex flex-column h-100'>
												<div style={{ height: '250px', maxHeight: '250px' }} className='w-100 p-3 admin-card corner-2 d-flex flex-column align-items-start'>
													<img src={bed} className='outline-icon' />
													<h5 className='bold mt-2'>Bed Type</h5>

													<div className='mt-3'>
														<p className='text-muted medium-3'>{roomData['bed_type'] ? roomData['bed_type'] : 'N/A'}</p>
													</div>
												</div>
											</div>
											<div className='col-3 d-flex flex-column'>
												<div style={{ height: '250px', maxHeight: '250px' }} className='w-100 p-3 admin-card corner-2 d-flex flex-column align-items-start'>
													<img src={amenities} className='outline-icon' />
													<h5 className='bold mt-2'>Amenities</h5>
													<div style={{ overflowY: 'scroll' }} className='w-100 max-height-200 mt-3'>
														{
															roomData['amenities'].filter(item => item !== '').map(item => (
																<p className='text-muted'><i class="fa-regular fa-circle-check mr-1"></i> {item.trim()}</p>
															))
														}
													</div>
												</div>
											</div>
											<div className='col-3 d-flex flex-column'>
												<div style={{ height: '250px', maxHeight: '250px' }} className='w-100 p-3 admin-card corner-2 d-flex flex-column align-items-start'>
													<img src={soldOut} className='outline-icon' />
													<h5 className='bold mt-2'>Sold Out</h5>
													<div className='mt-3'>
														<p className='text-muted medium-3'>{roomData['is_sold_out'] ? 'Yes' : 'No'}</p>
													</div>
												</div>
											</div>
											<div className='col-3 d-flex flex-column'>
												<div style={{ height: '250px', maxHeight: '250px' }} className='w-100 p-3 admin-card corner-2 d-flex flex-column align-items-start'>
													<img src={coupon} className='outline-icon' />
													<h5 className='bold mt-2'>Coupon Code</h5>
													<div className='mt-3'>
														<p className='text-muted medium-3'>{roomData['coupon_code'] ? roomData['coupon_code'] : 'N/A'}</p>
													</div>
												</div>
											</div>
										</div>
									</section>
									:
									null
							}
							<DialogActions className='mt-2'>
								<Button variant='contained' onClick={() => setOpen(false)}>Close</Button>
							</DialogActions>
						</Dialog>


						<Dialog
							open={compOpen}
							onClose={() => setCompOpen(false)}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
							fullWidth='sm'
							maxWidth='sm'
						>
							<DialogTitle className='w-100 row' id="alert-dialog-title">
								<h5 className='mt-1 bold'>Comparison of Best Available Rates</h5>
								<p className='small text-muted'><i class="fa-regular fa-calendar mr-2"></i> {compDate}</p>
							</DialogTitle>
							<section className='p-2 w-100 mb-3' style={{ backgroundColor: "#f6f8fa" }}>
								<div className='w-100 row' >
									<div className='col-4'></div>
									<div className='col-4 d-flex flex-column justify-content-center align-items-center hotel-listing-card-2 p-0' style={{ width: '270px', backgroundImage: `url(${compData['self']['img_url']})`, fontSize: '0.8em' }}>
										.
									</div>
									<div className='col-4 d-flex flex-column justify-content-center align-items-center hotel-listing-card-2 p-0' style={{ width: '270px', backgroundImage: `url(${compData['comp']['img_url']})`, fontSize: '0.8em' }}>
										.
									</div>
								</div>
								<div className='w-100 row mt-3'>
									<div className='col-4 px-4 d-flex align-items-center'>
										<h6 className='small bold grey-2'><i class="fa-solid fa-door-closed mr-2"></i> Room Type</h6>
									</div>
									<div className='col-4 d-flex flex-column justify-content-center align-items-center p-1'>
										{compData['self']['room_type']}
									</div>
									<div className='col-4 d-flex flex-column justify-content-center align-items-center p-1'>
										{compData['comp']['room_type']}
									</div>
								</div>
								<div className='line'></div>
								<div className='w-100 row mt-3'>
									<div className='col-4 px-4 d-flex align-items-center'>
										<h6 className='small bold grey-2'><i class="fa-solid fa-tag mr-2"></i> Best Available Rate</h6>
									</div>
									<div className='col-4 d-flex flex-column justify-content-center align-items-center p-1'>
										<span className='bold'>₹ {compData['self']['bar']}</span>
									</div>
									<div className='col-4 d-flex flex-column justify-content-center align-items-center p-1'>
										<span className='bold'>₹ {compData['comp']['bar']}</span>
									</div>
								</div>
								<div className='line'></div>
								<div className='w-100 row mt-3'>
									<div className='col-4 px-4 d-flex align-items-center'>
										<h6 className='small bold grey-2'><i class="fa-solid fa-bed mr-2"></i> Bed Type</h6>
									</div>
									<div className='col-4 d-flex flex-column justify-content-center align-items-center p-1'>
										<span className=''>{compData['self']['bed_type']}</span>
									</div>
									<div className='col-4 d-flex flex-column justify-content-center align-items-center p-1'>
										<span className=''>{compData['comp']['bed_type']}</span>
									</div>
								</div>
							</section>
						</Dialog>
					</div>

					:

					<div className="p-3">
						<Box>
							<div className='w-100 row'>
								<div className='col-4'>

								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
							</div>
							<div className='mt-3 w-100 row'>
								<div className='col-4'>
									<Skeleton variant="rounded" width={200} height={100} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
								<div className='col-1 d-flex justify-content-center align-items-center'>
									<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
								</div>
							</div>

						</Box>


					</div>

			}

			<Drawer
				anchor='right'
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
			>
				<Box sx={{ width: 250 }} role="presentation" onClick={() => setDrawerOpen(false)}>
					<List>
						Hi
					</List>
				</Box>
			</Drawer>
		</>
	)
}

export default Chart;