import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import '../../../assets/styles/base.css'

const Table = (props) => {

  return (
    <div style={{ height: 'auto', width: '100%' }}>
      <DataGrid
        sx={{borderCollapse: 'collapse', border: 'none'}}
        rows={props.rows}
        columns={props.cols}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        autoHeight={props.autoHeight ? true : false}
        pageSizeOptions={[5, 10, 15, 20]}
        checkboxSelection={props.disableSelect ? false : true}
        headerClassName="table-header"
      />
    </div>
  );
}

export default Table