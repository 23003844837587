import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Table from '../../components/common/Table/Table';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '../../components/common/Select'
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grow from '@mui/material/Grow';
import {fetchToken} from '../../Auth' 
import { toast } from 'react-toastify';

import auth from '../../assets/img/auth.png';

const Functionalities = ({ dashboards }) => {
  const [functionalityName, setFunctionalityName] = useState("");
  const [selectedDashboard, setSelectedDashboard] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [editName, setEditName] = useState("");
  const [editNameValid, setEditNameValid] = useState(true);

  const [deleteFunc, setDeleteFunc] = useState("");

  const [selectedForEdit, setSelectedForEdit] = useState('');

  const [openPassword, setOpenPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [mode, setMode] = useState(null);

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  
  const [functionalityCols, setFunctionalityCols] = useState([
    { field: 'id', headerName: 'ID', width: '70', headerClassName: 'bg-grey-3', flex: 1},
    { field: 'name', headerName: 'Functionality Name', minWidth: 180, headerClassName: 'bg-grey-3', flex: 1},
    { field: 'createdBy', headerName: 'Created By', width: '120', headerClassName: 'bg-grey-3', flex: 1},
    { field: 'createdAt', headerName: 'Created At', width: '120', headerClassName: 'bg-grey-3', flex: 1, type: "dateTime",
      renderCell: (params) => {
        let date = '';
        let time = '';
        if(params.row.createdAt){
          date = `${params.row.createdAt.getDate()} ${months[params.row.createdAt.getMonth()]} ${params.row.createdAt.getFullYear()}` 
          time = `${params.row.createdAt.getHours()}:${params.row.createdAt.getMinutes().toString().padStart(2, '0')}`
        }
        return(
          <div>
            <p className='mb-0'>{date}</p>
            <p className='small grey mb-0'>{time}</p>
          </div>
        )
      }
    },
    { field: 'updatedAt', headerName: 'Updated At', width: '120', headerClassName: 'bg-grey-3', flex: 1, type: "dateTime",
      renderCell: (params) => {
        let date = '';
        let time = '';
        if(params.row.updatedAt){
          date = `${params.row.updatedAt.getDate()} ${months[params.row.updatedAt.getMonth()]} ${params.row.updatedAt.getFullYear()}` 
          time = `${params.row.updatedAt.getHours()}:${params.row.updatedAt.getMinutes().toString().padStart(2, '0')}`
        }
        return(
          <div>
            <p className='mb-0'>{date}</p>
            <p className='small grey mb-0'>{time}</p>
          </div>
        )
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Switch size="large" onClick={async () =>{
          setMode({'mode': 'functionalityStatus', 'id': params.row.id, 'status': params.row.status, 'func': updateFunctionalityStatus})
          handleClickPasswordOpen()
          } } checked={params.row.status ? true : false} />
      ), headerClassName: 'bg-grey-3', flex: 1
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton size="small" aria-label="delete">
          <EditIcon onClick={() => {
            setMode({'mode': 'functionalityEdit', 'func': editFunctionality })
            setEditName(params.row.name)
            setSelectedForEdit(params.row.id)
            handleClickOpenEdit()
          } } fontSize="inherit"/>
        </IconButton>
      ),
      width: '70', headerClassName: 'bg-grey-3', flex: 1
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton size="small" aria-label="delete">
          <DeleteIcon onClick={() => {
            setMode({'mode': 'functionalityDelete', 'func': deleteFunctionality })
            setDeleteFunc(params.row.id)
            setOpenDelete(true)
          } } fontSize="inherit"/>
        </IconButton>
      ),
      width: '140', headerClassName: 'bg-grey-3', flex: 1
    }
  ]);

  const [functionalities, setFunctionalities] = useState([]);

  const navigate = useNavigate();

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClickPasswordOpen = () => {
    setOpenDelete(false)
    handleCloseEdit()
    setOpenPassword(true);
  };

  const handlePasswordClose = () => {
    if(mode['mode'] === 'functionalityStatus'){
      const func = mode['func']
      func(mode['id'], mode['status'], password, selectedDashboard)
    }
    else if(mode['mode'] === 'functionalityEdit'){
      let func = mode['func']
      editFunctionality(password)
    }
    else if(mode['mode'] === 'functionalityDelete'){
      let func = mode['func']
      deleteFunctionality(password)
    }
    setMode(null)
    setOpenPassword(false);
  };

  const updateFunctionalityStatus = async (funcId, currStatus, password, dashId) => {   
    let makeStatus;
    if(currStatus){
      makeStatus = 0;
    }else{
      makeStatus = 1;
    }
    try {
      const response = await axios.post("/update_functionality_status", {funcId, makeStatus, dashId, password}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
          },
      });
      if(response.status == 200){
        setPassword('')
        let temp = response.data.map(item => ({'id': item[0], 'name': item[1], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
        setFunctionalities(temp);
        toast.success('Functionality status updated!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.SUCCESS
        });
      }
    } catch (error) {
      console.error("Error", error);
      setPassword('')
      if(error.response !== undefined && error.response.status === 401){
        let temp = error.response.data.data.map(item => ({'id': item[0], 'name': item[1], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
        setFunctionalities(temp);
        toast.error('Invalid credentials!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
      }
    }
  }

  const getFunctionalities = async () => {
  	try {
        const response = await axios.get(`/get_functionalities/${selectedDashboard}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
          },
        });
        if(response.status == 200 && response.data !== undefined){
          let temp = response.data.map(item => ({'id': item[0], 'name': item[1], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
          setFunctionalities(temp);
        }
    } catch (err) {
      console.error("Error", err);
      if(err.response.status === 401){
        navigate('/login')
      }
    }
  }

  const addFunctionality = async () => {
    if(!selectedDashboard){
      toast.error('Select a dashboard first!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
      });
      return
    }
    if(!functionalityName){
      toast.error('Please provide a functionality name!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
      });
      return
    }
    if(/^\d+$/.test(functionalityName)){
      toast.error('Functionality name cannot contain only numerical characters!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.ERROR
      });
      return;
    }
    if(!/^[a-zA-Z\s]+$/.test(functionalityName)){
      toast.error('Functionality name should contain only alphabetical characters!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.ERROR
      });
      return;
    }
  	try{
  		const response = await axios.post(`/add_functionality`, {selectedDashboard, functionalityName} , {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
          },
        });
        if(response.status == 200 && response.data !== undefined){
	        setFunctionalityName("");
          setFunctionalities([...functionalities, {id: response.data[0], name: response.data[1], createdBy: response.data[4], createdAt: new Date(response.data[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), updatedAt: response.data[6] ? new Date(response.data[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', status: response.data[3]}])
	    }

  	} catch (err) {
      console.error("Error", err);
      if(err.response.status === 401){
        navigate('/login')
      }
    }
  }

  const editFunctionality = async (password) => {
    if(!editNameValid){
      return
    }
    try {
      const response = await axios.post("/edit_functionality", {'funcId': selectedForEdit, selectedDashboard: selectedDashboard, "name": editName, "password": password }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
          },
      });
      if(response.status == 200 && response.data !== undefined){
        let temp = response.data.map(item => ({'id': item[0], 'name': item[1], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
        setFunctionalities(temp);
        setSelectedForEdit("");
        setEditName("");
        setPassword('');
        toast.success('Functionality details updated!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "light",
            type: toast.TYPE.SUCCESS,
            className: "bg-success text-white"
        });
      }
    } catch (error) {
      console.error("Error", error);
      setPassword('')
      if(error.response !== undefined && error.response.status === 401){
        let temp = error.response.data.data.map(item => ({'id': item[0], 'name': item[1], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
        setFunctionalities(temp);
        toast.error('Invalid credentials!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
      }
    } 
  }

  const deleteFunctionality = async (password) => {
    try{
      const response = await axios.post(`/delete_functionality`, {funcId: deleteFunc, selectedDashboard: selectedDashboard, "password": password} , {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
          },
        });
        if(response.status == 200 && response.data !== undefined){
          let temp = response.data.map(item => ({'id': item[0], 'name': item[1], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
          setFunctionalities(temp);
          setOpenDelete(false)
          setDeleteFunc("");
          toast.success('Functionality deleted!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.SUCCESS
          });
        }

    } catch (err) {
      console.error("Error", err);
      setPassword('')
      if(err.response !== undefined && err.response.status === 401){
        let temp = err.response.data.data.map(item => ({'id': item[0], 'name': item[1], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
        setFunctionalities(temp);
        toast.error('Invalid credentials!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE.ERROR
        });
      }
    }
  }

  useEffect(() => {
  	if(selectedDashboard){
  		getFunctionalities()
  	}
  }, [selectedDashboard])

  useEffect(() => {
    if(!editName){
      setEditNameValid(false)
    }else{
      setEditNameValid(true)
    }
  }, [editName])

  return (
  	<section className="p-3">
		<div className="row">
			<div className="col-12">
        <Grow
          in={true}
          {...(true ? { timeout: 700 } : {})}
        >
				<div className="admin-card bg-white p-4">
					<h6 className="admin-card-header">Manage Functionalities</h6>

					<div class="admin-card-section p-4 mt-4 d-flex justify-content-between align-items-center">
						<Select style={{backgroundColor: "#fff"}} className='bg-white' size="small" options={dashboards.map(item => ({'value': item['id'], 'option': item['name']}))} label={'Dashboard'} onChange={setSelectedDashboard}/>
						<input value={functionalityName} onChange={(e) => setFunctionalityName(e.target.value)} type="text" className="mx-2 form-control" id="functionality-name" placeholder="Functionality name"/>
						<button onClick={addFunctionality} class="mx-2 admin-card-button"><i className='bx bx-plus'></i> Add</button>
					</div>

					<div className="row mt-5">
						<div className="col-12">
							{
                selectedDashboard ?
                <Table disableSelect={true} cols={functionalityCols} rows={functionalities} />
                :
                <Alert severity="info">Select a Dashboard from the dropdown.</Alert>
              }
						</div>
					</div>
				</div>
        </Grow>
			</div>
		</div>


    <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h5 className='bold mt-2'>Edit Functionality</h5>
        </DialogTitle>
        <DialogContent className="py-2">
            <div className="w-100 row">
              <div className="col-12">
                <TextField 
                  error={!editNameValid}
                  helperText={!editNameValid ? "Invalid functionality name" : ""} 
                  className="w-100 mb-1" 
                  label="Functionality Name" 
                  defaultValue={editName} 
                  onChange={(evt) => setEditName(evt.target.value) } 
                  size="small" 
                  variant="outlined" />
              </div>
            </div>  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit}>Cancel</Button>
          <Button variant="contained" onClick={() => handleClickPasswordOpen() } autoFocus>
            <i class="fa-solid fa-circle-check mx-2"></i> Save
          </Button>
        </DialogActions>
      </Dialog>


    <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h5 className='bold mt-3'>Delete this functionality?</h5>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            Clients and User roles to whom this functionality is assigned, will no longer be able to use this it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
          <Button variant="contained" onClick={() => handleClickPasswordOpen() } autoFocus>
            <i class="fa-solid fa-trash mx-2"></i> Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPassword}
        onClose={handlePasswordClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
            <div className='w-100 row d-flex justify-content-between align-items-center'>
              <div className='col-5 d-flex justify-content-center align-items-center'>
                <img style={{height: '10em'}} src={auth}/>
              </div>
              <div className='col-7'>
                <h5 className='bold mt-3'>Authentication required</h5>
                <p className='text-muted small mt-1'>Authentication is required in order to perform this action. Please enter your credentials below.</p>
                <input type='password' className='w-100 mt-2 form-control' placeholder='Enter your password' onChange={(evt) => setPassword(evt.target.value) }/>
              </div>
            </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handlePasswordClose} autoFocus>
            Verify
          </Button>
        </DialogActions>
      </Dialog>

	</section>
  );
}

export default Functionalities;