import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import Table from '../../components/common/Table/Table'
// import Select from '../../components/common/Select'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Grow from '@mui/material/Grow';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import EditRole from './EditRole';
import Notification from '../../components/common/Notification';
import { toast } from 'react-toastify';
import {fetchToken} from '../../Auth' 

const UserRoles = ({ roles, setRoles, dashboards, functionalities }) => {

	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [editId, setEditId] = useState('');
	const [roleName, setRoleName] = useState('');
	const [roleNameValid, setRoleNameValid] = useState(true);
	const [privilege, setPrivilege] = useState(1);
	const [assignedDashboards, setAssignedDashboards] = useState([]);
	const [selectedDashboard, setSelectedDashboard] = useState('');
	const [selectedDashboards, setSelectedDashboards] = useState([]);
	const [functionalityPrivilege, setFunctionalityPrivilege] = useState({});
	const [notif, setNotif] = useState(false);

	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

	const [cols, setCols] = useState([
	    { field: 'id', headerName: 'ID', width: '40', flex: 1},
	    { field: 'name', headerName: 'User Role Name', width: '200', flex: 1},
	    { field: 'createdBy', headerName: 'Created By', width: '230', flex: 1},
	    // { field: 'createdAt', headerName: 'Created At', width: '200', flex: 1},
	    { field: 'createdAt', headerName: 'Created At',   width: '200', flex: 1, type: "dateTime",
	      renderCell: (params) => {
	        let date = '';
	        let time = '';
	        if(params.row.createdAt){
	          date = `${params.row.createdAt.getDate()} ${months[params.row.createdAt.getMonth()]} ${params.row.createdAt.getFullYear()}` 
	          time = `${params.row.createdAt.getHours()}:${params.row.createdAt.getMinutes().toString().padStart(2, '0')}`
	        }
	        return(
	          <div>
	            <p className='mb-0'>{date}</p>
	            <p className='small grey mb-0'>{time}</p>
	          </div>
	        )
	      }
	    },
	    {
	      field: 'status',
	      headerName: 'Status',
	      disableColumnMenu: true,
	      sortable: false,
	      renderCell: (params) => (
	        <Switch size="large" onChange={() => updateUserRoleStatus(params.row.id, params.row.status)} defaultChecked={params.row.status ? true : false} />
	      ),
	      width: '80', flex: 1
	    },
	    {
	      field: 'Edit',
	      headerName: 'Edit',
	      disableColumnMenu: true,
	      sortable: false,
	      renderCell: (params) => (
	        <Button style={{height: '30px', width: '30px', borderRadius: '200px'}} variant="outlined" size="small" onClick={() => editRole(params.row.id)}>
	          <i class='bx bxs-pencil'></i>
	        </Button>
	      ),
	      width: '80', flex: 1
	    }
	 ]);

	const navigate = useNavigate();

	const handleClickOpen = () => {
	    setOpen(true);
	};

	const handleClose = () => {
		setSelectedDashboards([]);
	    setOpen(false);
	};

	useEffect(() => {
		if(roleName) setRoleNameValid(/^[a-zA-Z0-9\s]+$/.test(roleName))
	}, [roleName])

	const createRole = async () => {
		if(!roleName || !/^[a-zA-Z0-9\s]+$/.test(roleName)){
			// toast.error('Enter a valid user role name!', {
	  //           position: toast.POSITION.BOTTOM_RIGHT,
	  //           autoClose: 2500,
	  //           hideProgressBar: false,
	  //           closeOnClick: true,
	  //           pauseOnHover: false,
	  //           draggable: false,
	  //           theme: "colored",
	  //           type: toast.TYPE.ERROR
	  //       });
			setRoleNameValid(false)
			return
		}
		if(!selectedDashboards.length){
			toast.error('Atleast one dashboard should be assigned to a user role!', {
	            position: toast.POSITION.BOTTOM_RIGHT,
	            autoClose: 2500,
	            hideProgressBar: false,
	            closeOnClick: true,
	            pauseOnHover: false,
	            draggable: false,
	            theme: "colored",
	            type: toast.TYPE.ERROR
	        });
			return
		}
		try{
	  		const response = await axios.post(`/create_user_role`, {selectedDashboards, functionalityPrivilege, roleName}, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200){
	        	toast.success('User role created successfully!', {
		            position: toast.POSITION.BOTTOM_RIGHT,
		            autoClose: 2500,
		            hideProgressBar: false,
		            closeOnClick: true,
		            pauseOnHover: false,
		            draggable: false,
		            theme: "colored",
		            type: toast.TYPE.SUCCESS
		        });
		    	handleClose()
		    	setRoles([...roles, {'id': response.data[0], 'name': response.data[1], 'createdBy': response.data[3], 'createdAt': new Date(response.data[4].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': response.data[5], 'status': response.data[2]}])
		    }

	  	} catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	const editRole = async (roleId) => {
		setEditId(roleId);
		setOpenEdit(true);
	}

	const addDashboard = () => {
		let dash_id = (selectedDashboard.split('_'))[0]
		let dash_name = (selectedDashboard.split('_'))[1]
		setSelectedDashboards([...selectedDashboards, [dash_id, dash_name]])
	}


	const updatePrivilege = (privilege, funcId) => {
		let temp = functionalityPrivilege
		temp[funcId] = privilege
		setFunctionalityPrivilege(temp)
	};

	const updateUserRoleStatus = async (roleId, status) => {
		try {
	      const response = await axios.post("/update_user_role_status", {roleId, makeStatus: status ? 0 : 1}, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	      });
	      if(response.status == 200 && response.data !== undefined){
	      	let temp = response.data.map(item => ({'id': item[0], 'name': item[1], 'createdBy': item[3], 'createdAt': new Date(item[4].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[5], 'status': item[2]}))
	        setRoles(temp);
	        toast.success('User role status updated successfully!', {
	            position: toast.POSITION.BOTTOM_RIGHT,
	            autoClose: 2500,
	            hideProgressBar: false,
	            closeOnClick: true,
	            pauseOnHover: false,
	            draggable: false,
	            theme: "colored",
	            type: toast.TYPE.SUCCESS
	        });
	      }
	    } catch (error) {
	      console.error("Error", error);
	    }
	}

	return(

		<section className="p-3">
			<div className="row">
				<div className="col-12">
					<Grow
			          in={true}
			        >
					<div className="admin-card bg-white p-4">
				
						<div className="admin-card-section p-2 row">
							<div class="col-8">
								<h6 className="admin-card-header text-orange">User Roles</h6>
								<p className='text-muted'><span className='bold'>{roles.length}</span> roles created</p>
							</div>
							<div class="col-4 d-flex justify-content-end align-items-center">
								<Button onClick={handleClickOpen} variant="contained"><i class='bx bxs-plus-circle mx-2'></i> Create new role</Button>
							</div>
						</div>

						<div className="row">
							<div className="col-12 p-3">
								<Table disableSelect={true} cols={cols} rows={roles} />
							</div>
						</div>
					</div>
					</Grow>
				</div>
			</div>


			<Dialog
		        open={open}
		        onClose={handleClose}
		        maxWidth={'md'}
		        fullWidth={true}
		        aria-labelledby="alert-dialog-title"
		        aria-describedby="alert-dialog-description"
		    >
		        <DialogTitle id="alert-dialog-title">
		          <h5 class="bold">Create new User role</h5>
		        </DialogTitle>
		        <DialogContent>
		          <DialogContentText id="alert-dialog-description">
		            
		            <div class="mt-2 w-100 row">
		            	<div class="col-8">
		            		<FormControl fullWidth>
		            			<TextField
						          error={!roleNameValid}
						          onChange={(e) => setRoleName(e.target.value)}
						          size='small'
						          id="user-role-name"
						          label="User Role Name" 
						          variant="outlined"
						          helperText={!roleNameValid ? "Invalid role name" : ""}
						        />
		            		</FormControl>
		            	</div>
		            	<div class="mt-4 col-12">
			            	<div class="row">
			            		<div class="col-8">
			            			<FormControl fullWidth size="small">
			        					<InputLabel id="demo-simple-select-label">Active Dashboards</InputLabel>
					            		<Select
								          labelId="demo-simple-select-label"
								          id="dashboard"
								          value={selectedDashboard}
								          label="Active Dashboards"
								          onChange={(evt) => {
								          	let dash_id = (evt.target.value.split('_'))[0]
								          	let dash_name = (evt.target.value.split('_'))[1]
								          	setSelectedDashboard(`${dash_id}_${dash_name}`)
								          }}
								        >
								          {
								          	dashboards.map(item => <MenuItem value={`${item['id']}_${item['name']}`}>{item['name']}</MenuItem>)
								          }
								        </Select>
								    </FormControl>
			            		</div>
			            		<div class="col-4">
			            			<Button onClick={addDashboard} variant="outlined">Add</Button>
			            		</div>
			            	</div>
		            	</div>

		            	<div class="mt-3 col-12">
		            		<div class="accordion" id="accordion">
		            		{
		            			selectedDashboards.map(item => (
		            				<div class="accordion-item">
									    <h2 class="accordion-header" id={`heading${item[0]}`}>
									      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${item[0]}`} aria-expanded="true" aria-controls="collapseOne">
									        {item[1]}
									      </button>
									    </h2>
									    <div id={`collapse_${item[0]}`} class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
									      <div className="accordion-body">
									      	
									      	{
									      		typeof functionalities[`${item[0]}_${item[1]}`] !== 'undefined' ? functionalities[`${item[0]}_${item[1]}`].map(x => (

									      			<div className="row w-100">
											      		<div className="col-6">{x.func_name}</div>
											      		<RadioGroup
													        aria-labelledby="demo-radio-buttons-group-label"
													        name={`radio-buttons-group-${item[0]}_${item[1]}`}
													    	className="col-6"
													    >
													        <div class="d-flex justify-content-between align-items-center">
													        	<FormControlLabel value="1" control={<Radio onChange={(evt) => updatePrivilege(evt.target.value, x['func_id']) } />} label="Reader" />
														        <FormControlLabel value="2" control={<Radio onChange={(evt) => updatePrivilege(evt.target.value, x['func_id']) } />} label="Maker" />
														        <FormControlLabel value="3" control={<Radio onChange={(evt) => updatePrivilege(evt.target.value, x['func_id']) } />} label="Checker" />
													        </div>
													    </RadioGroup>
											      	</div>

									      		)) : null
									      	}


									      </div>
									    </div>
									</div>
		            			))
		            		}
							</div>
		            	</div>
		            </div>

		          </DialogContentText>
		        </DialogContent>

		        <DialogActions className='mt-1'>
		          <Button onClick={handleClose}>Cancel</Button>
		          <Button onClick={createRole} variant="contained" autoFocus>
		            Create Role
		          </Button>
		        </DialogActions>
		    </Dialog>

		    {openEdit ? <EditRole dashboards={dashboards} functionalities={functionalities} roleId={editId} setOpenEdit={setOpenEdit} roles={roles} setRoles={setRoles}/> : null}
			
		</section>
	);
}

export default UserRoles;