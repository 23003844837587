import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from 'react-select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import { toast } from 'react-toastify';

import {fetchToken} from '../../Auth' 

const User = ({roles, users, setUsers, clients, setClients}) => {

	const [userType, setUserType] = useState(null);
	const [showClientDD, setShowClientDD] = useState(false);
	const [selectedClientId, setSelectedClientId] = useState('');
	const [selectedUser, setSelectedUser] = useState(null);
	const [user, setUser] = useState({});
	const [showDetails, setShowDetails] = useState(false);


	// Use these states to edit a user
	const [active, setActive] = useState(false)
	const [userRole, setUserRole] = useState({'value': '', 'label': ''});
	const [assignedClients, setAssignedClients] = useState([]);


	const rolesOptions = []
	for(let item of roles){
		rolesOptions.push({'label': item['name'], 'value': item['id']})
	}

	const navigate = useNavigate();

	const getAllUsers = async () => {
		let type = userType === 1 ? 'internal' : 'external'
		try{
	  		const response = await axios.get(`/get_all_users/${type}`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
		    	let temp = []
		    	for(let item of response.data){
		    		temp.push({label: item[1], value: item[0]})
		    	}
		    	setUsers(temp)
		    }

	  	} catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	

	const getAllExternalUsers = async () => {
		try{
	  		const response = await axios.get(`/fetch_external_users/${selectedClientId}`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
		    	let temp = []
		    	for(let item of response.data){
		    		temp.push({label: item[1], value: item[0]})
		    	}
		    	setUsers(temp)
		    }

	  	} catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	const fetchUserDetails = async () => {
		try{
	  		const response = await axios.get(`/fetch_user_details/${selectedUser['value']}`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
		    	setUser(response.data);
		    	setActive(response.data.is_active)
		    	if(response.data.user_role_id){
		    		setUserRole({'value': response.data.user_role_id, 'label': response.data.user_role_name})
		    	}
		    	if(response.data.is_staff){
		    		let temp = []
		    		for(let item of response.data.assigned_clients){
		    			temp.push({label: item[1], value: item[0]})
		    		}
		    		setAssignedClients(temp)
		    	}
		    	setShowDetails(true);
		    }

	  	} catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	useEffect(() => {
		setUsers([])
		setSelectedUser("")

		if(!userType){
			setShowClientDD(true)
		}else{
			setShowClientDD(false)
		}

		if(userType){
			getAllUsers()
		}
	}, [userType])

	useEffect(() => {
		if(parseInt(userType) === 0 && selectedClientId){
			getAllExternalUsers()
		}
	}, [selectedClientId])

	useEffect(() => {
		if(selectedUser){
			setActive(false)
			setUserRole({'value': '', 'label': ''})
			fetchUserDetails()
		}
	}, [selectedUser])

	const editUser = async () => {
		if(!userRole['value']){
			toast.error('Please assign a user role!', {
	            position: toast.POSITION.BOTTOM_RIGHT,
	            autoClose: 2500,
	            hideProgressBar: false,
	            closeOnClick: true,
	            pauseOnHover: false,
	            draggable: false,
	            theme: "colored",
	            type: toast.TYPE.ERROR
	        });
			return;
		}

		if(!assignedClients || !assignedClients.length){
			toast.error('Aleast one client should be selected!', {
	            position: toast.POSITION.BOTTOM_RIGHT,
	            autoClose: 2500,
	            hideProgressBar: false,
	            closeOnClick: true,
	            pauseOnHover: false,
	            draggable: false,
	            theme: "colored",
	            type: toast.TYPE.ERROR
	        });
			return;
		}

		try{
	  		const response = await axios.post(`/update_user_details/${selectedUser['value']}`,
	  		{
	  			active: active ? 1 : 0,
	  			userRole: userRole['value'],
	  			assignedClients: assignedClients.map(item => parseInt(item['value'])),
	  			userId: selectedUser['value']
	  		},
	  		{
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
		    	toast.success('User details updated!', {
		            position: toast.POSITION.BOTTOM_RIGHT,
		            autoClose: 3500,
		            hideProgressBar: true,
		            closeOnClick: true,
		            pauseOnHover: false,
		            draggable: false,
		            theme: "light",
		            type: toast.TYPE.SUCCESS,
		            className: "bg-success text-white"
		        });
		    }

	  	} catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	return(
		<section className="p-3">
			<div className="row">
				<div className="col-12">
					<Grow
			          in={true}
			        >
					<div className="admin-card bg-white p-4">
						<h6 className="admin-card-header text-orange">User Management</h6>

						<div className="row">
							<div className="admin-card-section col-12">
								<div class="w-100 mt-4 d-flex justify-content-between align-items-center">
									<div className="w-100 mx-2">
										<label>User type</label>
										<Select placeholder="Select User Type" onChange={opt => setUserType(parseInt(opt.value))} className="mt-1 w-100" options={[{'label': 'Internal', value: 1}, {'label': 'External', value: 0}]} />
									</div>
									{ showClientDD ? 
										<div className="w-100 mx-2">
											<label>Clients</label>
											<Select placeholder="Select a Client" onChange={opt => setSelectedClientId(parseInt(opt.value))} options={userType ? clients : clients.filter(item => item.value !== 1 && item.label !== 'Internal')} className="mt-1 w-100" />
										</div>
									 : null }
									<div className="w-100 mx-2">
										<label>User</label>
										<Select placeholder="Select a User" value={selectedUser} onChange={opt => setSelectedUser(opt)} className="mt-1 w-100" options={users} />
									</div>
								</div>

								<div className="row mt-3 mb-4">
							{
								showDetails ?
								<div className="col-12">
									
									
									{
										userType ?
										<>
										<Divider className="my-4" textAlign="left"><span style={{fontWeight: 'bold'}}>Assigned Clients</span></Divider>

										<div className="row">
											<div className="col-8">
												<Select isMulti value={assignedClients} onChange={opt => setAssignedClients(opt)} options={clients} className="w-100" />
											</div>
										</div>
										</>
										:
										null
									}

									<Divider className="my-4" textAlign="left"><span style={{fontWeight: 'bold'}}>Set User Role</span></Divider>

									<div className="row">
										<div className="col-8">
											<Select onChange={(opt) => setUserRole(opt) } value={userRole} className="w-50 mx-2" options={rolesOptions} />
										</div>
									</div>

									<Divider className="my-4" textAlign="left"><span style={{fontWeight: 'bold'}}>Set Active/Inactive </span></Divider>

									<FormControlLabel
							          control={<Switch checked={active} onChange={(evt) => setActive(!active) } color="primary" />}
							          label="User Active"
							          labelPlacement="start"
							        />
									
									<div className="mt-4 w-100 d-flex justify-content-center align-items-center">
										<Button onClick={editUser} style={{width: '20%'}} variant="contained">Save</Button>
									</div>
								</div>
								:
								null
							}
						</div>
							</div>
						</div>

						
					</div>
					</Grow>
				</div>
			</div>
		</section>
	)
}

export default User;