import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import Grow from '@mui/material/Grow';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchToken } from '../../Auth';

import doubleArrow from '../../assets/img/double-arrow.png';

const Dashboard = () => {

    
    const [search, setSearch] = useState("");

    const [emtHotels, setEMTHotels] = useState([]);
    const [agodaHotels, setAgodaHotels] = useState([]);
    const [bookingHotels, setBookingHotels] = useState([]);

    const [selectedEMTHotel, setSelectedEMTHotel] = useState('');
    const [selectedEMTHotelName, setSelectedEMTHotelName] = useState('');
    const [selectedAgodaHotel, setSelectedAgodaHotel] = useState('');
    const [selectedAgodaHotelName, setSelectedAgodaHotelName] = useState('');
    const [selectedBookingHotel, setSelectedBookingHotel] = useState('');
    const [selectedBookingHotelName, setSelectedBookingHotelName] = useState('');

    const navigate = useNavigate();

    const searchHotels = async (search) => {
        try {
            const response = await axios.post("/search_hotels_all_otas", { search }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200) {
                setEMTHotels(response.data.emt_hotels)
                setAgodaHotels(response.data.agoda_hotels)
                setBookingHotels(response.data.booking_hotels)
            }
        } catch (error) {
            console.error("Error", error);
            if (error.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const saveMapping = async () => {
        let star1 = selectedEMTHotel ? (emtHotels.find(hotel => hotel.emt_id === selectedEMTHotel))['star'] : null
        let star2 = selectedAgodaHotel ? (agodaHotels.find(hotel => hotel.agoda_id === selectedAgodaHotel))['star'] : null
        let star3 = selectedBookingHotel ? (bookingHotels.find(hotel => hotel.booking_id === selectedBookingHotel))['star'] : null

        // if ((star1 && star2) && (star1 != star2)) {
        //     toast.error(`The EMT hotel is a ${star1} star hotel, and the Agoda hotel is a ${star2} star hotel. You
        //     cannot map them!`, {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         autoClose: 4500,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: false,
        //         draggable: false,
        //         theme: "colored",
        //     });
        //     return;
        // }
        // if ((star1 && star3) && (star1 != star3)) {
        //     toast.error(`The EMT hotel is a ${star1} star hotel, and the Booking.com hotel is a ${star3} star hotel. You
        //     cannot map them!`, {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         autoClose: 4500,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: false,
        //         draggable: false,
        //         theme: "colored",
        //     });
        //     return;
        // }
        try {
            const response = await axios.post("/update_hotel_mapping", {
                'emt_id': selectedEMTHotel, 'agoda_id': selectedAgodaHotel, 'booking_id': selectedBookingHotel,
                'emt_name': selectedEMTHotelName, 'agoda_name': selectedAgodaHotelName, 'booking_name': selectedBookingHotelName
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200) {
                setSearch('');
                setEMTHotels([])
                setAgodaHotels([])
                setBookingHotels([])
                setSelectedAgodaHotel('')
                setSelectedAgodaHotelName('')
                setSelectedBookingHotel('')
                setSelectedBookingHotelName('')
                setSelectedEMTHotel('')
                setSelectedEMTHotelName('')
                toast.success(response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                });
            }
            else if (response.status == 400) {
                toast.error(response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                });
            }
        } catch (error) {
            console.error("Error", error);
            if (error.response && error.response.status == 400) {
                toast.error(error.response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                });
            }
        }
    }

    useEffect(() => {
        setSelectedEMTHotel('')
        setSelectedEMTHotelName('')
        setSelectedAgodaHotel('')
        setSelectedAgodaHotelName('')
        setSelectedBookingHotel('')
        setSelectedBookingHotelName('')
        if (search.trim().length >= 5) {
            searchHotels(search)
        }
    }, [search])

    useEffect(() => {
        if (selectedEMTHotel) {
            setSelectedEMTHotelName((emtHotels.find(hotel => hotel.emt_id === selectedEMTHotel))['hotel_name'])
        }
    }, [selectedEMTHotel])

    useEffect(() => {
        if (selectedAgodaHotel) {
            setSelectedAgodaHotelName((agodaHotels.find(hotel => hotel.agoda_id === selectedAgodaHotel))['hotel_name'])
        }
    }, [selectedAgodaHotel])

    useEffect(() => {
        if (selectedBookingHotel) {
            setSelectedBookingHotelName((bookingHotels.find(hotel => hotel.booking_id === selectedBookingHotel))['hotel_name'])
        }
    }, [selectedBookingHotel])

    return (
        <>
            <Grow
                in={true}
                {...(true ? { timeout: 500 } : {})}
            >
                <div className='px-4 py-2 mt-3 white-card'>
                    <div className="mt-3">
                        <div className='bg-grey p-1 py-2 corner w-100 row'>
                            <div className='col-9'>
                                <h3 class='dashboard-card-title'>Map new Hotel</h3>
                                <p className='grey-2 small mt-1'>Map a particular hotel across all OTAs</p>
                            </div>
                            <div className='col-3 d-flex justify-content-between align-items-center'>
                                <Button variant='contained' size='large' onClick={() => navigate('/hotel-mappings/mappings')}>View Mappings</Button>
                            </div>
                        </div>

                        <div className='mt-3 mb-3 w-50'>
                            <h6 className='mt-2 bold'>Map a new hotel</h6>
                            <p className='text-muted small'>Search for a hotel name to view results across all OTAs</p>
                            <input value={search} onChange={(evt) => setSearch(evt.target.value)} type="text" class="form-control" aria-describedby="hotelSearch" placeholder='Start typing...' />
                        </div>
                    </div>
                </div>
            </Grow>

            <div className='w-100 row mt-3'>
                <div className='col-6 my-2'>
                    <Grow
                        in={true}
                        {...(true ? { timeout: 700 } : {})}
                    >
                        <div className='px-4 py-2 white-card height-auto max-height-400'>
                            <h5 className='mt-2 bold'>EaseMyTrip</h5>

                            {
                                emtHotels.length ?
                                    <div style={{ height: '300px', maxHeight: '300px', overflowY: 'scroll' }} className='mt-2 px-2 w-100 '>
                                        {
                                            emtHotels.map((hotel) => (
                                                <div style={{ cursor: 'pointer', borderRadius: '12px !important' }} className={selectedEMTHotel === hotel.emt_id ? 'corner-2 shadow bg-orange text-white mx-1 my-3 py-1 w-90 row' : 'bordered border-hover mx-1 my-2 py-1 w-90 row'}>
                                                    <div className='col-4 h-100'>
                                                        <img className='square-img-1 corner' src={hotel.img_url} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='small bold'>{hotel.hotel_name}</p>
                                                        <p style={{ marginTop: '-10px' }} className='text-muted small-2'>{hotel.emt_id}</p>
                                                    </div>
                                                    <div className='col-2'>
                                                        <Checkbox className='bg-white' checked={selectedEMTHotel === hotel.emt_id} onChange={(evt) => setSelectedEMTHotel(hotel.emt_id)} />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className='h-80 d-flex justify-content-center align-items-center'>
                                        <Alert severity="info">No hotels matched on EMT.</Alert>
                                    </div>
                            }
                        </div>
                    </Grow>
                </div>
                <div className='col-6 my-2'>
                    <Grow
                        in={true}
                        {...(true ? { timeout: 900 } : {})}
                    >
                        <div className='px-4 py-2 white-card height-auto max-height-400'>
                            <h5 className='mt-2 bold'>Agoda</h5>
                            {
                                agodaHotels.length ?
                                    <div style={{ height: '300px', maxHeight: '300px', overflowY: 'scroll' }} className='mt-2 px-2 w-100 '>
                                        {
                                            agodaHotels.map((hotel) => (
                                                <div style={{ cursor: 'pointer', borderRadius: '12px !important' }} className={selectedAgodaHotel === hotel.agoda_id ? 'corner-2 shadow bg-orange text-white mx-1 my-3 py-1 w-90 row' : 'bordered border-hover mx-1 my-2 py-1 w-90 row'}>
                                                    <div className='col-4 h-100'>
                                                        <img className='square-img-1 corner' src={hotel.img_url} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='small bold'>{hotel.hotel_name}</p>
                                                        <p style={{ marginTop: '-10px' }} className='text-muted small-2'>{hotel.agoda_id}</p>
                                                    </div>
                                                    <div className='col-2'>
                                                        <Checkbox className='bg-white' checked={selectedAgodaHotel === hotel.agoda_id} onChange={(evt) => setSelectedAgodaHotel(hotel.agoda_id)} />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className='h-80 d-flex justify-content-center align-items-center'>
                                        <Alert severity="info">No hotels matched on Agoda.</Alert>
                                    </div>
                            }
                        </div>
                    </Grow>
                </div>
                <div className='col-6 my-2'>
                    <Grow
                        in={true}
                        {...(true ? { timeout: 1100 } : {})}
                    >
                        <div className='px-4 py-2 white-card height-auto max-height-400'>
                            <h5 className='mt-2 bold'>Booking.com</h5>

                            {
                                bookingHotels.length ?
                                    <div style={{ height: '300px', maxHeight: '300px', overflowY: 'scroll' }} className='mt-2 px-2 w-100 '>
                                        {
                                            bookingHotels.map((hotel) => (
                                                <div style={{ cursor: 'pointer', borderRadius: '12px !important' }} className={selectedBookingHotel === hotel.booking_id ? 'corner-2 shadow bg-orange text-white mx-1 my-3 py-1 w-90 row' : 'bordered border-hover mx-1 my-2 py-1 w-90 row'}>
                                                    <div className='col-4 h-100'>
                                                        <img className='square-img-1 corner' src={hotel.img_url} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='small bold'>{hotel.hotel_name}</p>
                                                        <p style={{ marginTop: '-10px' }} className='text-muted small-2'>{hotel.booking_id}</p>
                                                    </div>
                                                    <div className='col-2'>
                                                        <Checkbox className='bg-white' checked={selectedBookingHotel === hotel.booking_id} onChange={(evt) => setSelectedBookingHotel(hotel.booking_id)} />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className='h-80 d-flex justify-content-center align-items-center'>
                                        <Alert severity="info">No hotels matched on Booking.com.</Alert>
                                    </div>
                            }
                        </div>
                    </Grow>
                </div>
            </div>

            <Grow
                in={true}
                {...(true ? { timeout: 1500 } : {})}
            >
                <div className='px-4 py-2 mt-3 mb-3 white-card'>
                <h5 className='mt-2 bold'>Hotel ID Mapping</h5>
                <p className='small text-muted'>A hotel if not present on an OTA, will not be mapped</p>

                <div className='mt-3 d-flex flex-column justify-content-center align-items-center'>
                    <div className='mt-2 w-70 d-flex justify-content-between align-items-center'>
                        <div className='bordered corner-2 p-3'>
                            <h5 className='bold'>{selectedEMTHotel ? selectedEMTHotel : 'N/A'}</h5>
                            <p>EaseMyTrip</p>
                        </div>
                        <div>
                            <img src={doubleArrow} style={{ height: '50px' }} />
                        </div>
                        <div className='bordered corner-2 p-3'>
                            <h5 className='bold'>{selectedAgodaHotel ? selectedAgodaHotel : 'N/A'}</h5>
                            <p>Agoda</p>
                        </div>
                        <div>
                            <img src={doubleArrow} style={{ height: '50px' }} />
                        </div>
                        <div className='bordered corner-2 p-3'>
                            <h5 className='bold'>{selectedBookingHotel ? selectedBookingHotel : 'N/A'}</h5>
                            <p>Booking.com</p>
                        </div>
                    </div>

                    <div className='mt-4 w-100 d-flex justify-content-center align-items-center'>
                        <Button variant="contained" onClick={() => saveMapping()}>Save Mapping</Button>
                    </div>
                    </div>
                </div>
            </Grow>
        </>
    )
};

export default Dashboard