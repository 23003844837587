import {useState, useEffect} from 'react';

import FCMapping from './FCMapping';

const ClientSettings = () => {

	return(
		<section className='admin-card py-3 px-4 mt-4 w-100'>
			<div className='w-100 row'>
				<div className='col-6'>
					<h2 className='bold'>Settings</h2>
				</div>
			</div>

			<div className='mt-3 w-100 row px-3'>
				<FCMapping />
			</div>
		</section>
	)
}

export default ClientSettings;