import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Grow from '@mui/material/Grow';
import axios from 'axios';
import {fetchToken} from '../../../Auth' 
import { toast } from 'react-toastify';

const PriceHistory = ({details, ota, city, date, selectedHotelId}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  
    const [labels, setLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const [amenities, setAmenities] = useState({});

    const options = {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      stacked: false,
      plugins: {
        title: {
          display: true,
          text: '',
        },
      },
      scales: {
        y1: {
          type: 'linear',
          display: true,
          position: 'left',
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    };

    const colors = [
      '#EDAE49', '#3376BD', '#00798C', '#52489C'
    ];

    const navigate = useNavigate()

    const getPriceHistory = async (date) => {
        try {
            const response = await axios.get(`/get_price_history/${ota}/${city}/${date}/${selectedHotelId}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
              },
            });
            
            if(response.status == 200 && response.data !== undefined){
              setLabels(response.data.date_points)
              let temp = []
              let i= 0
              for(let key in response.data.price_history){
                temp.push({
                    label: key in details ? details[key]['hotel_name'] : 'You',
                    data: response.data.price_history[key],  
                    borderColor: colors[i],
                    backgroundColor: colors[i],
                    yAxisID: 'y1',
                })
                i+=1
              }
              setDatasets(temp);
              setAmenities(response.data.amenities);
            }
        } catch (err) {
          console.error("Error", err);
          if(err.response.status === 401){
            navigate('/login')
          }
        }
    }

    useEffect(() => {
        getPriceHistory(date)
    }, [])

    return(
        <>
            <div className='w-100 row mt-3'>
              <div className='h-70 outline-card-2 col-8'>
                <Grow
                  in={true}
                  {...(true ? { timeout: 500 } : {})}
                >
                  <div style={{height: '500px'}} className='w-100 p-3'>
                  <Line options={options} data={{
                    labels,
                    datasets
                  }} />
                  </div>
                </Grow>
              </div>
              <div className='col-4 d-flex flex-column'>
                {
                  Object.keys(amenities).map((item, idx) => (
                    <Grow
                      in={true}
                      {...(true ? { timeout: 500 + idx*0.2 } : {})}
                    >
                      <div key={idx} className={`${amenities[item]['is_self'] ? 'bg-orange' : 'bg-dark-blue'} shadow rounded p-3 text-white my-2`}>
                        <h6 className='bold'>{amenities[item]['room_type'].replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</h6>
                        {
                          amenities[item]['amenities'].filter(amen => amen !== '').map((amen, idx) => <span className='small-2 mr-3' key={idx}><i class="fa-solid fa-check mr-2"></i> {amen}</span>)
                        }
                      </div>
                    </Grow>
                  ))
                }
              </div>
            </div>
        </>
    )
};

export default PriceHistory;