import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import Table from '../../components/common/Table/Table';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Select from '../../components/common/Select'
import {fetchToken} from '../../Auth' 

import UserRoles from './UserRoles';
import User from './User';
import NewUser from './NewUser';
import UserScope from './UserScope';

import '../../assets/styles/base.css';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const UserManagement = () => {

	const [dashboards, setDashboards] = useState([]);
	const [functionalities, setFunctionalities] = useState({})
	const [roles, setRoles] = useState([]);
	const [users, setUsers] = useState([]);
	const [clients, setClients] = useState([]);

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
	setValue(newValue);
	};

	const navigate = useNavigate();

	const getAllClients = async () => {
		try{
	  		const response = await axios.get(`/fetch_all_clients`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
		    	let temp = []
		    	for(let item of response.data){
		    		temp.push({label: item[1], value: item[0]})
		    	}
		    	setClients(temp)
		    }

	  	} catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	const getUserRoles = async () => {
		try {
	        const response = await axios.get('/get_user_roles', {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
	          let temp = response.data.map(item => ({'id': item[0], 'name': item[1], 'createdBy': item[3], 'createdAt': new Date(item[4].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[5], 'status': item[2]}))
	          setRoles(temp)
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response !== undefined && err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	const getDashboards = async () => {
	    try {
	        const response = await axios.get('/get_active_dashboards', {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
	          let temp = response.data.map(item => ({'id': item[0], 'name': item[1], 'url': item[2], 'createdBy': item[4], 'createdAt': item[5], 'updatedAt': item[6], 'status': item[3]}))
	          setDashboards(temp)
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	const getFunctionalities = async () => {
	  	try {
	        const response = await axios.get(`/get_all_functionalities`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
	          setFunctionalities(response.data)
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	useEffect(() => {
		getUserRoles()
		getDashboards()
		getFunctionalities()
		getAllClients()
	}, [])

	return(
		<>
			<Box sx={{ width: '100%' }}>
		      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
		        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
		          <Tab label="Create User" {...a11yProps(0)} />
		          <Tab label="Manage Users & Scope" {...a11yProps(1)} />
		          <Tab label="User Roles" {...a11yProps(2)} />
		        </Tabs>
		      </Box>
		      <CustomTabPanel value={value} index={0}>
		        <NewUser roles={roles} clients={clients} setUsers={setUsers} users={users}/>
		      </CustomTabPanel>
		      <CustomTabPanel value={value} index={1}>
		        <User roles={roles} users={users} setUsers={setUsers} clients={clients} />
		        <UserScope />
		      </CustomTabPanel>
		      <CustomTabPanel value={value} index={2}>
		        <UserRoles roles={roles} setRoles={setRoles} dashboards={dashboards} functionalities={functionalities} />
		      </CustomTabPanel>
		    </Box>
			
			
		</>
	)
}

export default UserManagement;