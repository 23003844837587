export function deslugify(slug) {
    return slug
        .split('-') // Split the string by hyphens
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the words with spaces
}

export function openUrlInIncognito(url) {
    if (url.startsWith("http://") || url.startsWith("https://")) {

    }
    else {
        url = `https://${url}`
    }
    window.open(url, '_blank', 'incognito=yes');
}