import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { fetchToken } from "../../../Auth";
import Switch from "@mui/material/Switch";
import { DataGrid } from "@mui/x-data-grid";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Tooltip from "@mui/material/Tooltip";
import LinkIcon from "@mui/icons-material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { toast } from "react-toastify";
import { openUrlInIncognito } from "../../../services/helper"
const FocusComp = ({
  tenantId,
  propertyId,
  roomType,
  mealPlan,
  ota,
  date,
  baseFare,
}) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [selfRow, setSelfRow] = useState({});

  const getNonFCData = async (selfData, fcRows, compIds) => {
    try {
      const response = await axios.post(
        `/fetch_non_fc_comp`,
        {
          propertyId: propertyId,
          roomType: roomType,
          mealPlan: mealPlan,
          ota: ota,
          date: date,
          compIds: compIds,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      setSelfRow(selfData);
      const formattedRows = [...fcRows, ...response.data].map((res, index) => ({
        ...res,
        id: index + 1,
      }));

      setRows(formattedRows);
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        `/fetch_fc_details/${tenantId}/${propertyId}/${roomType}/${mealPlan}/${ota}/${date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );

      getNonFCData(
        response.data.self_data,
        response.data.comp_data,
        response.data.comp_ids
      );
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };
  function formatDate(dateString) {
    const [datePart, hourPart] = dateString.split("|");

    const [year, month, day] = datePart.split("-").map(Number);

    const date = new Date(year, month - 1, day, parseInt(hourPart), 0);

    const formattedDay = String(date.getDate()).padStart(2, "0");
    const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
    const formattedYear = date.getFullYear();
    const formattedHour = String(date.getHours()).padStart(2, "0");
    const formattedMinute = String(date.getMinutes()).padStart(2, "0");

    return `${formattedDay}-${formattedMonth}-${formattedYear}|${formattedHour}`;
  }



  const columns = [
    {
      sortable: false,
      headerName: "",
      width: 15,
      renderCell: (params) => {
        return (
          <>
            {params.row.is_equ ? (
              <Tooltip title="Lowest available room">
                <AutoFixHighIcon
                  style={{ color: "orange", fontSize: "1.5em" }}
                />
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
    {
      field: `${ota}_name`,
      headerName: "Hotel Name",
      flex: 2.5,
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Hotel Name</div>
            <hr style={{ margin: 0 }} />

            <div title={selfRow[`${ota}_name`]}>{selfRow[`${ota}_name`]}</div>
          </div>
        </>
      ),
    },
    {
      field: "comp_mapped_room_type",
      headerName: "Room Type",
      flex: 1,
      renderHeader: () => (
        <>
          <div
            title={selfRow.comp_mapped_room_type}
            className="d-flex flex-column"
          >
            <div>Room Type</div>
            <hr style={{ margin: 0 }} />
            <div className={selfRow.comp_mapped_room_type}>
              {selfRow.comp_mapped_room_type}
            </div>
          </div>
        </>
      ),
    },
    {
      field: "comp_mapped_meal_plan",
      headerName: "Meal Plan",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <span title={params.row.comp_mapped_meal_plan_name}>
            {params.row.comp_mapped_meal_plan}
          </span>
        );
      },
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Meal Plan</div>
            <hr style={{ margin: 0 }} />
            <div>{selfRow.comp_mapped_meal_plan}</div>
          </div>
        </>
      ),
    },
    {
      field: "base_fare",
      headerName: "Rate",
      flex: 1,
      sortable: true,
      renderCell: (params) =>
        params.row.base_fare === "-"
          ? "-"
          : `₹ ${parseInt(params.row.base_fare).toLocaleString("en-IN")}`,
      sortComparator: (v1, v2) => {
        if (v1 === "-" && v2 === "-") return 0;
        if (v1 === "-") return -1;
        if (v2 === "-") return 1;
        return parseInt(v1) - parseInt(v2);
      },
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Rate</div>
            <hr style={{ margin: 0 }} />

            <div
              title={
                selfRow.base_fare
                  ? selfRow.base_fare === "-"
                    ? "-"
                    : `₹ ${parseInt(selfRow.base_fare).toLocaleString("en-IN")}`
                  : "-"
              }
            >
              {selfRow.base_fare
                ? selfRow.base_fare === "-"
                  ? "-"
                  : `₹ ${parseInt(selfRow.base_fare).toLocaleString("en-IN")}`
                : "-"}
            </div>
          </div>
        </>
      ),
    },
    {
      field: "discount",
      headerName: "Discount",
      sortable: false,
      flex: 1,
      sortable: true,
      renderCell: (params) =>
        params.row.discount === "-"
          ? "-"
          : `₹ ${parseInt(params.row.discount).toLocaleString("en-IN")}`,
      sortComparator: (v1, v2) => {
        if (v1 === "-" && v2 === "-") return 0;
        if (v1 === "-") return -1;
        if (v2 === "-") return 1;
        return parseInt(v1) - parseInt(v2);
      },
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Discount</div>
            <hr style={{ margin: 0 }} />
            <div title={selfRow.discount ? selfRow.discount : "-"}>
              {selfRow.discount ? selfRow.discount : "-"}
            </div>
          </div>
        </>
      ),
    },
    {
      field: "is_sold_out",
      headerName: "Status",
      flex: 1,
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Status</div>
            <hr style={{ margin: 0 }} />
            <div
              title={
                selfRow.is_sold_out !== null
                  ? selfRow.is_sold_out === false
                    ? "Available"
                    : "Sold Out"
                  : "-"
              }
            >
              {selfRow.is_sold_out !== null
                ? selfRow.is_sold_out === false
                  ? "Available"
                  : "Sold Out"
                : "-"}
            </div>
          </div>
        </>
      ),
    },
    {
      field: "scrape_date",
      headerName: "Refresh Date",
      flex: 1.2,
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Refresh Date</div>
            <hr style={{ margin: 0 }} />
            <div title={selfRow.scrape_date ? formatDate(selfRow.scrape_date) : "-"}>
              {selfRow.scrape_date ? formatDate(selfRow.scrape_date) : "-"}
            </div>
          </div>
        </>
      ),
      renderCell: (params) => {
        return (
          <span title={params.row.scrape_date ? params.row.scrape_date : ""}>
            {params.row.scrape_date ? formatDate(params.row.scrape_date) : null}
          </span>
        );
      },
    },
    {
      field: "is_fc",
      type: "actions",
      headerName: "Is FC",
      flex: 1,
      cellClassName: "is_fc",
      getActions: ({ row }) => {
        return [
          <Switch
            checked={row.is_fc}
            className={"is_self" in row && row.is_self ? "d-none" : ""}
            size="small"
            disabled
          />,
        ];
      },
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Is FC</div>
            <hr style={{ margin: 0 }} />
            <div>-</div>
          </div>
        </>
      ),
    },
    {
      field: "room_url",
      type: "actions",
      headerName: "View on OTA",
      flex: 1,
      cellClassName: "room_url",
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>View On OTA</div>
            <hr style={{ margin: 0 }} />
            <div className="d-flex justify-content-center mt-3">
              {selfRow.room_url ? (

                <OpenInNewIcon sx={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => { openUrlInIncognito(selfRow.room_url) }} />

              ) : (
                <OpenInNewIcon sx={{ color: "grey" }} />
              )}
            </div>
          </div>
        </>
      ),
      renderCell: (params) =>
        params.value ? (
          <OpenInNewIcon sx={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => { openUrlInIncognito(params.value) }} />
        ) : (
          <OpenInNewIcon sx={{ color: "grey" }} />
        ),
    },
  ];

  useEffect(() => {
    if (ota && date && tenantId && propertyId && roomType && mealPlan)
      getData();
  }, [ota, date, tenantId, propertyId, roomType, mealPlan]);

  return (
    <div className="focus-comp-table-container">
      <DataGrid
        rows={
          rows
            ? rows.map((item) => ({
              id: item["id"],
              [ota + "_name"]: item[`${ota}_name`],
              comp_mapped_room_type: item["comp_mapped_room_type"],
              comp_mapped_meal_plan: item["comp_mapped_meal_plan"],
              comp_mapped_meal_plan_name: item["comp_mapped_meal_plan_name"],
              base_fare: item["base_fare"] ? item["base_fare"] : "-",
              discount: item.discount ? item.discount : "-",
              is_sold_out:
                item.is_sold_out !== null
                  ? item.is_sold_out === false
                    ? "Available"
                    : "Sold Out"
                  : "-",
              scrape_date: item.scrape_date ? item.scrape_date : "-",
              is_fc: item.is_fc,
              is_self: item.is_self,
              is_equ: "is_equ" in item && item["is_equ"] ? true : false,
              room_url: item.room_url,
            }))
            : []
        }
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        // checkboxSelection
        disableColumnMenu
        disableRowSelectionOnClick
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
        }
      />
    </div>
  );
};

export default FocusComp;
