import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import {fetchToken} from '../../Auth' 

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditRole = ({ setOpenEdit, roleId, dashboards, functionalities, setRoles, roles }) => {

	const [open, setOpen] = useState(true);
	const [roleName, setRoleName] = useState("");
	const [selectedDashboards, setSelectedDashboards] = useState([]);
	const [selectedFunctionalities, setSelectedFunctionalities] = useState({});

	const [selectedDashboard, setSelectedDashboard] = useState('');

	const navigate = useNavigate();

	const notify = () => toast("Wow so easy!");

	const handleClickOpen = () => {
	    setOpen(true);
	};

	const handleClose = () => {
		setOpen(false)
	    setOpenEdit(false);
	};

	const getUserRole = async () => {
		try {
	        const response = await axios.get(`/get_user_role/${roleId}`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
	          setRoleName(response.data.name);
	          setSelectedDashboards(response.data.dashboards)
	          setSelectedFunctionalities(response.data.functionalities)
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	useEffect(() => {
		getUserRole()
	}, []);

	const addDashboard = () => {
		let dash_id = (selectedDashboard.split('_'))[0]
		let dash_name = (selectedDashboard.split('_'))[1]
		setSelectedDashboards([...selectedDashboards, {'dashboard_id': parseInt(dash_id), 'dashboard_name': dash_name}])

		let temp = selectedFunctionalities
		temp[dash_id] = []
		for(let key in functionalities){
			let dId = (key.split('_'))[0]
			if(parseInt(dId) === parseInt(dash_id)){
				for(let f of functionalities[key]){
					temp[dash_id].push({functionality_id: f.func_id, functionality_name: f.func_name, 'privilege': 1})
				}
			}
		}
		setSelectedFunctionalities(temp)
	}

	const changePrivilege = (privilege, dashId, funcId) => {
		let temp = selectedFunctionalities
		let funcs = temp[dashId]

		for(let f of funcs){
			if(f.functionality_id === funcId){
				f.privilege = parseInt(privilege)
				break
			}
		}
		temp[dashId] = funcs
		setSelectedFunctionalities(temp)
	}

	const updateRole = async () => {
		if(!roleName){
			toast.error('Enter a valid user role name!', {
	            position: toast.POSITION.BOTTOM_RIGHT,
	            autoClose: 2500,
	            hideProgressBar: false,
	            closeOnClick: true,
	            pauseOnHover: false,
	            draggable: false,
	            theme: "colored",
	            type: toast.TYPE.ERROR
	        });
			return
		}
		if(!selectedDashboards.length){
			toast.error('Atleast one dashboard should be assigned to a user role!', {
	            position: toast.POSITION.BOTTOM_RIGHT,
	            autoClose: 2500,
	            hideProgressBar: false,
	            closeOnClick: true,
	            pauseOnHover: false,
	            draggable: false,
	            theme: "colored",
	            type: toast.TYPE.ERROR
	        });
			return
		}
		try{
	  		const response = await axios.post(`/update_user_role`, {roleId, roleName, selectedDashboards, selectedFunctionalities}, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200){
		   		let temp = roles
		   		for(let item of temp){
		   			if(parseInt(item['id']) === parseInt(roleId)){
		   				item['name'] = response.data[1]
		   				item['updatedAt'] = response.data[5]
		   			}
		   		}
		   		setRoles(temp)
		   		handleClose()
		   		toast.success('User role updated!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						autoClose: 3500,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						theme: "light",
						type: toast.TYPE.SUCCESS,
						className: "bg-success text-white"
					});
		   	}

	  	} catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }else{
	      	toast.error('Something went wrong!', {
						position: toast.TOP_CENTER,
						autoClose: 3500,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						theme: "light",
						type: toast.TYPE.SUCCESS,
						className: "bg-danger text-white"
					});
	      }
	    }
	}

	return(
		<Dialog
	        open={open}
	        onClose={handleClose}
	        maxWidth={'md'}
	        fullWidth={true}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
		>
		<DialogTitle id="edit-alert-dialog-title">
		  <h4 class="font-weight-bold">Edit User Role</h4>
		</DialogTitle>
		<DialogContent>
			
			<div class="mt-2 w-100 row">
				<div class="col-8">
            		<FormControl fullWidth>
            			<TextField value={roleName} onChange={(e) => setRoleName(e.target.value)} id="edit-user-role-name" label="User Role Name" variant="outlined" />
            		</FormControl>
            	</div>

            	<div class="mt-4 col-12">
	            	<div class="row">
	            		<div class="col-8">
	            			<FormControl fullWidth>
	        					<InputLabel id="demo-simple-select-label">Dashboards</InputLabel>
			            		<Select
						          labelId="demo-simple-select-label"
						          id="edit-dashboard"
						          label="Dashboard"
						          onChange={(evt) => {
						          	let dash_id = (evt.target.value.split('_'))[0]
						          	let dash_name = (evt.target.value.split('_'))[1]
						          	setSelectedDashboard(`${dash_id}_${dash_name}`)
						          }}
						        >
						          {
						          	dashboards.filter(item => !selectedDashboards.find(d => d.dashboard_id == item.id)).map(item => <MenuItem value={`${item['id']}_${item['name']}`}>{item['name']}</MenuItem>)
						          }
						        </Select>
						    </FormControl>
	            		</div>
	            		<div class="col-4">
	            			<Button onClick={addDashboard} variant="outlined">Add</Button>
	            		</div>
	            	</div>
            	</div>

            	<div class="mt-4 col-12">
            		<div class="accordion" id="accordion">
            			{
            				selectedDashboards.map(item => (
            					<div class="accordion-item">
								    <h2 class="accordion-header" id={`edit_heading${item['dashboard_name']}`}>
								      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#edit_collapse_${item['dashboard_id']}`} aria-expanded="true" aria-controls="collapseOne">
								        {item['dashboard_name']}
								      </button>
								    </h2>
								    <div id={`edit_collapse_${item['dashboard_id']}`} class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
										<div className="accordion-body">
											{
												typeof selectedFunctionalities[item['dashboard_id']] !== 'undefined' && selectedFunctionalities[item['dashboard_id']].map(x => (
													<div className="row w-100">
											      		<div className="col-6">{x.functionality_name}</div>

											      		<RadioGroup
													        aria-labelledby="demo-radio-buttons-group-label"
													        name={`edit-radio-buttons-group-${item['dashboard_id']}_${item['dashboard_name']}`}
													    	className="col-6"
													    	defaultValue={String(x['privilege'])}
													    >
													        <div class="d-flex justify-content-between align-items-center">
													        	<FormControlLabel value="1" control={<Radio onChange={(evt) => changePrivilege(evt.target.value, item['dashboard_id'], x['functionality_id']) } />} label="Reader" />
														        <FormControlLabel value="2" control={<Radio onChange={(evt) => changePrivilege(evt.target.value, item['dashboard_id'], x['functionality_id']) } />} label="Maker" />
														        <FormControlLabel value="3" control={<Radio onChange={(evt) => changePrivilege(evt.target.value, item['dashboard_id'], x['functionality_id']) } />} label="Checker" />
													        </div>
													    </RadioGroup>
											      	</div>
												))
											}
										</div>
									</div>
								</div>
            				))
            			}
            		</div>
		        </div>


			</div>
		</DialogContent>

		<DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateRole} variant="contained" autoFocus>
            Save
          </Button>
        </DialogActions>

		</Dialog>

	)
}


export default EditRole;