import * as React from 'react';
import Button from '@mui/material/Button';

import cityDetails from '../../services/cityDetails.js';
import internationalCities from '../../services/internationalCities.js';


const Summary = ({ clientProperties, createClient, name, domain, headOffice, international }) => {

	return(
		<div>
			<section className='border mt-5 p-4 w-100'>
				<div className='w-100 row'>
					<p className='small text-muted'>SUMMARY</p>
					<h2 className='bold'>{name}</h2>
				</div>
				<div className='w-100 row mt-4'>
					<div className='col-6 border-right'>
						<h6 className='bold small'>Basic Details</h6>
						{
							headOffice['label'] ?
							<p className=' small bold-2'><i class="fa-solid fa-location-dot mr-2"></i> {headOffice['label']}, {parseInt(international) === 0 ? cityDetails[headOffice['label']]['state'] : internationalCities[headOffice['label']]['country']}</p>
							:
							null
						}
						<p className='small bold-2'><i class="fa-solid fa-globe mr-2"></i> {!domain ? 'N/A' : domain}</p>
					</div>
					<div className='col-6'>
						<h6 className='bold small'>Regions</h6>
						<div className='w-100 d-flex flex-wrap justify-content-between align-items-center'>
							{
								Object.keys(clientProperties).map(item => <div className='pill-blue'>{item}</div>)
							}
						</div>
					</div>
				</div>
			</section>
			<div className='mt-5 w-100 d-flex justify-content-center'>
				<Button style={{width: '30%'}} size='large' variant="contained" onClick={() => createClient()}>Complete Setup</Button>  
			</div>
		</div>
	)
};

export default Summary;