import Rating from '@mui/material/Rating';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';

const Tiles = ({loading, hotels, checked, clickHotel}) => {

  return (
    <section className='w-100 row'>
      {
        !loading && hotels.length ? hotels.map((hotel, idx) => (
          <div key={idx} className='col-4 my-2'>
            <div id={hotel['_id']} onClick={() => clickHotel(hotel['_id'])} style={{backgroundImage: `url(${hotel['image_url']})`}} className='d-flex flex-column justify-content-between align-items-center w-100 hotel-listing-card p-0 my-3'>
                <div className='w-100 d-flex justify-content-end align-items-center'>
                  <Checkbox style={{ color: '#FF8B00', backgroundColor: "#fff" }} size='small' className='m-1' checked={checked.includes(hotel['_id'])}/>
                </div>
              <div className='w-100 px-2 py-2 hotel-listing-card-gradient'>
                <h6 className='w-100 text-white bold'>{hotel['hotel_name']}</h6>
                <div className='w-100 row'>
                  <div className='col-12'>
                    <Rating
                      value={hotel['star']}
                      readOnly
                      size='small'
                    />
                  </div>
                  <div className='col-9'><span className='small text-white'><i class="fa-solid fa-location-dot mr-2"></i> {hotel['city']}</span></div>
                </div>
              </div>
            </div>
          </div>
        ))
        :
        loading && !hotels.length ?
        <>
        <div className='col-4 my-2'>
            <div className='hotel-listing-card bg-white p-3 d-flex flex-column justify-content-end align-items-center'>
                <div className='d-flex align-items-center'>
                    <Skeleton className='my-3' variant="rounded" width={120} height={15} />
                </div>
                <div className='d-flex align-items-center'>
                  <Skeleton className='mx-2' variant="rounded" width={60} height={15} />
                  <Skeleton className='mx-2' variant="rounded" width={40} height={15} />
                  <Skeleton className='mx-2' variant="rounded" width={40} height={15} />
                </div>
            </div>
        </div>
        <div className='col-4 my-2'>
            <div className='hotel-listing-card bg-white p-3 d-flex flex-column justify-content-end align-items-center'>
                <div className='d-flex align-items-center'>
                    <Skeleton className='my-3' variant="rounded" width={120} height={15} />
                </div>
                <div className='d-flex align-items-center'>
                  <Skeleton className='mx-2' variant="rounded" width={60} height={15} />
                  <Skeleton className='mx-2' variant="rounded" width={40} height={15} />
                  <Skeleton className='mx-2' variant="rounded" width={40} height={15} />
                </div>
            </div>
        </div>
        <div className='col-4 my-2'>
            <div className='hotel-listing-card bg-white p-3 d-flex flex-column justify-content-end align-items-center'>
                <div className='d-flex align-items-center'>
                    <Skeleton className='my-3' variant="rounded" width={120} height={15} />
                </div>
                <div className='d-flex align-items-center'>
                  <Skeleton className='mx-2' variant="rounded" width={60} height={15} />
                  <Skeleton className='mx-2' variant="rounded" width={40} height={15} />
                  <Skeleton className='mx-2' variant="rounded" width={40} height={15} />
                </div>
            </div>
        </div>
        <div className='col-4 my-2'>
            <div className='hotel-listing-card bg-white p-3 d-flex flex-column justify-content-end align-items-center'>
                <div className='d-flex align-items-center'>
                    <Skeleton className='my-3' variant="rounded" width={120} height={15} />
                </div>
                <div className='d-flex align-items-center'>
                  <Skeleton className='mx-2' variant="rounded" width={60} height={15} />
                  <Skeleton className='mx-2' variant="rounded" width={40} height={15} />
                  <Skeleton className='mx-2' variant="rounded" width={40} height={15} />
                </div>
            </div>
        </div>
        <div className='col-4 my-2'>
            <div className='hotel-listing-card bg-white p-3 d-flex flex-column justify-content-end align-items-center'>
                <div className='d-flex align-items-center'>
                    <Skeleton className='my-3' variant="rounded" width={120} height={15} />
                </div>
                <div className='d-flex align-items-center'>
                  <Skeleton className='mx-2' variant="rounded" width={60} height={15} />
                  <Skeleton className='mx-2' variant="rounded" width={40} height={15} />
                  <Skeleton className='mx-2' variant="rounded" width={40} height={15} />
                </div>
            </div>
        </div>
        </>
        :
        <div className='mt-3 w-100 row'>
            <div className='col-12'>
                <Alert severity="info">No hotels found!</Alert>
            </div>
        </div>
      }
    </section>
  );
}

export default Tiles;