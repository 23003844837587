const cities = {
  "Amsterdam": { img_url: "https://images.unsplash.com/photo-1580996378027-23040f16f157?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YW1zdGVyZGFtfGVufDB8fDB8fHww", country: "Netherlands" },
  "Athens": { img_url: "https://images.unsplash.com/photo-1555993539-1732b0258235?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YXRoZW5zfGVufDB8fDB8fHww", country: "Greece" },
  "Auckland": { img_url: "https://images.unsplash.com/photo-1595125990323-885cec5217ff?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YXVja2xhbmR8ZW58MHx8MHx8fDA%3D", country: "New Zealand" },
  "Barcelona": { img_url: "https://images.unsplash.com/photo-1564221710304-0b37c8b9d729?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGJhcmNlbG9uYXxlbnwwfHwwfHx8MA%3D%3D", country: "Spain" },
  "Beijing": { img_url: "https://images.unsplash.com/photo-1620964780032-81ef649db4d9?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YmVpamluZ3xlbnwwfHwwfHx8MA%3D%3D", country: "China" },
  "Berlin": { img_url: "https://images.unsplash.com/photo-1566404791232-af9fe0ae8f8b?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YmVybGlufGVufDB8fDB8fHww", country: "Germany" },
  "Buenos Aires": { img_url: "https://images.unsplash.com/photo-1589909202802-8f4aadce1849?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YnVlbm9zJTIwYWlyZXN8ZW58MHx8MHx8fDA%3D", country: "Argentina" },
  "Budapest": { img_url: "https://media.istockphoto.com/id/468863922/photo/panoramic-view-of-budapest-cityscape.webp?b=1&s=170667a&w=0&k=20&c=S0a6VMEZjPdVfMlvmvK_TwLEuxlxcxWecHqHvU1g3tQ=", country: "Hungary" },
  "Cairo": { img_url: "https://media.istockphoto.com/id/1434687699/photo/the-sphinx-by-the-pyramids-of-giza-in-the-desert-of-egypt.webp?b=1&s=170667a&w=0&k=20&c=czxTZ4_w44vmzNTztVJX7opsvtB5sZm1tnNopF5Rl54=", country: "Egypt" },
  "Cape Town": { img_url: "https://images.unsplash.com/photo-1576485375217-d6a95e34d043?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8Y2FwZSUyMHRvd258ZW58MHx8MHx8fDA%3D", country: "South Africa" },
  "Copenhagen": { img_url: "https://images.unsplash.com/photo-1531041233226-c8a14aea8f71?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGNvcGVuaGFnZW58ZW58MHx8MHx8fDA%3D", country: "Denmark" },
  "Dubai": { img_url: "https://images.unsplash.com/photo-1546412414-e1885259563a?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZHViYWl8ZW58MHx8MHx8fDA%3D", country: "United Arab Emirates" },
  "Dublin": { img_url: "https://images.unsplash.com/photo-1518005068251-37900150dfca?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZHVibGlufGVufDB8fDB8fHww", country: "Ireland" },
  "Geneva": { img_url: "https://images.unsplash.com/photo-1633022326182-1b36700bc49a?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Z2VuZXZhfGVufDB8fDB8fHww", country: "Switzerland" },
  "Hamburg": { img_url: "https://images.unsplash.com/photo-1569150216991-aba1feb19ac5?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aGFtYnVyZ3xlbnwwfHwwfHx8MA%3D%3D", country: "Germany" },
  "Helsinki": { img_url: "https://images.unsplash.com/photo-1538332576228-eb5b4c4de6f5?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aGVsc2lua2l8ZW58MHx8MHx8fDA%3D", country: "Finland" },
  "Istanbul": { img_url: "https://media.istockphoto.com/id/1327842864/photo/blue-mosque-of-istanbul-famous-place-of-visit-turkey.webp?b=1&s=170667a&w=0&k=20&c=YjN-SXr1eQuJcNA7DvFP103vcJWrX5md4tSNmo7QmdE=", country: "Turkey" },
  "Lisbon": { img_url: "https://images.unsplash.com/photo-1536663815808-535e2280d2c2?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bGlzYm9ufGVufDB8fDB8fHww", country: "Portugal" },
  "London": { img_url: "https://images.unsplash.com/photo-1533929736458-ca588d08c8be?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bG9uZG9ufGVufDB8fDB8fHww", country: "United Kingdom" },
  "Los Angeles": { img_url: "https://images.unsplash.com/photo-1460881680858-30d872d5b530?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGhvbGx5d29vZCUyMHNpZ258ZW58MHx8MHx8fDA%3D", country: "United States" },
  "Lyon": { img_url: "https://media.istockphoto.com/id/1176558435/photo/architecture-in-lyon.webp?b=1&s=170667a&w=0&k=20&c=_wdAnyjpc4ANxtryE42I0FyWciSE91gkV1E2fQ6bwhs=", country: "France" },
  "Madrid": { img_url: "https://images.unsplash.com/photo-1570698473651-b2de99bae12f?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bWFkcmlkfGVufDB8fDB8fHww", country: "Spain" },
  "Mexico City": { img_url: "https://media.istockphoto.com/id/1064279806/photo/independence-monument-mexico-city.webp?b=1&s=170667a&w=0&k=20&c=dH7BBUnsmAZcrk_O0BgGKHdqx6rUHNPMBcrYLIGygcs=", country: "Mexico" },
  "Milan": { img_url: "https://images.unsplash.com/photo-1610016302534-6f67f1c968d8?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bWlsYW58ZW58MHx8MHx8fDA%3D", country: "Italy" },
  "Montreal": { img_url: "https://images.unsplash.com/photo-1470181942237-78ce33fec141?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bW9udHJlYWx8ZW58MHx8MHx8fDA%3D", country: "Canada" },
  "Moscow": { img_url: "https://images.unsplash.com/photo-1513326738677-b964603b136d?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bW9zY293fGVufDB8fDB8fHww", country: "Russia" },
  "Munich": { img_url: "https://media.istockphoto.com/id/184102635/photo/munich.webp?b=1&s=170667a&w=0&k=20&c=xhZeAN0PyrVIZ5F7F4O0QGv9xQ-TQHsqq72CYjtl64M=", country: "Germany" },
  "New York": { img_url: "https://images.unsplash.com/photo-1617688319108-cb3bdc88f587?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fG5ldyUyMHlvcmslMjBjaXR5fGVufDB8fDB8fHww", country: "United States" },
  "Oslo": { img_url: "https://images.unsplash.com/photo-1433757741270-94a3bcadc2f3?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8b3Nsb3xlbnwwfHwwfHx8MA%3D%3D", country: "Norway" },
  "Paris": { img_url: "https://images.unsplash.com/photo-1492136344046-866c85e0bf04?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTB8fHxlbnwwfHx8fHw%3D", country: "France" },
  "Prague": { img_url: "https://images.unsplash.com/photo-1564511287568-54483b52a35e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cHJhZ3VlfGVufDB8fDB8fHww", country: "Czech Republic" },
  "Rio de Janeiro": { img_url: "https://images.unsplash.com/photo-1483729558449-99ef09a8c325?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cmlvJTIwZGUlMjBqYW5laXJvfGVufDB8fDB8fHww", country: "Brazil" },
  "Rome": { img_url: "https://images.unsplash.com/photo-1552432552-06c0b0a94dda?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cm9tYW58ZW58MHx8MHx8fDA%3D", country: "Italy" },
  "Seoul": { img_url: "https://media.istockphoto.com/id/1359844912/photo/cityscape-night-view-of-seoul-south-korea-at-sunset-time.webp?b=1&s=170667a&w=0&k=20&c=LxzwqCfMf1tzQkvZf_h1NW8FY8vhNrACgPxGjxyTOR8=", country: "South Korea" },
  "Shanghai": { img_url: "https://images.unsplash.com/photo-1538428494232-9c0d8a3ab403?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2hhbmdoYWl8ZW58MHx8MHx8fDA%3D", country: "China" },
  "Singapore": { img_url: "https://images.unsplash.com/photo-1600664356348-10686526af4f?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHNpbmdhcG9yZXxlbnwwfHwwfHx8MA%3D%3D", country: "Singapore" },
  "Stockholm": { img_url: "https://images.unsplash.com/photo-1569510982863-00502bd764d1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHN0b2NraG9sbXxlbnwwfHwwfHx8MA%3D%3D", country: "Sweden" },
  "Sydney": { img_url: "https://images.unsplash.com/photo-1616128618694-96e9e896ecb7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE1fHx8ZW58MHx8fHx8&w=1000&q=80", country: "Australia" },
  "Tokyo": { img_url: "https://images.unsplash.com/photo-1536098561742-ca998e48cbcc?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8dG9reW98ZW58MHx8MHx8fDA%3D", country: "Japan" },
  "Toronto": { img_url: "https://images.unsplash.com/photo-1588733103629-b77afe0425ce?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y24lMjB0b3dlciUyQyUyMHRvcm9udG8lMkMlMjBjYW5hZGF8ZW58MHx8MHx8fDA%3D", country: "Canada" },
  "Vancouver": { img_url: "https://images.unsplash.com/photo-1609825488888-3a766db05542?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dmFuY291dmVyfGVufDB8fDB8fHww", country: "Canada" },
  "Vienna": { img_url: "https://images.unsplash.com/photo-1519923041107-e4dc8d9193da?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dmllbm5hJTIwY2l0eXxlbnwwfHwwfHx8MA%3D%3D", country: "Austria" },
  "Warsaw": { img_url: "https://images.unsplash.com/photo-1607078486875-a697a8a38e87?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8d2Fyc3phd2F8ZW58MHx8MHx8fDA%3D", country: "Poland" },
  "Wellington": { img_url: "https://images.unsplash.com/photo-1442483221814-59f7d8b22739?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8d2VsbGluZ3RvbnxlbnwwfHwwfHx8MA%3D%3D", country: "New Zealand" },
  "Zurich": { img_url: "https://images.unsplash.com/photo-1620562423895-ad4924643d43?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8enVyaWNoJTIwbGFrZXxlbnwwfHwwfHx8MA%3D%3D", country: "Switzerland" }
};

export default cities;