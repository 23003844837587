import { useEffect } from 'react'
import { BrowserRouter, Routes, Route , useNavigate} from 'react-router-dom';
import BaseLayout from '../../components/common/Layout/BaseLayout'
import isAuthenticated, {getCookie} from '../../services/auth.js'
import { isValidRoute } from '../../Auth';
import {AppProvider} from '../../context/AppContext';
import ClientSettings from './ClientSettings';

// import '../../assets/styles/admin.css';
// import '../../assets/styles/master.css';
// import '../../assets/styles/base.css';

const Settings = ({ match }) => {
  const navigate = useNavigate()

  const checkRoute = async () => {
    const res = await isValidRoute(2)
    if(!res['valid']){
      navigate(res['redirect'])
    }
  }
  
  useEffect(() => {
    checkRoute()
  }, [])

  return(
    <AppProvider>
    <BaseLayout>
      <Routes>
          <Route path="configure-settings" element={<ClientSettings />} />
      </Routes>
    </BaseLayout>
    </AppProvider>
  )
};

export default Settings;