import {useEffect, useRef} from 'react';
import mapboxgl from 'mapbox-gl';
import anime from 'animejs';

import markerImg from '../../../assets/img/marker.png';
import '../../../assets/styles/base.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiYXJjaGl0cmUxMiIsImEiOiJja2lpbDRtZW8wNzhtMnFxdW05bG9zNWl0In0.dIvw__ebeoXjRZszI3qeyQ'

const FCMap = ({radius, hotels, lat, long}) => {

  const mapContainer = useRef(null);
  const popupRef = useRef(new mapboxgl.Popup({ closeButton: false }));

  let zoom = 13;
  if(parseInt(radius) > 0 && parseInt(radius) <= 5){
    zoom = 13
  }
  else if(parseInt(radius) > 5 && parseInt(radius) <= 10){
    zoom = 12.5
  }
  else if(parseInt(radius) > 10 && parseInt(radius) <= 15){
    zoom = 12
  }
  else if(parseInt(radius) > 15 && parseInt(radius) <= 20){
    zoom = 11.5
  }
  else if(parseInt(radius) > 20 && parseInt(radius) <= 25){
    zoom = 11
  }
  else if(parseInt(radius) > 25 && parseInt(radius) <= 30){
    zoom = 10.5
  }

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [long, lat],
      zoom: zoom
    });

    new mapboxgl.Marker().setLngLat([long, lat]).addTo(map);
    
    const geoData = {
      'type': 'FeatureCollection',
      'features': []
    }
    for(let hotel of hotels){
      if(hotel['latitude'] && hotel['longitude']){
        geoData['features'].push({
          'type': "Feature",
          'geometry': {
            type: "Point",
            'coordinates': [parseFloat(hotel['longitude']), parseFloat(hotel['latitude'])]
          },
          'properties': {
            'hotel_id': hotel['_id'],
            'title': hotel['hotel_name']
          }
        })
      }
    }

    map.on('load', () => {
      map.loadImage(
        markerImg,
        (error, image) => {
          if (error) throw error;
          map.addImage('custom-marker', image);
          map.addSource('points', {
            'type': 'geojson',
            'data': geoData,
            'cluster': false,
            'clusterMaxZoom': 14,  // Adjust as needed
        'clusterRadius': 50
          });

          map.addLayer({
            'id': 'points',
            'type': 'symbol',
            'source': 'points',
            'layout': {
              'icon-image': 'custom-marker',
              'icon-size': 0.05
            }
          });

          // Add tooltips on marker hover
//           map.on('mouseenter', 'points', (e) => {
//             map.getCanvas().style.cursor = 'pointer';
// 
//             const coordinates = e.features[0].geometry.coordinates.slice();
//             const title = e.features[0].properties.title;
//             popupRef.current.setLngLat(coordinates).setHTML(`<p>${title}</p>`);
// 
//             if (!popupRef.current.isOpen()) {
//               popupRef.current.addTo(map);
//             }
//             new mapboxgl.Popup()
//               .setLngLat(coordinates)
//               .setHTML(`<p>${title}</p>`)
//               .addTo(map);
//           });

          // Remove tooltips on marker leave
          // map.on('mouseleave', 'points', () => {
          //   map.getCanvas().style.cursor = '';
          //   map.getCanvas().style.cursor = '';
          //   if (popupRef.current.isOpen()) {
          //   popupRef.current.remove();
          // }
          // });

          // Add click function for markers
          map.on('click', 'points', (e) => {
            const hotel_id = e.features[0].properties.hotel_id;

            // You can perform any action on marker click here
            const element = document.getElementById(hotel_id)
            element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            // anime({
            //   targets: `#${hotel_id}`,
            //   keyframes: [
            //     {scale: 1.14},
            //     {scale: 1},
            //     {scale: 1.14},
            //     {scale: 1},
            //   ],
            //   duration: 2000,
            //   easing: 'easeOutElastic(1, .8)',
            //   loop: false
            // });
          });


        }
        );
    });

    // Clean up the map on unmount
    return () => map.remove();
  }, [hotels]);

  return(
    <div style={{
      width: '100%',
      height: '400px',
      borderRadius: '12px'
    }} ref={mapContainer} className="mt-2 mapContainer"/>
    )
}

export default FCMap;