import { useEffect } from 'react'
import { BrowserRouter, Routes, Route , useNavigate} from 'react-router-dom';
import BaseLayout from '../../components/common/Layout/BaseLayout';
import {AppProvider} from '../../context/AppContext';
import isAuthenticated, {getCookie} from '../../services/auth.js'
import { isValidRoute } from '../../Auth';

import Dashboard from './Dashboard';

const UsageTracker = ({ match, userCount, connectedUsers }) => {
  const navigate = useNavigate()

  const checkRoute = async () => {
    const res = await isValidRoute(2)
    if(!res['valid']){
      navigate(res['redirect'])
    }
  }
  
  useEffect(() => {
    checkRoute()
  }, [])

  return(
    <AppProvider>
      <BaseLayout>
        <Routes>
            <Route path="dashboard" element={<Dashboard connectedUsers={connectedUsers} userCount={userCount}/>} />
        </Routes>
      </BaseLayout>
    </AppProvider>
  )
};

export default UsageTracker;