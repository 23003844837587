const MenuItem = (props) => {
  return (
    <li>
        <a className={props.active ? 'bg-white' : ''} href={props.link}>
            <i className={props.active ?  props.icon + " blue" : props.icon}></i>
            <span className={props.active ? "links_name blue" : "links_name"}>{props.menuTitle}</span>
        </a>
        <span className="tooltip">{props.menuTitle}</span>
    </li>
  );
};

export default MenuItem;