import {useState, useEffect} from 'react';
import Grow from '@mui/material/Grow';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';


const RecommendedPrices = ({loading, dates, selfData, site, cities, week, recPrices, roomTypes, errors}) => {

	return(
		<>
			<h3 className='mt-3 bold'>Recommended Prices</h3>
			{
				!loading ?
			
			<table className='chart w-100'>
				  <thead className=''>
				  	<Grow
				        in={true}
				        {...(true ? { timeout: 500 } : {})}
				      >
					    <tr className='bold'>
					      <th scope="col" className='c-2'><h5 className='bold text-orange'><i class="fa-regular fa-calendar mr-2"></i> Week #{week}</h5></th>
					      {
					      	dates.length ?
					      	dates.map(item => (
					      		<th scope='col' className='c-1'>
					      			<div className='w-100'>
					      				<h6 className=' text-center bold'>{item.day}</h6>
					      				<p className='text-center grey-2 small'>{item.date}</p>
					      			</div>
					      			{/* <div className='w-100 d-flex justify-content-center align-items-center'> */}
					      			{/* 	<i class="large fa-solid fa-circle-up text-success mx-1"></i> <span className='small grey'>High demand</span> */}
					      			{/* </div> */}
					      			
					      		</th>
					      	))
					      	:null
					      }
					    </tr>
					   </Grow>
				  </thead>
				  <tbody>
				  	{
				  		roomTypes.length ? roomTypes.map((item, index) => ({...item, weight: 1 + 0.2 * index})).map(room => (
				  			<tr>
				  				<td scope='row'>
				  					<div className='d-flex flex-column justify-content-end align-items-center w-90 hotel-listing-card-2 p-0 ' style={{backgroundImage: `url(${room['img_url']})`}}>
										<div className='w-100 px-2 py-2 hotel-listing-card-gradient'>
											<h6 className='w-100 text-white bold'>{room['room_type_name'].replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</h6>
										</div>
									</div>	
				  					{/* <div> */}
				  					{/* 	<h6 className='bold'><span className='text-orange'>#{room['priority']}</span> {room['room_type_name'].replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</h6> */}
				  					{/* 	{ */}
				  					{/* 		room['is_anchor'] ? */}
				  					{/* 		<p className='success-pill p-1'><i class="fa-solid fa-anchor mr-2"></i> Anchor Room</p> */}
				  					{/* 		: */}
				  					{/* 		null */}
				  					{/* 	} */}
				  					{/* </div> */}
				  				</td>
				  				{
				  					recPrices && Object.keys(recPrices).length && dates.length ? dates.map(date => (
				  						<td className='h-100 p-1'>
					  						{
					  							typeof recPrices[date['key']] !== 'undefined' ?
					  							<div style={{height: '150px'}} className="py-3 rounded p-1 bg-grey-4 d-flex flex-column justify-content-center align-items-center">
						  							{
						  								room['is_anchor'] ?
						  								<p>Your price: ₹{selfData[date['key']]['anchor_room_price']}</p>
						  								: null
						  							}

						  							₹{(recPrices[date['key']] * room['weight']).toLocaleString('en-IN')}
						  						</div>
					  							:
					  							<div style={{height: '80px'}} className="py-3 rounded p-1 bg-grey-4">
					  								-
					  							</div>
					  							
					  						}
					  					</td>

				  					))
				  					:null
				  				}
				  			</tr>
				  		))
				  		:
				  		null
				  	}
				  </tbody>
			</table>

			:

			<div>
				<div className="p-3">
					 <Box>
					 	<div className='w-100 row'>
					 		<div className='col-4'>
					 			
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={50} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 	</div>
					 	<div className='mt-3 w-100 row'>
					 		<div className='col-4'>
					 			<Skeleton variant="rounded" width={200} height={100} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 	</div>
					 	<div className='mt-2 w-100 row'>
					 		<div className='col-4'>
					 			<Skeleton variant="rounded" width={200} height={100} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 		<div className='col-1 d-flex justify-content-center align-items-center'>
					 			<Skeleton width={80} height={100} variant="text" sx={{ fontSize: '1rem' }} />
					 		</div>
					 	</div>
					 	
					 </Box>
				</div>
			</div>
			}
		</>
	)
}

export default RecommendedPrices;