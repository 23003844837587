import { create } from "zustand";
import { persist } from "zustand/middleware";
import axios from "axios";
import { fetchToken } from "../Auth";

const fetchApprovalCount = async () => {
    try {
        const response = await axios.get("/get_approval_counts", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
            },
        })
        if (response.status == 200) {
            // console.log(response)
            return response.data
        }
    } catch (error) {
        console.error("Error fetching approval counts", error)
    }
}


const ApprovalStore = create(
    persist(
        (set) => ({
            roomTypeApprovalCount: 0,
            mealPlanApprovalCount: 0,

            fetchApprovalCount: async () => {
                const data = await fetchApprovalCount()
                if (data) {
                    set({
                        roomTypeApprovalCount: data.room_type_count,
                        mealPlanApprovalCount: data.meal_plan_count,
                    })
                }
            },

            setRoomTypeApprovalCount: (roomTypeApprovalCount) => set({ roomTypeApprovalCount }),
            setMealPlanApprovalCount: (mealPlanApprovalCount) => set({ mealPlanApprovalCount }),

            incrementRoomTypeApprovalCount: () => set((state) => ({
                roomTypeApprovalCount: state.roomTypeApprovalCount + 1
            })),
            decrementRoomTypeApprovalCount: () => set((state) => ({
                roomTypeApprovalCount: Math.max(0, state.roomTypeApprovalCount - 1)
            })),

            incrementMealPlanApprovalCount: () => set((state) => ({
                mealPlanApprovalCount: state.mealPlanApprovalCount + 1
            })),
            decrementMealPlanApprovalCount: () => set((state) => ({
                mealPlanApprovalCount: Math.max(0, state.mealPlanApprovalCount - 1)
            })),
        }),
        {
            name: "approval-storage",
            getStorage: () => localStorage,
        }
    )
);

export default ApprovalStore;