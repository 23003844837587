import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../assets/styles/modal.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Autocomplete, TextField } from '@mui/material';
import axios from "axios";
import { fetchToken } from "../../Auth";
import Alert from '@mui/material/Alert';
import ApprovalStore from "../../context/RoomTypeMealTypeApprovalContext";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

const dialogPaperStyle = {
    // height: "23.5rem",
    width: "50rem"
};

const RoomTypeApprovalModal = ({
    open,
    onClose,
    onApprove,
    approvalData,
    roomTypeToMappedRoomType,
    getRoomTypeMappings,
    get_room_type_to_mapped_room_type
}) => {
    const {
        decrementRoomTypeApprovalCount,
    } = ApprovalStore();

    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [selectedMappedRoomType, setSelectedMappedRoomType] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        setSelectedMappedRoomType(approvalData.changed_mapped_room_type || '');
    }, [approvalData]);

    const closeModal = () => {
        setAlertMessage("");
        setShowAlert(false);
        setSelectedMappedRoomType("");
        onClose();
    };

    const handleApproveClick = () => {
        if (!selectedMappedRoomType) {
            setAlertMessage("Select a mapping type!");
            setShowAlert(true);
            return;
        }
        setShowConfirmationDialog(true); // Show confirmation dialog
    };

    const approveRoomTypeMapping = async () => {
        try {
            const data = {
                ...approvalData,
                changed_mapped_room_type: selectedMappedRoomType,
            };

            const response = await axios.post("/approve_room_type_mapping", data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
                },
            });

            if (response.status === 200) {
                closeModal();
                toast.success(response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.SUCCESS
                });
                decrementRoomTypeApprovalCount();
                getRoomTypeMappings();
                get_room_type_to_mapped_room_type();
            }
        } catch (err) {
            console.error("Error approving mapping", err);
            if (err.response && err.response.status === 500) {
                toast.error('Something went wrong!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                });
            }
            if (err.response && err.response.status === 400) {
                toast.error(err.response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                });
            }
            if (err.response && err.response.status === 401) {
                navigate('/login');
            }
        } finally {
            setShowConfirmationDialog(false); // Hide confirmation dialog after approval
        }
    };

    const handleCancelConfirmation = () => {
        setShowConfirmationDialog(false);
    };

    return (
        <>
            {/* Main RoomTypeApprovalModal */}
            <Dialog
                open={open}
                onClose={closeModal}
                PaperProps={{ style: dialogPaperStyle }}
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "0" }}>
                    <p style={{ margin: 0, fontWeight: "bold" }}>Approve Mapping</p>
                    <IconButton edge="end" color="inherit" onClick={closeModal} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: 0 }}>
                    <div className="modal-container">
                        <p className="grey-text">Existing Mapping</p>
                        <div className="input-container">
                            <div className="ind-inputs">
                                <label htmlFor="room-type">Room Type</label>
                                <input type="text" className='form-control' name='room-type' id='room-type' disabled value={approvalData.room_type} title={approvalData.room_type} />
                            </div>
                            <div className="ind-inputs">
                                <label htmlFor="mapped-room-type">Mapped Room Type</label>
                                <input type="text" className='form-control' name='mapped-room-type' id='mapped-room-type' disabled value={approvalData.mapped_room_type} title={approvalData.mapped_room_type} />
                            </div>
                        </div>
                        <p className="grey-text">Recommended Mapping</p>
                        <div className='input-container'>
                            <div className="ind-inputs">
                                <label htmlFor="room-type-2">Room Type</label>
                                <input type="text" className='form-control' name='room-type-2' id='room-type-2' disabled value={approvalData.room_type} title={approvalData.room_type} />
                            </div>
                            <div className="ind-inputs">
                                <label htmlFor="suggested-room-type">Suggested Room Type Mapping<Tooltip title="Can edit the suggested mapping" placement="top"><InfoOutlinedIcon sx={{ height: "0.65em", cursor: 'pointer' }} /></Tooltip></label>
                                <Autocomplete
                                    freeSolo
                                    id="suggested-room-type"
                                    options={roomTypeToMappedRoomType || []}
                                    value={selectedMappedRoomType}
                                    onChange={(event, newValue) => {
                                        setSelectedMappedRoomType(newValue || '');
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        setSelectedMappedRoomType(newInputValue || '');
                                    }}
                                    inputValue={selectedMappedRoomType}
                                    sx={{ width: "15em", height: "1em" }}
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{ padding: 0 }}
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {approvalData.approval_sent_by &&
                            <div className='mt-4'>
                                Sent By: {approvalData.approval_sent_by}
                            </div>
                        }
                        <div className='mt-2'>
                            {showAlert && <Alert severity="error">{alertMessage}</Alert>}
                        </div>

                    </div>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "1em", marginBottom: "1em" }}>
                    <Button variant="outlined" onClick={handleApproveClick} color="primary">
                        Approve Mapping
                    </Button>
                    {/* <Button variant="outlined" onClick={closeModal} color="primary">
                        Cancel
                    </Button> */}
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog
                open={showConfirmationDialog}
                onClose={handleCancelConfirmation}
                PaperProps={{
                    sx: {
                        top: 0,
                        marginTop: '2rem',
                        position: 'absolute',
                    }
                }}
            >
                <DialogTitle>Confirm Approval</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to approve this mapping?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={approveRoomTypeMapping} color="primary" variant="contained">
                        Save
                    </Button>
                    <Button onClick={handleCancelConfirmation} color="primary" variant="outlined">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RoomTypeApprovalModal;
