import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grow from "@mui/material/Grow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Table from "../../components/common/Table/Table";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import axios from "axios";
import { fetchToken } from "../../Auth";
import { toast } from "react-toastify";

const Properties = () => {
  const navigate = useNavigate();

  const [properties, setProperties] = useState([]);

  const cols = [
    {
      field: "id",
      headerName: "Property ID",
      headerClassName: "bg-grey-3",
      flex: 1,
    },
    {
      field: "property_name",
      headerName: "Property Name",
      headerClassName: "bg-grey-3",
      width: 400,
    },
    {
      field: "city",
      headerName: "Location",
      headerClassName: "bg-grey-3",
      flex: 1,
    },
    {
      field: "service_type",
      headerName: "Service Type",
      headerClassName: "bg-grey-3",
      flex: 1,
    },
    {
      field: "service_start",
      headerName: "Service Start",
      headerClassName: "bg-grey-3",
      flex: 1,
    },
    {
      field: "service_end",
      headerName: "Service End",
      headerClassName: "bg-grey-3",
      flex: 1,
    },
    {
      field: "rooms_count",
      headerName: "Total Rooms",
      headerClassName: "bg-grey-3",
      flex: 1,
    },
    {
      headerName: "Action",
      headerClassName: "bg-grey-3",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => navigate(`/rooms/controls/${params.row.property_id}`)}>
          <OpenInNewIcon />
        </Button>
      ),
    },
  ];

  const getProperties = async () => {
    try {
      const response = await axios.get(`/fetch_all_properties`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status === 200) {
        setProperties(response.data);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    getProperties();
  }, []);

  return (
    <div className="admin-card p-1 m-2">
      <div className="container-fluid">
        <div className="w-100 my-2 row">
          <div className="col-9"></div>
          <div className="col-3">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                "& > *": {
                  m: 0.3,
                },
              }}
            >
              <h6 className="bold medium">ROOM STATUS</h6>
              <ButtonGroup variant="outlined" aria-label="Basic button group">
                <Button size="medium">Display</Button>
                <Button size="medium">Optimise</Button>
                <Button size="medium">Live</Button>
              </ButtonGroup>
            </Box>
          </div>
        </div>

        <Divider style={{ backgroundColor: "#000", height: "0.5px" }} />

        {properties.length ? (
          <Grow in={true} {...(true ? { timeout: 500 } : {})}>
            <section className="mt-4 w-100">
              <Table disableSelect={true} rows={properties} cols={cols} />
            </section>
          </Grow>
        ) : null}
      </div>
    </div>
  );
};

export default Properties;
