import * as React from 'react';

export default function NotFound() {

  return (
    <>
      <div>
        404 not found
      </div>
    </>
  );
}