import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import BaseLayout from '../../components/common/Layout/BaseLayout';
import { isValidRoute } from '../../Auth';
import {AppProvider} from '../../context/AppContext';
import Client from './Client';
import ClientDetails from './ClientDetails';
import NewClient from './NewClient';
import ClientDashboards from './ClientDashboards';

import '../../assets/styles/admin.css'
import '../../assets/styles/base.css'

const ClientManagement = ({ match }) => {

  const navigate = useNavigate()

  const checkRoute = async () => {
    const res = await isValidRoute(11)
    if(!res['valid']){
      navigate(res['redirect'])
    }
  }
  
  useEffect(() => {
    checkRoute()
  }, [])

  return(
    <AppProvider>
    <BaseLayout background={'bg-grey'}>
      <Routes>
          <Route path="client-management" element={<Client />} />
          <Route path="setup-client" element={<NewClient />} />
          <Route path="details" element={<ClientDetails />} />
          {/* <Route path="client-dashboards" element={<ClientDashboards />} /> */}
      </Routes>
    </BaseLayout>
    </AppProvider>
  )
};

export default ClientManagement;