import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import cities from '../../services/cities.js';
import internationalCities from '../../services/internationalCities.js';

const BasicDetails = ({ name, domain, headOffice, setName, setDomain, setHeadOffice, international, setInternational, showInput, otherCity, setOtherCity }) => {

	return(
		<div className='mt-5 mb-4'>
			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Client Name</label>
				</div>
				<div className='col-8'><input value={name} onChange={(evt) => setName(evt.target.value) } type="text" className="mt-1 form-control" placeholder="Client name" aria-label="name"  /></div>
			</div>

			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Domain</label>
					<p className='small grey-2 light'>Client website (optional)</p>
				</div>
				<div className='col-8'>
					<div class="input-group">
						<span class="input-group-text" id="basic-addon1">www.</span>
						<input value={domain} onChange={(evt) => setDomain(evt.target.value) } type="text" className="form-control" placeholder="Website URL" aria-label="domain"  />
					</div>
				</div>
			</div>

			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Client Type</label>
				</div>
				<div className='col-8'>
					<select value={international} onChange={(evt) => setInternational(evt.target.value)} className="mt-1 form-control">
						<option value='0'>Domestic</option>
						<option value='1'>International</option>
					</select>
				</div>
			</div>

			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Head Office</label>
					<p className='small grey-2 light'>Choose others option if city is not found.</p>
				</div>
				<div className='col-8'>
					{
					  	international == '0' ?
					  	<Select value={headOffice} onChange={opt => setHeadOffice({'label': opt.value, 'value': opt.value})} className="w-100 mt-1" options={[...cities, 'Other'].map(item => ({'label': item, 'value': item}))} />
					  	:
					  	<Select value={headOffice} onChange={opt => setHeadOffice({'label': opt.value, 'value': opt.value})} className="w-100 mt-1" options={[...Object.keys(internationalCities), 'Other'].map(item => ({'label': item, 'value': item}))} />
					}
				</div>
			</div>

			{
				showInput ?
				<div className="w-100 row">
					<div className='col-4'>
					</div>
					<div className='col-8'>
						<input value={otherCity} onChange={(evt) => setOtherCity(evt.target.value) } type="text" className="mt-1 form-control" placeholder="City name" aria-label="name"  />
					</div>
				</div>
				:
				null
			}

		</div>
	)
};

export default BasicDetails;