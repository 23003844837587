import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { fetchToken } from "../../../Auth";
import { toast } from "react-toastify";
import { deslugify } from "../../../services/helper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { openUrlInIncognito } from "../../../services/helper"

const RateParity = ({
  tenantId,
  propertyId,
  roomType,
  mealPlan,
  ota,
  date,
}) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  const sites = {
    agoda: "Agoda",
    booking: "Booking",
    emt: "EaseMyTrip",
    mmt: "MakeMyTrip",
  };

  const fetchRateParity = async () => {
    try {
      const response = await axios.post(
        `/fetch_rate_parity`,
        {
          tenant_id: tenantId,
          property_id: propertyId,
          date: date,
          room_type: roomType,
          meal_plan: mealPlan,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      if (response.status === 200) {
        const formattedRows = response.data.map((row, index) => ({
          id: index + 1,
          ota: sites[row.ota],
          roomType: deslugify(row.roomType),
          mealPlan: row.mealPlan,
          fare: `₹ ${parseInt(row.fare).toLocaleString("en-IN")}`,
          discount: row.discount ? `₹ ${parseInt(row.discount).toLocaleString("en-IN")}` :  '-',
          lastScraped: row.lastScraped,
          link: row.link,
        }));
        setRows(formattedRows);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    fetchRateParity();
  }, [date]);

  // Define DataGrid columns
  const columns = [
    { field: "ota", headerName: "OTA", flex: 1 },
    { field: "roomType", headerName: "Room Type", flex: 1 },
    { field: "mealPlan", headerName: "Meal Plan", flex: 1 },
    { field: "fare", headerName: "Rate", flex: 1 },
    { field: "discount", headerName: "Discount", flex: 1 },
    { field: "lastScraped", headerName: "Last Scraped", flex: 1 },
    {
      field: "link",
      headerName: "View on OTA",
      type: "actions",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.value ? (

          <OpenInNewIcon onClick={() => { openUrlInIncognito(params.value) }} sx={{ color: "#0d6efd", cursor: "pointer" }} />

        ) : (
          <OpenInNewIcon sx={{ color: "grey" }} />
        ),
    },
  ];

  return (
    <>
      <div className="rate-parity-table-container">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[5]}
          disableSelectionOnClick
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
          }
        />
      </div>
    </>
  );
};

export default RateParity;
