import { useEffect } from 'react'
import { Routes, Route , useNavigate} from 'react-router-dom';
import BaseLayout from '../../components/common/Layout/BaseLayout';
import {AppProvider} from '../../context/AppContext';
import { isValidRoute } from '../../Auth';

import ManageHotels from './ManageHotels';

const HotelManagement = ({ match }) => {
  const navigate = useNavigate()

  const checkRoute = async () => {
    const res = await isValidRoute(34)
    if(!res['valid']){
      navigate(res['redirect'])
    }
  }
  
  useEffect(() => {
    checkRoute()
  }, [])

  return(
    <AppProvider>
      <BaseLayout>
        <Routes>
            <Route path="manage-hotels" element={<ManageHotels />} />
        </Routes>
      </BaseLayout>
    </AppProvider>
  )
};

export default HotelManagement;